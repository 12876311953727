export const NEWS_ARRAY = [
  {
    id: 17,
    data: {
      "en-US": {
        title: "Saint Nicholas Day!",
        short_desc: 'Every year at the beginning of December, we gather as a big team to spend a few days together and give a holiday to those who deserve it🫂 Children from temporarily occupied territories, children of our military, our rescuers and doctors - we invited them all to spend St. Nicholas Day with us and tried to make this day unforgettable for them ❤️‍🔥 On this day, the Opera and Ballet Theater had treats, animation, face painting, creative master classes, and gifts🎁 We watched a wonderful performance "Snow White and the 7 Dwarfs"✨ We are sincerely grateful to @kazka.band for participating and supporting our holiday, the children were delighted, this is a real gift for all of us🫶 @yuriy_tkach, @mila.eremeeva - our hearts are with you❤️‍🔥 It is impossible to imagine our holidays without you! Thank you, dear ones, for your support. You have long become the main faces and ambassadors of our foundation. We are proud and appreciate you.',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `День Святого Миколая!`,
        short_desc: `Кожного року на початку грудня ми збираємось усією великою командою аби провести разом декілька днів і подарувати свято тим, хто його заслуговує🫂 Діти з тимчасово окупованих територій, діти наших військових, наших рятувальників і медиків - всіх їх ми запросили провести із нами День Святого Миколая і постаралися зробити цей день для них незабутнім ❤️‍🔥 В цей день в театрі опери і балету були смаколики, анімація , аквагрим , творчі майстер-класи , подарунки 🎁 Ми подивились чудову виставу «Білосніжка та 7 гномів»✨ щиро вдячні @kazka.band за участь та підтримку нашого свята, діти були в захваті, це справжній подарунок для всіх нас🫶 @yuriy_tkach , @mila.eremeeva - наше сердечко із вами❤️‍🔥 Уявити собі наші свята без вас вже й неможливо! дякуємо вам рідненькі за підтримку, ви давно стали головними обличчями і амбасадорами нашого фонду, пишаємось і цінуємо🫰🫂`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Nikolaustag!",
        short_desc: "Jedes Jahr Anfang Dezember versammeln wir uns als großes Team, um ein paar Tage zusammen zu verbringen und denjenigen einen Feiertag zu schenken, die ihn verdienen🫂 Kinder aus vorübergehend besetzten Gebieten, Kinder unseres Militärs, unserer Retter und Ärzte - wir haben sie alle eingeladen den Nikolaustag mit uns zu verbringen und versuchten, diesen Tag für sie unvergesslich zu machen ❤️‍🔥 An diesem Tag gab es im Opern- und Balletttheater Leckereien, Animation, Gesichtsbemalung, kreative Meisterkurse und Geschenke 🎁 Wir sahen eine wunderbare Aufführung von \"Schneewittchen und die 7 Zwerge\"✨ Wir sind @kazka.band aufrichtig dankbar für die Teilnahme und Unterstützung unseres Urlaubs, die Kinder waren begeistert, das ist ein echtes Geschenk für uns alle🫶 @yuriy_tkach, @mila.eremeeva - unsere Herzen sind mit dir❤️‍🔥 Unsere Ferien sind ohne dich nicht vorstellbar! Vielen Dank, meine Lieben, für Ihre Unterstützung. Sie sind seit langem die wichtigsten Gesichter und Botschafter unserer Stiftung. Wir sind stolz auf Sie und schätzen Sie.",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "¡Día de San Nicolás!",
        short_desc: 'Cada año, a principios de diciembre, nos reunimos como un gran equipo para pasar unos días juntos y darles unas vacaciones a quienes lo merecen. Niños de territorios ocupados temporalmente, hijos de nuestros militares, nuestros rescatistas y médicos: los invitamos a todos. para pasar el día de San Nicolás con nosotros e intentaron hacer que este día fuera inolvidable para ellos ❤️‍🔥 En este día, el Teatro de Ópera y Ballet tuvo obsequios, animación, pintura facial, clases magistrales creativas y regalos 🎁 Vimos una maravillosa actuación de "Blancanieves y los 7 enanitos"✨ sinceramente agradecidos a @kazka.band por la participación y el apoyo de nuestras vacaciones, los niños estaban encantados, este es un verdadero regalo para todos nosotros🫶 @yuriy_tkach, @mila.eremeeva - nuestros corazones están contigo❤️‍🔥 ¡Es imposible imaginar nuestras vacaciones sin ti! Gracias, queridos, por vuestro apoyo. Hace tiempo que os habéis convertido en los rostros principales y embajadores de nuestra fundación. Estamos orgullosos de vosotros y os agradecemos.',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "La Saint-Nicolas !",
        short_desc: "Chaque année, au début du mois de décembre, nous nous réunissons en grande équipe pour passer quelques jours ensemble et offrir des vacances à ceux qui le méritent. Enfants des territoires temporairement occupés, enfants de nos militaires, nos sauveteurs et médecins - nous les avons tous invités pour passer la Saint-Nicolas avec nous et essayer de rendre cette journée inoubliable pour eux ❤️‍🔥 Ce jour-là, le Théâtre d'Opéra et de Ballet proposait des friandises, des animations, du maquillage, des master classes créatives et des cadeaux 🎁 Nous avons assisté à une magnifique représentation de \"Blanche-Neige et les 7 nains\"✨ sincèrement reconnaissant à @kazka.band pour sa participation et son soutien à nos vacances, les enfants étaient ravis, c'est un vrai cadeau pour nous tous🫶 @yuriy_tkach, @mila.eremeeva - nos cœurs sont avec toi❤️‍🔥 Impossible d'imaginer nos vacances sans toi ! Merci, chers amis, pour votre soutien. Vous êtes depuis longtemps les principaux visages et ambassadeurs de notre fondation. Nous en sommes fiers et vous en sommes reconnaissants.",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Giorno di San Nicola!",
        short_desc: "Ogni anno all'inizio di dicembre ci riuniamo come una grande squadra per trascorrere qualche giorno insieme e regalare una vacanza a chi se la merita🫂 Bambini provenienti da territori temporaneamente occupati, figli dei nostri militari, dei nostri soccorritori e dottori: li abbiamo invitati tutti per trascorrere con noi il giorno di San Nicola e hanno cercato di rendere questa giornata indimenticabile per loro ❤️‍🔥 In questo giorno, il Teatro dell'Opera e del Balletto ha offerto dolcetti, animazione, truccabimbi, lezioni magistrali creative e regali 🎁 Abbiamo assistito a una meravigliosa esibizione di \"Biancaneve e i 7 nani\"✨ sinceramente grati a @kazka.band per la partecipazione e il supporto alla nostra festa, i bambini erano felicissimi, questo è un vero regalo per tutti noi🫶 @yuriy_tkach, @mila.eremeeva - i nostri cuori sono con te❤️‍🔥 È impossibile immaginare le nostre vacanze senza di te! Grazie, cari, per il vostro supporto. Siete diventati da tempo i volti principali e gli ambasciatori della nostra fondazione. Siamo orgogliosi e vi apprezziamo.",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dzień Świętego Mikołaja!",
        short_desc: "Co roku na początku grudnia zbieramy się jako duży zespół, aby spędzić razem kilka dni i dać urlop tym, którzy na to zasługują🫂 Dzieci z terenów tymczasowo okupowanych, dzieci naszych żołnierzy, nasi ratownicy i lekarze – zaprosiliśmy ich wszystkich spędzić z nami Mikołajki i postarali się, aby ten dzień był dla nich niezapomniany ❤️‍🔥 W tym dniu Teatr Opery i Baletu przygotował dla nich poczęstunek, animacje, malowanie twarzy, kreatywne warsztaty mistrzowskie i prezenty 🎁 Obejrzeliśmy wspaniały spektakl „Królewna Śnieżka i 7 krasnoludków”✨ szczerze dziękujemy zespołowi @kazka.band za udział i wsparcie naszych wakacji, dzieci były zachwycone, to prawdziwy prezent dla nas wszystkich🫶 @yuriy_tkach, @mila.eremeeva – nasze serca są zachwycone z Wami❤️‍🔥 Nie sposób wyobrazić sobie naszych świąt bez Was! Dziękujemy Wam, Kochani, za Wasze wsparcie. Już od dawna jesteście głównymi twarzami i ambasadorami naszej fundacji. Jesteśmy dumni i doceniamy Was.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Dia de São Nicolau!",
        short_desc: 'Todos os anos, no início de dezembro, reunimo-nos como uma grande equipa para passar alguns dias juntos e dar férias a quem as merece🫂 Crianças de territórios temporariamente ocupados, filhos dos nossos militares, dos nossos socorristas e médicos - convidamo-los a todos a passar o Dia de São Nicolau connosco e tentámos tornar este dia inesquecível para eles ❤️‍🔥 Neste dia, o Teatro de Ópera e Ballet contou com guloseimas, animação, pinturas faciais, master classes criativas e presentes 🎁 Assistimos a uma maravilhosa apresentação de "Branca de Neve e os 7 Anões"✨ sinceramente gratos à @kazka.band pela participação e apoio ao nosso feriado, as crianças ficaram encantadas, este é um verdadeiro presente para todos nós🫶 @yuriy_tkach, @mila.eremeeva - os nossos corações estão convosco❤️‍🔥 É impossível imaginar as nossas férias sem ti! Obrigado, queridos, pelo vosso apoio.',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-18/1.jpg"),
    time: "06.12.2024",
    img_report: [],
    letters_thanks: {
      state: false,
    },
    video: {
      state: true,
      link: "Il-33RTlS8g",
    },
  },
  {
    id: 16,
    data: {
      "en-US": {
        title: "CHILDREN'S PROTECTION DAY CELEBRATION",
        short_desc: 'CHILDREN\'S DAY CELEBRATION 🤩 An event that brought together over 2,000 children 🙌🏻 Food courts, animation, face painting and much more for our children 🫶🏻 Star guests: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @amadorlopez_rumberos @nadyadorofeeva We are sincerely grateful to everyone who participated ☺️ We do everything for a happy childhood for little children 😘',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `СВЯТО ДО ДНЯ ЗАХИСТУ ДІТЕЙ `,
        short_desc: `СВЯТО ДО ДНЯ ЗАХИСТУ ДІТЕЙ 🤩 Захід який обʼєднав понад 2000 дітей🙌🏻 Фуд-корти, анімація, аквагрим та багато іншого для наших дітей 🫶🏻 Зіркові гості: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @amadorlopez_rumberos @nadyadorofeeva Всім, хто брав участь щиро вдячні ☺️ Робимо все для щасливого дитинства маленьких діток 😘`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "FEIER ZUM KINDERSCHUTZTAG",
        short_desc: "FEIER ZUM KINDERSCHUTZTAG 🤩 Eine Veranstaltung, die über 2.000 Kinder zusammenbrachte🙌🏻 Food Courts, Animation, Kinderschminken und vieles mehr für unsere Kinder 🫶🏻 Stargäste: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @ amadorlopez_rumberos @nadyadorofeeva Wir sind allen, die teilgenommen haben, aufrichtig dankbar ☺️ Wir tun alles für eine glückliche Kindheit für kleine Kinder 😘",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "CELEBRACIÓN DEL DÍA DE PROTECCIÓN DE LA INFANCIA",
        short_desc: 'CELEBRACIÓN DEL DÍA DE PROTECCIÓN DE LOS NIÑOS 🤩 Un evento que reunió a más de 2,000 niños🙌🏻 Patios de comidas, animación, pintacaras y mucho más para nuestros niños 🫶🏻 Invitados estrella: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @ amadorlopez_rumberos @nadyadorofeeva Agradecemos sinceramente a todos los que participaron ☺️ Hacemos todo lo posible para que los niños pequeños tengan una infancia feliz 😘',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "CÉLÉBRATION DE LA JOURNÉE DE LA PROTECTION DES ENFANTS",
        short_desc: "CÉLÉBRATION DE LA JOURNÉE DE LA PROTECTION DE L'ENFANCE 🤩 Un événement qui a réuni plus de 2 000 enfants 🙌🏻 Aires de restauration, animation, maquillage et bien plus encore pour nos enfants 🫶🏻 Invités vedettes : @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @amadorlopez_rumberos @nadyadorofeeva Nous sommes sincèrement reconnaissants à tous ceux qui ont participé ☺️ Nous faisons tout pour une enfance heureuse pour les petits enfants 😘",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "CELEBRAZIONE DELLA GIORNATA DI PROTEZIONE DEI BAMBINI",
        short_desc: "CELEBRAZIONE DELLA GIORNATA DI PROTEZIONE DEI BAMBINI 🤩 Un evento che ha riunito oltre 2.000 bambini🙌🏻 Aree ristoro, animazione, truccabimbi e molto altro per i nostri bambini 🫶🏻 Ospiti d'eccezione: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @ amadorlopez_rumberos @nadyadorofeeva Siamo sinceramente grati a tutti coloro che hanno partecipato ☺️ Facciamo di tutto per un'infanzia felice per i più piccoli 😘",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "OBCHODY DNIA OCHRONY DZIECI",
        short_desc: "OBCHODY DNIA OCHRONY DZIECI 🤩 Wydarzenie, które zgromadziło ponad 2000 dzieci🙌🏻 Strefy gastronomiczne, animacje, malowanie twarzy i wiele więcej dla naszych dzieci 🫶🏻 Główni goście: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @ amadorlopez_rumberos @nadyadorofeeva Jesteśmy szczerze wdzięczni wszystkim, którzy wzięli udział ☺️ Robimy wszystko, aby zapewnić małym dzieciom szczęśliwe dzieciństwo 😘",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "CELEBRAÇÃO DO DIA DA PROTEÇÃO À CRIANÇA",
        short_desc: 'CELEBRAÇÃO DO DIA DA PROTEÇÃO ÀS CRIANÇAS 🤩 Um evento que reuniu mais de 2.000 crianças🙌🏻 Praças de alimentação, animação, pinturas faciais e muito mais para as nossas crianças 🫶🏻 Convidados especiais: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce. oficial @positiff @ amadorlopez_rumberos @nadyadorofeeva Estamos sinceramente gratos a todos os que participaram ☺️ Fazemos tudo por uma infância feliz para as crianças 😘',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-17/1.jpg"),
    time: "01.06.2024",
    img_report: [],
    letters_thanks: {
      state: false,
    },
    video: {
      state: true,
      link: "5wwdc0HB6XY",
    },
  },
  {
    id: 15,
    data: {
      "en-US": {
        title: "Chess tournament \"STRATEGY OF THE UNBREAKABLE\"!",
        short_desc: 'May 28! In the city of Dnipro! The chess tournament "STRATEGY OF THE UNBREAKABLE" took place! Over 650 participants, including more than 450 children! Five different tournaments for children and adults! Our permanent hosts @yuriy_tkach and @mila.eremeeva! The anthem was performed by @mishvirmish 🇺🇦! And the most interesting thing is, together with you, we set a Ukrainian record for a simultaneous game session! Absolutely all participants became record holders and were sure to receive a medal - a distinction and diplomas! May 29! The finals of all five tournaments took place! And the winners received gifts, diplomas, medals and cups!',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Турнір з шахів «СТРАТЕГІЯ НЕЗЛАМНИХ»!`,
        short_desc: `28 травня! У місті Дніпро! Відбувся турнір з шахів «СТРАТЕГІЯ НЕЗЛАМНИХ»! Понад 650 учасників, серед яких більш ніж 450 дітей! Пʼять різних турнірів для дітей та дорослих! Наші незмінні ведучі @yuriy_tkach та @mila.eremeeva ! Гімн виконала @mishvirmish 🇺🇦 ! І найцікавіше, ми разом з вами встановили рекорд України з сеансу одночасної гри! Абсолютно всі учасники стали рекордсменами і обовʼязково отримали медаль - відзнаку та дипломи! 29-го травня! Відбулися фінали усіх пʼяти турнірів!А переможці отримали подарунки, дипломи, медалі та кубки !`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Schachturnier „STRATEGIE DES UNZERBRECHLICHEN“!",
        short_desc: "28. Mai! In der Stadt Dnipro! Das Schachturnier „STRATEGY OF THE UNBREAKABLE“ hat stattgefunden! Über 650 Teilnehmer, darunter mehr als 450 Kinder! Fünf verschiedene Turniere für Kinder und Erwachsene! Unsere ständigen Gastgeber @yuriy_tkach und @mila.eremeeva! Die Hymne wurde von @mishvirmish 🇺🇦 aufgeführt! Und das Interessanteste: Gemeinsam mit Ihnen haben wir einen ukrainischen Rekord für die meisten gleichzeitigen Spielsitzungen aufgestellt! Absolut alle Teilnehmer wurden Rekordhalter und bekamen mit Sicherheit eine Medaille – eine Auszeichnung und Diplome! 29. Mai! Die Endspiele aller fünf Turniere wurden ausgetragen! Und die Gewinner erhielten Geschenke, Diplome, Medaillen und Pokale!",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "¡Torneo de ajedrez \"ESTRATEGIA DE LO INQUEBRANTABLE\"!",
        short_desc: '¡28 de mayo! ¡En la ciudad de Dnipro! ¡Se celebró el torneo de ajedrez "ESTRATEGIA DE LO INQUEBRANTABLE"! ¡Más de 650 participantes, incluidos más de 450 niños! ¡Cinco torneos diferentes para niños y adultos! ¡Nuestros anfitriones constantes @yuriy_tkach y @mila.eremeeva! ¡El himno fue interpretado por @mishvirmish 🇺🇦! ¡Y lo más interesante es que junto con vosotros hemos conseguido un récord ucraniano de mayor cantidad de sesiones de juego simultáneas! ¡Absolutamente todos los participantes se convirtieron en poseedores de récords y estaban seguros de recibir una medalla, una distinción y diplomas! ¡29 de mayo! ¡Se celebraron las finales de los cinco torneos! ¡Y los ganadores recibieron regalos, diplomas, medallas y copas!',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Tournoi d'échecs \"STRATÉGIE DE L'INCASSABLE\" !",
        short_desc: "28 mai! Dans la ville de Dnipro ! Le tournoi d'échecs \"STRATÉGIE DE L'INCASSABLE\" a eu lieu ! Plus de 650 participants, dont plus de 450 enfants ! Cinq tournois différents pour enfants et adultes ! Nos hôtes constants @yuriy_tkach et @mila.eremeeva ! L\'hymne a été interprété par @mishvirmish 🇺🇦 ! Et le plus intéressant, c\'est qu\'avec vous, nous avons établi un record ukrainien pour le plus grand nombre de sessions de jeu simultanées ! Absolument tous les participants sont devenus détenteurs de records et étaient assurés de recevoir une médaille - une distinction et des diplômes ! 29 mai ! Les finales des cinq tournois ont eu lieu ! Et les vainqueurs ont reçu des cadeaux, des diplômes, des médailles et des coupes !",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Torneo di scacchi \"STRATEGIA DELL'INDISTRUTTIBILE\"!",
        short_desc: "28 maggio! Nella città di Dnipro! Ha avuto luogo il torneo di scacchi \"STRATEGIA DELL'INDISTRUTTIBILE\"! Oltre 650 partecipanti, tra cui più di 450 bambini! Cinque diversi tornei per bambini e adulti! I nostri ospiti fissi sono @yuriy_tkach e @mila.eremeeva! L'inno è stato eseguito da @mishvirmish 🇺🇦! E la cosa più interessante è che insieme a voi abbiamo stabilito il record ucraino per il maggior numero di sessioni di gioco simultanee! Tutti i partecipanti sono diventati detentori di record e hanno ricevuto sicuramente una medaglia, un riconoscimento e dei diplomi! 29 maggio! Si sono tenute le finali di tutti e cinque i tornei! E i vincitori hanno ricevuto regali, diplomi, medaglie e coppe!",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Turniej szachowy \"STRATEGIA NIEZŁAMANEGO\"!",
        short_desc: "28 maja! W mieście Dniepr! Odbył się turniej szachowy \"STRATEGIA NIEZŁAMANEGO\"! Ponad 650 uczestników, w tym ponad 450 dzieci! Pięć różnych turniejów dla dzieci i dorosłych! Nasi stali gospodarze @yuriy_tkach i @mila.eremeeva! Hymn wykonał @mishvirmish 🇺🇦! A co najciekawsze, wspólnie z Wami ustanowiliśmy ukraiński rekord w liczbie jednoczesnych sesji gry! Absolutnie wszyscy uczestnicy zostali rekordzistami i z pewnością otrzymali medal - wyróżnienie i dyplomy! 29 maja! Odbyły się finały wszystkich pięciu turniejów! A zwycięzcy otrzymali prezenty, dyplomy, medale i puchary!",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Torneio de xadrez \"ESTRATÉGIA DO INQUEBRÁVEL\"!",
        short_desc: '28 de maio! Na cidade de Dnipro! Decorreu o torneio de xadrez "STRATEGY OF THE UNBREAKABLE"! Mais de 650 participantes, incluindo mais de 450 crianças! Cinco torneios diferentes para crianças e adultos! Os nossos anfitriões constantes @yuriy_tkach e @mila.eremeeva! O hino foi interpretado por @mishvirmish 🇺🇦! E o mais interessante é que, juntamente consigo, estabelecemos um recorde ucraniano para o maior número de sessões de jogo simultâneas! Absolutamente todos os participantes se tornaram recordistas e certamente receberiam uma medalha - uma distinção e diplomas! 29 de maio! Realizaram-se as finais de todos os cinco torneios! E os vencedores receberam presentes, diplomas, medalhas e taças!',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-16/1.jpg"),
    time: "28.05.2024",
    img_report: [],
    letters_thanks: {
      state: false,
    },
    video: {
      state: true,
      link: "2KD11HKdGZE",
    },
  },
  {
    id: 14,
    data: {
      "en-US": {
        title: "OPENING OF THE CHILDREN'S CENTER 🥳",
        short_desc: 'OPENING OF THE CHILDREN\'S CENTER 🥳 the long-awaited event has taken place 👏🏻 On April 19, we opened the doors of our children\'s space for our little ones 🙌🏻 Now we will draw, study, play sports together and all this thanks to the caring benefactors of the city of Dnipro! Thank you! We sincerely thank everyone who helped us create this space 🙏 Thank you for the gifts @bontoy_job ❤️ We create goodness and benefit for our children to ensure they have a happy childhood despite everything!',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `ВІДКРИТТЯ ДИТЯЧОГО ЦЕНТРУ 🥳`,
        short_desc: `ВІДКРИТТЯ ДИТЯЧОГО ЦЕНТРУ 🥳 довгоочікувана подія відбулась 👏🏻 19.04 ми відкрили наші двері дитячого простору для наших маленьких діток 🙌🏻 Тепер ми разом будемо малювати, навчатись, займатись спортом і все це завдяки небайдужим благодійникам міста Дніпра ! Дякуємо! Щиро дякуємо всім, хто допоміг нам у створенні цього простору 🙏 Дякуємо за подарунки @bontoy_job ❤️ Створюємо добро та користь для наших діток, щоб забезпечити їм щасливе дитинство попри все!`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "ERÖFFNUNG DES KINDERZENTRUMS 🥳",
        short_desc: "ERÖFFNUNG DES KINDERZENTRUMS 🥳 Das lang erwartete Ereignis hat stattgefunden 👏🏻 Am 19. April öffneten wir die Türen unseres Kinderraums für unsere kleinen Kinder 🙌🏻 Jetzt werden wir zusammen zeichnen, lernen, Sport treiben und das alles dank die fürsorglichen Wohltäter der Stadt Dnipro! Danke schön! Wir danken allen herzlich, die uns geholfen haben, diesen Raum zu schaffen 🙏 Danke für die Geschenke @bontoy_job ❤️ Wir schaffen Gutes und Nützliches für unsere Kinder, um ihnen trotz allem eine glückliche Kindheit zu ermöglichen!",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "APERTURA DEL CENTRO INFANTIL 🥳",
        short_desc: 'APERTURA DEL CENTRO INFANTIL 🥳 Ya se ha producido el evento tan esperado 👏🏻 El pasado 19 de abril abrimos las puertas de nuestro espacio infantil para nuestros pequeños 🙌🏻 Ahora dibujaremos, estudiaremos, haremos deporte juntos, y todo ello gracias a ¡Los benefactores solidarios de la ciudad de Dnipro! ¡Gracias! Agradecemos sinceramente a todos los que nos ayudaron a crear este espacio 🙏 Gracias por los regalos @bontoy_job ❤️ ¡Creamos bondad y beneficios para nuestros niños para asegurarnos de que tengan una infancia feliz a pesar de todo!',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "OUVERTURE DU CENTRE POUR ENFANTS 🥳",
        short_desc: "OUVERTURE DU CENTRE POUR ENFANTS 🥳 L'événement tant attendu a eu lieu 👏🏻 Le 19 avril, nous avons ouvert les portes de notre espace enfants pour nos petits enfants 🙌🏻 Maintenant, nous allons dessiner, étudier, faire du sport ensemble, et tout cela grâce à les bienfaiteurs bienveillants de la ville de Dnipro ! Merci! Nous remercions sincèrement tous ceux qui nous ont aidé à créer cet espace 🙏 Merci pour les cadeaux @bontoy_job ❤️ Nous créons de la bonté et des bienfaits pour nos enfants pour leur assurer une enfance heureuse malgré tout !",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "APERTURA DEL CENTRO PER L'INFANZIA 🥳",
        short_desc: "APERTURA DEL CENTRO INFANZIA 🥳 L'evento tanto atteso si è svolto 👏🏻 Il 19 aprile abbiamo aperto le porte del nostro spazio bimbi ai nostri più piccoli 🙌🏻 Ora disegneremo, studieremo, faremo sport insieme, e tutto questo grazie a i benefattori premurosi della città di Dnipro! Grazie! Ringraziamo di cuore tutti coloro che ci hanno aiutato a creare questo spazio 🙏 Grazie per i regali @bontoy_job ❤️ Creiamo bontà e benefici per i nostri bambini per garantire loro un'infanzia felice nonostante tutto!",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "OTWARCIE CENTRUM DZIECIĘCEGO 🥳",
        short_desc: "OTWARCIE CENTRUM DZIECIĘCEGO 🥳 Długo wyczekiwane wydarzenie już nastąpiło 👏🏻 19 kwietnia otworzyliśmy drzwi naszej przestrzeni dziecięcej dla naszych małych pociech 🙌🏻 Teraz będziemy wspólnie rysować, uczyć się, uprawiać sport, a to wszystko dzięki troskliwi dobroczyńcy miasta Dniepru! Dziękuję! Serdecznie dziękujemy wszystkim, którzy pomogli nam stworzyć tę przestrzeń 🙏 Dziękujemy za prezenty @bontoy_job ❤️ Tworzymy dobro i korzyści dla naszych dzieci, aby zapewnić im szczęśliwe dzieciństwo pomimo wszystko!",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "INAUGURAÇÃO DO CENTRO INFANTIL 🥳",
        short_desc: 'INAUGURAÇÃO DO CENTRO INFANTIL 🥳 O evento tão esperado aconteceu 👏🏻 No dia 19 de abril, abrimos as portas do nosso espaço infantil aos nossos mais pequenos 🙌🏻 Agora vamos desenhar, estudar, praticar desporto juntos, e tudo isto graças aos atenciosos benfeitores da cidade de Dnipro! Obrigado! Agradecemos sinceramente a todos os que nos ajudaram a criar este espaço 🙏 Obrigada pelos presentes @bontoy_job ❤️ Criamos coisas boas e benefícios para as nossas crianças para garantir que têm uma infância feliz, apesar de tudo!',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-15/1.jpg"),
    time: "19.04.2024",
    img_report: [],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
      link: "pa2_nlSl2Sg",
    },
  },
  {
    id: 13,
    data: {
      "en-US": {
        title: "We celebrate St. Nicholas Day",
        short_desc: 'We celebrate St. Nicholas Day together with IDP children who are currently in the Dnipropetrovsk region',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Святкуємо День Святого Миколаю`,
        short_desc: `Святкуємо День Святого Миколаю разом з дітьми ВПО які зараз знаходяться у Дніпропетровській області`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Wir feiern den Nikolaustag",
        short_desc: "Wir feiern den Nikolaustag zusammen mit den Binnenflüchtlingen, die sich derzeit in der Region Dnipropetrowsk aufhalten",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Celebramos el día de San Nicolás",
        short_desc: 'Celebramos el día de San Nicolás junto con los niños desplazados que se encuentran actualmente en la región de Dnipropetrovsk',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Nous célébrons la Saint-Nicolas",
        short_desc: "Nous célébrons la Saint-Nicolas avec les enfants déplacés qui se trouvent actuellement dans la région de Dnipropetrovsk",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Celebriamo il giorno di San Nicola",
        short_desc: "Celebriamo il giorno di San Nicola insieme ai bambini sfollati che si trovano attualmente nella regione di Dnipropetrovsk",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Świętujemy Dzień Świętego Mikołaja",
        short_desc: "Świętujemy Mikołajki razem z dziećmi przesiedlonymi, które obecnie przebywają w obwodzie dniepropietrowskim",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Celebramos o dia de São Nicolau",
        short_desc: 'Celebramos o Dia de São Nicolau juntamente com as crianças deslocadas internamente que se encontram atualmente na região de Dnipropetrovsk',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-14/1.jpg"),
    time: "06.12.2023",
    img_report: [],
    letters_thanks: {
      state: false,
    },
    video: {
      state: true,
      link: "gVEaBBIIdj8",
    },
  },
  {
    id: 12,
    data: {
      "en-US": {
        title: "HOLIDAY \"MOVING TOWARDS VICTORY\"",
        short_desc: 'WE HELD THE HOLIDAY "MOVING TOWARDS VICTORY" A sports event for 500 children, created with the support of the regional administration so that our children move only forward, do not forget about their goals and achievements, despite the war 🙏 We are infinitely grateful to @_oleksandrleshchenko for organizing the flash mob. World-class athletes 🏅 took part, as well as star friends, such as @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official We thank everyone who took part in this event. Namely, to the organizers, without whom our event would not have taken place: @viktoriya_tka4 @deniskonovalov @m_galat @vladimir_drachuk @franz_audio You are the best 🫂',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `СВЯТО «РУХАЄМОСЬ ДО ПЕРЕМОГИ»`,
        short_desc: `ПРОВЕЛИ СВЯТО «РУХАЄМОСЬ ДО ПЕРЕМОГИ» Спортивний захід на 500 дітей, створений за підтримки обл адміністрації для того, щоб наші діти рухались тільки вперед, не забували про свої цілі та досягнення, не дивлячись на війну 🙏 За організацію флеш-мобу безмежно вдячні @_oleksandrleshchenko Участь приймали світові спортсмени🏅 а також зіркові друзі , такі як @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official Дякуємо всім, хто приймав участь у цьому заході А саме організаторам без яких не пройшов наш захід: @viktoriya_tka4 @deniskonovalov @m_galat @vladimir_drachuk @franz_audio Ви найкращі 🫂`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "URLAUB „DEM SIEG ENTGEGEN“",
        short_desc: "WIR HABEN DEN FEIERTAG \DEM SIEG ENTGEGEN\" VERANSTALTET Eine Sportveranstaltung für 500 Kinder, die mit Unterstützung der Regionalverwaltung ins Leben gerufen wurde, damit unsere Kinder trotz des Krieges nur vorwärts gehen und ihre Ziele und Erfolge nicht vergessen 🙏 Wir sind unendlich dankbar @_oleksandrleshchenko für die Organisation des Flashmobs. Weltklasse-Athleten🏅 und Starfreunde wie @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official haben teilgenommen. Vielen Dank an alle, die an dieser Veranstaltung teilgenommen haben. Insbesondere an die Organisatoren, ohne die unsere Veranstaltung nicht stattgefunden hätte: @viktoriya_tka4 @deniskonalov @m_galat @vladimir_drachuk @franz_audio Ihr seid die Besten 🫂",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "VACACIONES \"AVANZANDO HACIA LA VICTORIA\"",
        short_desc: 'CELEBRAMOS LA FIESTA “AVANZANDO HACIA LA VICTORIA” Un evento deportivo para 500 niños, creado con el apoyo de la administración regional para que nuestros niños solo sigan adelante, no se olviden de sus metas y logros, a pesar de la guerra 🙏 Estamos infinitamente agradecidos a @_oleksandrleshchenko por organizar el flash mob Participaron atletas de clase mundial🏅 y amigos estrella como @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official. Gracias a todos los que participaron en este evento. En particular, a los Organizadores, sin los cuales nuestro evento no habría tenido lugar: @viktoriya_tka4 @ deniskonovalov @m_galat @vladimir_drachuk @franz_audio Sois los mejores 🫂',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "VACANCES « EN MARCHE VERS LA VICTOIRE »",
        short_desc: "NOUS AVONS ORGANISÉ LA FÊTE « EN MARCHE VERS LA VICTOIRE » Un événement sportif pour 500 enfants, créé avec le soutien de l'administration régionale pour que nos enfants avancent seulement, n'oublient pas leurs objectifs et leurs réalisations, malgré la guerre 🙏 Nous sommes infiniment reconnaissants à @_oleksandrleshchenko pour l'organisation du flash mob. Des athlètes de classe mondiale🏅 et des amis stars tels que @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official y ont participé. Merci à tous ceux qui ont participé à cet événement. Notamment aux organisateurs, sans qui notre événement n'aurait pas eu lieu : @viktoriya_tka4 @ deniskonovalov @m_galat @vladimir_drachuk @franz_audio Vous êtes les meilleurs 🫂",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "FESTA \"VERSO LA VITTORIA\"",
        short_desc: "ABBIAMO ORGANIZZATO LA FESTA \"IN MOVIMENTO VERSO LA VITTORIA\" Un evento sportivo per 500 bambini, creato con il sostegno dell'amministrazione regionale affinché i nostri bambini vadano solo avanti, non dimentichino i loro obiettivi e i loro successi, nonostante la guerra 🙏 Vi siamo infinitamente grati @_oleksandrleshchenko per aver organizzato il flash mob Atleti di fama mondiale🏅 e amici famosi come @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official hanno preso parte. Grazie a tutti coloro che hanno preso parte a questo evento. Vale a dire a organizzatori, senza i quali il nostro evento non avrebbe avuto luogo: @viktoriya_tka4 @ deniskonovalov @m_galat @vladimir_drachuk @franz_audio Siete i migliori 🫂",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "ŚWIĘTO „W KIERUNKU ZWYCIĘSTWA”",
        short_desc: "ZORGANIZOWALIŚMY ŚWIĘTO „RUSZAJĄC KU ZWYCIĘSTWU” Wydarzenie sportowe dla 500 dzieci, stworzone przy wsparciu administracji regionalnej, aby nasze dzieci szły tylko do przodu, nie zapominając o swoich celach i osiągnięciach, mimo wojny 🙏 Jesteśmy nieskończenie wdzięczni @_oleksandrleshchenko za zorganizowanie flash moba. Wzięli w nim udział sportowcy światowej klasy🏅 i przyjaciele gwiazdy, tacy jak @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official. Dziękujemy wszystkim, którzy wzięli udział w tym wydarzeniu. Mianowicie organizatorzy, bez których nasza impreza by się nie odbyła: @viktoriya_tka4 @ deniskonovalov @m_galat @vladimir_drachuk @franz_audio Jesteście najlepsi 🫂",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "FERIADO \"RUMO À VITÓRIA\"",
        short_desc: 'REALIZAMOS O FERIADO "RUMO À VITÓRIA" Um evento desportivo para 500 crianças, criado com o apoio da administração regional para que as nossas crianças sigam em frente, não se esqueçam dos seus objetivos e conquistas, apesar da guerra 🙏 Estamos infinitamente gratos a @_oleksandrleshchenko por organizar o flash mob Atletas de classe mundial🏅 e amigos famosos como @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official participaram. acontecido: @viktoriya_tka4 @ deniskonovalov @m_galat @vladimir_drachuk @franz_audio Vocês são os melhores 🫂',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-13/1.jpg"),
    time: "06.10.2023",
    img_report: [],
    letters_thanks: {
      state: false,
    },
    video: {
      state: true,
      link: "bvMJDdlBfpE",
    },
  },
  {
    id: 11,
    data: {
      "en-US": {
        title: "\"Land of the Unbreakable\" in Odesa ⚓️",
        short_desc: '"Land of the Unbreakable" in Odessa ⚓️ We, the charitable foundation "Who, If Not Us" held a grand celebration for IDP children in Odessa 🙌🏻 "Land of the Unbreakable" is a celebration for 2,000 people, which gave children and adults a lot of bright emotions, a fiery mood and exciting impressions 🙏 Free foot court with unlimited ice cream🍦, cotton candy, popcorn🍿, drinks Cool fun animation, face painting 🎭 We also thank our partners for being with us that day, and of course for the delicious pizza🍕 @siobhanstrust Large-scale drum master class from @david._.davidyan (over 300 drums were involved🥁) Thank you to everyone who participated in the organization @viktoriya_tka4 @deniskonovalov @vladimir_drachuk @nasti_belozerova Unsurpassed presenters @yuriy_tkach @mila.eremeeva Star friends @a.wellboy @positiff @mishvirmish @mashaa.kondratenko @uliana_royce @mashukovsky @magic_five.official @amadorlopez_rumberos @david._.davidyan We also invited children from the Svitanok orphanage 🫶🏻 We give emotions of happiness and a fun childhood to our children🙏',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `«Країна Незламних» в місті Одеса ⚓️`,
        short_desc: `«Країна Незламних» в місті Одеса⚓️ Ми, благодійний фонд «Хто, Якщо не ми» провели грандіозне свято для діток ВПО в місті Одеса🙌🏻 «Країна Незламних» - це свято на 2 000 людей, яке подарувало діткам та дорослим велику кількість яскравих емоцій, запальний настрій та захоплюючі враження 🙏 Безкоштовний фут-корт з необмеженою кількістю морозива🍦, солодкої вати, поп-корну🍿, напоїв Крутезна весела анімація, аквагрим 🎭 Також дякуємо нашим партнерам за те, що були з нами в цей день, та звісно за смачну піцу🍕 @siobhanstrust Масштабний майстер-клас на барабанах від @david._.davidyan ( було задіяно понад 300 барабанів🥁) Дякуємо всім, хто брав участь в організації @viktoriya_tka4 @deniskonovalov @vladimir_drachuk @nasti_belozerova Неперевершені ведучі @yuriy_tkach @mila.eremeeva Зіркові друзі @a.wellboy @positiff @mishvirmish @mashaa.kondratenko @uliana_royce @mashukovsky @magic_five.official @amadorlopez_rumberos @david._.davidyan Також ми запросили діток з дитячого будинку «Світанок»🫶🏻 Даруємо емоції щастя та веселого дитинства нашим діткам🙏`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "„Land der Unzerbrechlichen“ in Odessa ⚓️",
        short_desc: "„Land der Unzerbrechlichen“ in der Stadt Odessa Wir, die Wohltätigkeitsstiftung „Wer, wenn nicht wir“, haben eine große Feier für Binnenflüchtlingskinder in der Stadt Odessa abgehalten🙌🏻 „Land der Unzerbrechlichen“ ist eine Feier für 2.000 Menschen , die Kindern und Erwachsenen viele helle Emotionen, feurige Stimmung und spannende Erlebnisse beschert hat 🙏 Kostenloser Foot Court mit unbegrenzt Eis🍦, Zuckerwatte, Popcorn🍿, Getränken Coole lustige Animation, Gesichtsbemalung 🎭 Wir danken auch unseren Partnern für die Unterstützung uns an diesem Tag und natürlich für leckere Pizza🍕 @siobhanstrust Groß angelegter Schlagzeug-Meisterkurs von @david._.davidyan (über 300 Trommeln wurden verwendet🥁) Vielen Dank an alle, die an der Organisation teilgenommen haben @viktoriya_tka4 @deniskonovalov @vladimir_drachuk @nasti_belozerova Unübertroffene Moderatoren @yuriy_tkach @mila.eremeeva Star-Freunde @a.wellboy @positiff @mishvirmish @mashaa.kondratenko @uliana_royce @mashukovsky @magic_five.official @amadorlopez_rumberos @david._.davidyan Wir haben auch Kinder aus dem Waisenhaus Svitanok eingeladen 🫶🏻 Wir geben Emotionen Glück und unseren Kindern eine glückliche Kindheit🙏",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "\"La tierra de lo inquebrantable\" en Odesa ⚓️",
        short_desc: '"La tierra de lo inquebrantable" en la ciudad de Odessa Nosotros, la fundación benéfica "Quién, si no nosotros" celebramos una gran fiesta para los niños desplazados en la ciudad de Odessa🙌🏻 "La tierra de lo inquebrantable" es una fiesta para 2.000 personas , que brindó a niños y adultos una gran cantidad de emociones brillantes, un estado de ánimo ardiente y experiencias emocionantes 🙏 Cancha de fútbol gratuita con helado ilimitado🍦, algodón de azúcar, palomitas de maíz🍿, bebidas Animación divertida y genial, pintura facial 🎭 También agradecemos a nuestros socios por estar con nosotros nosotros en este día, y por supuesto por la deliciosa pizza🍕 @siobhanstrust Clase magistral de batería a gran escala por @david._.davidyan (se utilizaron más de 300 tambores🥁) Gracias a todos los que participaron en la organización @viktoriya_tka4 @deniskonovalov @vladimir_drachuk @nasti_belozerova Presentadores insuperables @yuriy_tkach @mila.eremeeva Amigos estrellas @a.wellboy @positiff @mishvirmish @mashaa.kondratenko @uliana_royce @mashukovsky @magic_five.official @amadorlopez_rumberos @david._.davidyan También invitamos a niños del orfanato Svitanok 🫶🏻 Damos felicidad a las emociones y una infancia feliz para nuestros hijos🙏',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "«Terre de l'incassable» à Odessa ⚓️",
        short_desc: "\"Terre de l'incassable\" dans la ville d'Odessa Nous, la fondation caritative \"Qui, sinon nous\" avons organisé une grande fête pour les enfants déplacés dans la ville d'Odessa🙌🏻 \"Terre de l'incassable\" est une fête pour 2 000 personnes , qui a donné aux enfants et aux adultes un grand nombre d'émotions vives, une humeur enflammée et des expériences passionnantes 🙏 Terrain de foot gratuit avec glaces à volonté 🍦, barbe à papa, pop-corn 🍿, boissons Animation amusante et cool, maquillage 🎭 Nous remercions également nos partenaires d'être avec nous nous ce jour-là, et bien sûr pour une délicieuse pizza 🍕 @siobhanstrust Master class de batterie à grande échelle par @david._.davidyan (plus de 300 tambours ont été utilisés 🥁) Merci à tous ceux qui ont participé à l'organisation @viktoriya_tka4 @deniskonovalov @vladimir_drachuk @nasti_belozerova Des présentateurs hors pair @yuriy_tkach @mila.eremeeva Amis stars @a.wellboy @positiff @mishvirmish @mashaa.kondratenko @uliana_royce @mashukovsky @magic_five.official @amadorlopez_rumberos @david._.davidyan Nous avons également invité les enfants de l'orphelinat Svitanok 🫶🏻 Nous donnons du bonheur aux émotions et une enfance joyeuse à nos enfants🙏",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "\"Terra dell'indistruttibile\" a Odessa ⚓️",
        short_desc: "\"Terra degli Indistruttibili\" nella città di Odessa Noi, la fondazione benefica \"Chi, se non noi\" abbiamo organizzato una grande festa per i bambini sfollati nella città di Odessa🙌🏻 \"Terra degli Indistruttibili\" è una festa per 2.000 persone , che ha regalato a bambini e adulti un gran numero di emozioni luminose, umore infuocato ed esperienze emozionanti 🙏 Campo da calcetto gratuito con gelato illimitato🍦, zucchero filato, popcorn🍿, bevande Animazione divertente e divertente, truccabimbi 🎭 Ringraziamo anche i nostri partner per essere stati con noi noi in questo giorno, e naturalmente per la deliziosa pizza🍕 @siobhanstrust Masterclass di batteria su larga scala di @david._.davidyan (sono stati utilizzati oltre 300 tamburi🥁) Grazie a tutti coloro che hanno partecipato all'organizzazione @viktoriya_tka4 @deniskonovalov @vladimir_drachuk @nasti_belozerova Presentatori insuperabili @yuriy_tkach @mila.eremeeva Amici delle stelle @a.wellboy @positiff @mishvirmish @mashaa.kondratenko @uliana_royce @mashukovsky @magic_five.official @amadorlopez_rumberos @david._.davidyan Abbiamo invitato anche i bambini dell'orfanotrofio di Svitanok 🫶🏻 Regaliamo emozioni felicità e un'infanzia gioiosa ai nostri figli🙏",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "„Kraina Niezniszczalnego” w Odessie ⚓️",
        short_desc: "„Kraina niezniszczalnego” w mieście Odessa My, fundacja charytatywna „Who, If Not We” zorganizowaliśmy wielką uroczystość dla dzieci wewnętrznie przesiedlonych w mieście Odessa🙌🏻 „Kraina niezniszczalnego” to uroczystość dla 2000 osób , które dostarczyło dzieciom i dorosłym mnóstwo jasnych emocji, ognistego nastroju i ekscytujących przeżyć 🙏 Bezpłatne boisko do piłki nożnej z nieograniczoną ilością lodów🍦, waty cukrowej, popcornu🍿, napojów Fajna, zabawna animacja, malowanie twarzy 🎭 Dziękujemy również naszym partnerom za to, że jesteście z nami nas w tym dniu i oczywiście za pyszną pizzę🍕 @siobhanstrust Ogromny kurs mistrzowski gry na bębnach prowadzony przez @david._.davidyan (użyto ponad 300 bębnów🥁) Dziękujemy wszystkim, którzy wzięli udział w organizacji @viktoriya_tka4 @deniskonovalov @vladimir_drachuk @nasti_belozerova Niezrównani prezenterzy @yuriy_tkach @mila.eremeeva Przyjaciele gwiazdy @a.wellboy @positiff @mishvirmish @mashaa.kondratenko @uliana_royce @mashukovsky @magic_five.official @amadorlopez_rumberos @david._.davidyan Zaprosiliśmy również dzieci z sierocińca Svitanok 🫶🏻 Dajemy emocjom szczęście i radosnego dzieciństwa dla naszych dzieci🙏",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "\"Terra do Inquebrável\" em Odessa ⚓️",
        short_desc: '"Terra do Inquebrável" na cidade de Odessa ️ Nós, a fundação de caridade "Who, If Not We" realizamos uma grande celebração para crianças IDP na cidade de Odessa🙌🏻 "Terra do Inquebrável" é uma celebração para 2.000 pessoas, que proporcionou às crianças e adultos um grande número de emoções brilhantes, clima de fogo e experiências emocionantes 🙏 Campo de futebol gratuito com gelado à discrição🍦, algodão doce, pipocas🍿, bebidas Animação divertida e cool, pinturas faciais 🎭 Agradecemos também aos nossos parceiros por estarem connosco nós neste dia, e claro, pela deliciosa pizza🍕 @siobhanstrust Aula de bateria em grande escala por @david._.davidyan (foram utilizados mais de 300 tambores🥁) Obrigado a todos os que participaram na organização @viktoriya_tka4 @deniskonovalov @vladimir_drachuk @nasti_belozerova Apresentadores insuperáveis @yuriy_tkach @mila.eremeeva Amigos famosos @a.wellboy @positiff @mishvirmish @mashaa.kondratenko @uliana_royce @mashukovsky @magic_five.official @amadorlopez_rumberos @david._.davidyan Também convidamos crianças do orfanato Svitanok 🫶🏻 Damos emoções e felicidade e uma infância feliz para os nossos filhos🙏',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-12/1.jpg"),
    time: "28.07.2023",
    img_report: [],
    letters_thanks: {
      state: false,
    },
    video: {
      state: true,
      link: "deDgHlHqlPA",
    },
  },
  {
    id: 10,
    data: {
      "en-US": {
        title: "Holiday \"Land of the Invincible\"",
        short_desc: 'We report! We held the holiday 17.06 "Land of the Invincible" with the support of the Dnipropetrovsk Regional Military Administration 🫂 Holiday for children of IDPs from different parts of the country 🫶🏻at the location Lavina @lavinapark With the support of our star friends @annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila.eremeeva @viktoriya_tka4 and @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official Our military, who defend our country and give us the opportunity to create holidays, received 28 cars and more than 20 pieces of equipment 🚐',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Свято «Країна незламних»`,
        short_desc: `Звітуємо! Провели свято 17.06 «Країна незламних» за підтримки Дніпропетровської Областної Військової адміністрації 🫂 Свято для дітей впо з різних куточків країни 🫶🏻на локаціі Лавина @lavinapark За підтримки наших зіркових друзів @annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila.eremeeva @viktoriya_tka4 та @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official Наші військові, які боронять нашу країну та дають можливість створювати свята, отримали 28 одиниц авто та понадо 20 одиниц техніки 🚐`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Urlaub \"Land der Unbesiegbaren\"",
        short_desc: "Wir berichten! Am 17.06. veranstalteten wir mit Unterstützung der Militärverwaltung der Region Dnipropetrowsk einen Feiertag „Land der Unbesiegbaren“ 🫂 Ein Feiertag für Kinder von Binnenflüchtlingen aus verschiedenen Teilen des Landes 🫶🏻 am Standort Lavina @lavinapark Mit der Unterstützung unserer Starfreunde @ annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila.eremeeva @viktoriya_tka4 und @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official Unsere Militärangehörigen, die unser Land verteidigen und die Schaffung von Feiertagen ermöglichen, erhielten 28 Autos und mehr als 20 Ausrüstungsgegenstände 🚐",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Vacaciones \"Tierra de los Invencibles\"",
        short_desc: '¡Informamos! Celebramos el 17.06 una fiesta "Tierra de los Invencibles" con el apoyo de la Administración Militar Regional de Dnipropetrovsk 🫂 Una fiesta para los hijos de los desplazados internos de diferentes partes del país 🫶🏻en el lugar de Lavina @lavinapark Con el apoyo de nuestros amigos estrella @ annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila.eremeeva @viktoriya_tka4 y @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official Nuestros militares, que defienden nuestro país y hacen posible la creación de días festivos, recibieron 28 Coches y más de 20 piezas de equipamiento 🚐',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Vacances \"Terre des Invincibles\"",
        short_desc: "Nous rapportons ! Nous avons organisé une fête le 17.06 \"Terre de l'Invincible\" avec le soutien de l'Administration militaire régionale de Dnipropetrovsk 🫂 Une fête pour les enfants de déplacés internes de différentes régions du pays 🫶🏻 sur le site de Lavina @lavinapark Avec le soutien de nos amis stars @ annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila. eremeeva @viktoriya_tka4 et @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official Nos militaires, qui défendent notre pays et permettent de créer des fêtes, ont reçu 28 voitures et plus de 20 pièces d'équipement 🚐",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Festa \"Terra degli Invincibili\"",
        short_desc: "Vi informiamo! Abbiamo organizzato una festa il 17.06 \"Terra degli invincibili\" con il supporto dell'Amministrazione militare regionale di Dnipropetrovsk 🫂 Una festa per i figli degli sfollati interni provenienti da diverse parti del paese 🫶🏻nella località di Lavina @lavinapark Con il supporto dei nostri amici star @ annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila. eremeeva @viktoriya_tka4 e @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official I nostri militari, che difendono il nostro paese e rendono possibile la creazione di festività, hanno ricevuto 28 auto e più di 20 pezzi di equipaggiamento 🚐",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Wakacje „Kraina Niezwyciężonych”",
        short_desc: "Zgłaszamy! 17.06 zorganizowaliśmy święto „Kraina Niezwyciężonych” przy wsparciu Dniepropietrowskiej Obwodowej Administracji Wojskowej 🫂 Święto dla dzieci przesiedleńców wewnętrznych z różnych części kraju 🫶🏻 w ośrodku Lavina @lavinapark Przy wsparciu naszych przyjaciół gwiazd @ annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila. eremeeva @viktoriya_tka4 i @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official Nasi wojskowi, którzy bronią naszego kraju i umożliwiają organizowanie świąt, otrzymali 28 samochody i ponad 20 sztuk sprzętu 🚐",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Feriado \"Terra do Invencível\"",
        short_desc: 'Nós reportamos! Realizamos um feriado no dia 17.06 "Terra do Invencível" com o apoio da Administração Militar Regional de Dnipropetrovsk 🫂 Um feriado para os filhos de deslocados internos de diferentes partes do país 🫶🏻 no local de Lavina @lavinapark Com o apoio dos nossos amigos famosos @ annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila. de 20 equipamentos 🚐',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-11/1.jpg"),
    time: "17.06.2023",
    img_report: [],
    letters_thanks: {
      state: false,
    },
    video: {
      state: true,
      link: "I_3hYLp_lsU",
    },
  },
  {
    id: 9,
    data: {
      "en-US": {
        title: "Children are our wealth and our future",
        short_desc:
          'The first lesson of the group for children of psychological support on the project "NEAR" 🕊 Children are our future, and in order to create maximum support for them now, the psychologists of the OK Psychology Center work under the program of the Ministry of Education and Science of Ukraine, UNICEF - where they help children minimize the consequences of war in their lives. Kids have something to say, they feel even more than you can imagine',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Співпраця з центром психологічної підтримки "Ок"`,
        short_desc: `Перше заняття групи для дітей психологічної підтримки по проекту «ПОРУЧ» 🕊 Діти - наше майбутнє і щоб створити для них зараз максимальну підтримку, психологині  центру психології  ОК працюють за програмою Міністерства освіти та науки України, ЮНІСЕФ – де допомогають дітям мінімізувати наслідки війни в їх життях. Дітям є про що сказати, вони відчувають навіть більше, ніж ви можете уявити`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Kindertag",
        short_desc:
          "Die erste Unterrichtsstunde der Gruppe für Kinder der psychologischen Betreuung zum Projekt „NEAR“ 🕊 Kinder sind unsere Zukunft, und um jetzt maximale Unterstützung für sie zu schaffen, arbeiten die Psychologen des OK-Psychologiezentrums im Rahmen des Programms des Ministeriums für Bildung und Wissenschaft der Ukraine, UNICEF, wo sie Kindern helfen, die Folgen des Krieges in ihrem Leben zu minimieren lebt. Kinder haben etwas zu sagen, sie fühlen noch mehr, als Sie sich vorstellen können",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Día del Niño",
        short_desc:
          'La primera lección del grupo para niños de apoyo psicológico sobre el proyecto "NEAR" 🕊 Los niños son nuestro futuro, y para crear el máximo apoyo para ellos ahora, los psicólogos del Centro de Psicología OK trabajan bajo el programa del Ministerio de Educación y Ciencia de Ucrania, UNICEF, donde ayudan a los niños a minimizar las consecuencias de la guerra en su vive. Los niños tienen algo que decir, sienten incluso más de lo que te imaginas',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "La journée des enfants",
        short_desc:
          "Le premier cours du groupe pour enfants de soutien psychologique sur le projet \"NEAR\" 🕊 Les enfants sont notre avenir, et afin de créer un maximum de soutien pour eux maintenant, les psychologues du centre de psychologie OK travaillent dans le cadre du programme du ministère de l'Éducation et des Sciences de l'Ukraine, UNICEF - où ils aident les enfants à minimiser les conséquences de la guerre dans leur des vies. Les enfants ont quelque chose à dire, ils ressentent encore plus que vous ne pouvez l'imaginer",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Giorno dei bambini",
        short_desc:
          "La prima lezione del gruppo per bambini di sostegno psicologico sul progetto \"VICINO\" 🕊 I bambini sono il nostro futuro e, al fine di creare loro il massimo sostegno ora, gli psicologi del Centro di Psicologia OK lavorano nell'ambito del programma del Ministero dell'Istruzione e della Scienza dell'Ucraina, UNICEF - dove aiutano i bambini a ridurre al minimo le conseguenze della guerra nella loro vite. I bambini hanno qualcosa da dire, si sentono anche più di quanto tu possa immaginare",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dzień Dziecka",
        short_desc:
          "Pierwsza lekcja grupy dla dzieci wsparcia psychologicznego w ramach projektu „BLIŻEJ” Dzieci są naszą przyszłością i aby teraz stworzyć dla nich maksymalne wsparcie, psychologowie Centrum Psychologii OK działają w ramach programu Ministerstwa Edukacji i Nauki Ukrainy UNICEF - gdzie pomagają dzieciom zminimalizować konsekwencje wojny w ich zyje. Dzieci mają coś do powiedzenia, czują nawet więcej, niż możesz sobie wyobrazić",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Dia das Crianças",
        short_desc:
          'A primeira aula do grupo para crianças de apoio psicológico no projeto "NEAR" 🕊 As crianças são o nosso futuro e, para lhes dar o máximo apoio agora, os psicólogos do OK Psychology Center trabalham no âmbito do programa do Ministério da Educação e Ciência da Ucrânia, UNICEF - onde ajudam as crianças a minimizar as consequências da guerra nas suas vidas. As crianças têm algo a dizer, elas sentem ainda mais do que você pode imaginar',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-10/2.JPG"),
    time: "04.06.2022",
    img_report: [
      { img: require("../assets/img/news/news-10/1.JPG") },
      { img: require("../assets/img/news/news-10/2.JPG") },
      { img: require("../assets/img/news/news-10/3.JPG") },
      { img: require("../assets/img/news/news-10/4.JPG") },
    ],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
  {
    id: 8,
    data: {
      "en-US": {
        title: "Children are our wealth and our future",
        short_desc:
          'That\'s why we created a competition for our children🥳 Conditions: Children up to 6 years old will need to record their talent on video - poetry, song, dance, any creative production💃 And children over 7 years old will need to create any product you know - something sew, blind, tie, glue, draw - the flight of fancy is not limited. Do not forget to videotape the work process and bring it with the product to the office of our foundation 🚀 A prerequisite for everyone - there must be something patriotic in the frame. Or in clothes, for example to be in an embroidered shirt or colors of a flag, or in jewelry - bracelets, earrings. Maybe there will be a picture or a Ukrainian song in the frame🤗 At the end of the video the child will say the following phrase: "Who, if not me, will support my country" 🎉 The winners will be chosen by voting on Instagram in the fund. Voting starts on June 17 And, of course, the most important information. Prizes provided by the children\'s toy store "Bontoy"',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Діти — це наше багатство і наше майбутнє`,
        short_desc: `Тому ми створили конкурс для наших діток🥳 Умови: Діткам до 6 років треба буде свій талант записати на відео - вірш, пісня, танець, будь-яка творча постановка💃 А дітям старше 7 років треба створити виріб будь-який, яку вмієте - щось пошити, сліпити, зв\'язати, склеїти, намалювати - політ фантазії не обмежений. Процес роботи не забудьте зняти на відео і принести разом з виробом в офіс нашого фонду🚀 Обов\'язкова умова для усіх - в кадрі повинно бути щось патріотичне. Або в одязі, наприклад бути у вишиванці або кольорах прапора, або в прикрасах - браслети, сережки. Може в кадр буде якийсь малюнок або звучати українська пісня🤗 У кінці відео дитина скаже таку фразу: "Хто, як не я, підтримає мою країну" 🎉 Переможці будуть вибрані шляхом голосування в инстаграм в фонду. Старт голосування 17 червня. І, звичайно, найголовніша інформація. Призи надані магазином дитячих іграшок "Bontoy"`,
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Kindertag",
        short_desc:
          'Aus diesem Grund haben wir einen Wettbewerb für unsere Kinder ins Leben gerufen🥳 Bedingungen: Kinder bis 6 Jahre müssen ihr Talent auf Video festhalten – Poesie, Gesang, Tanz, jede kreative Produktion💃 Und Kinder über 7 Jahre müssen jedes von Ihnen erstellte Produkt erstellen wissen - etwas nähen, blind machen, binden, kleben, zeichnen - der Fantasie sind keine Grenzen gesetzt. Vergesst nicht den Arbeitsprozess auf Video aufzunehmen und mit dem Produkt ins Büro unserer Stiftung zu bringen 🚀 Voraussetzung für alle - es muss etwas Patriotisches im Rahmen sein. Oder in Kleidung, zum Beispiel in einem bestickten Hemd oder in den Farben einer Flagge, oder in Schmuck - Armbändern, Ohrringen. Vielleicht wird ein Bild oder ein ukrainisches Lied im Rahmen sein🤗 Am Ende des Videos sagt das Kind den folgenden Satz: „Wer, wenn nicht ich, wird mein Land unterstützen“ 🎉 Die Gewinner werden per Abstimmung auf Instagram ermittelt im Fonds. Die Abstimmung startet am 17. Juni Und natürlich die wichtigsten Informationen. Preise des Kinderspielzeugladens "Bontoy"',
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Día del Niño",
        short_desc:
          'Es por eso que creamos un concurso para nuestros niños🥳 Condiciones: Los niños de hasta 6 años deberán registrar su talento en video: poesía, canción, baile, cualquier producción creativa💃 Y los niños mayores de 7 años deberán crear cualquier producto que saber - algo coser, cegar, atar, pegar, dibujar - el vuelo de la fantasía no está limitado. No olvide grabar en video el proceso de trabajo y llevarlo con el producto a la oficina de nuestra fundación 🚀 Un requisito previo para todos: debe haber algo patriótico en el marco. O en ropa, por ejemplo para estar en una camisa bordada o colores de una bandera, o en joyas - pulseras, aretes. Tal vez haya una foto o una canción ucraniana en el marco 🤗 Al final del video, el niño dirá la siguiente frase: "¿Quién, si no yo, apoyará a mi país?" 🎉 Los ganadores serán elegidos votando en Instagram en el fondo La votación comienza el 17 de junio Y, por supuesto, la información más importante. Premios proporcionados por la juguetería infantil "Bontoy"',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "La journée des enfants",
        short_desc:
          "C'est pourquoi nous avons créé un concours pour nos enfants🥳 Conditions : Les enfants jusqu'à 6 ans devront enregistrer leur talent sur vidéo - poésie, chanson, danse, toute production créative💃 Et les enfants de plus de 7 ans devront créer n'importe quel produit que vous savoir - quelque chose coudre, aveugler, attacher, coller, dessiner - le vol de fantaisie n'est pas limité. N'oubliez pas de filmer le processus de travail et de l'apporter avec le produit au bureau de notre fondation 🚀 Une condition préalable pour tout le monde - il doit y avoir quelque chose de patriotique dans le cadre. Ou dans les vêtements, par exemple pour être dans une chemise brodée ou aux couleurs d'un drapeau, ou dans des bijoux - bracelets, boucles d'oreilles. Peut-être y aura-t-il une photo ou une chanson ukrainienne dans le cadre🤗 A la fin de la vidéo l'enfant dira la phrase suivante : \"Qui, si ce n'est moi, soutiendra mon pays\" 🎉 Les gagnants seront choisis en votant sur Instagram dans le fonds. Le vote commence le 17 juin Et, bien sûr, l'information la plus importante. Lots offerts par le magasin de jouets pour enfants \"Bontoy\"",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Giorno dei bambini",
        short_desc:
          'Ecco perché abbiamo creato un concorso per i nostri bambini🥳 Condizioni: i bambini fino a 6 anni dovranno registrare il loro talento in video - poesia, canto, danza, qualsiasi produzione creativa💃 E i bambini di età superiore ai 7 anni dovranno creare qualsiasi prodotto tu sapere - qualcosa cuci, acceca, lega, incolla, disegna - il volo della fantasia non è limitato. Non dimenticare di filmare il processo di lavoro e portarlo con il prodotto nell\'ufficio della nostra fondazione 🚀 Un prerequisito per tutti: ci deve essere qualcosa di patriottico nell\'inquadratura. O nei vestiti, ad esempio per essere in una camicia ricamata o nei colori di una bandiera, o in gioielli: bracciali, orecchini. Magari nella cornice ci sarà una foto o una canzone ucraina🤗 Alla fine del video il bambino pronuncerà la seguente frase: "Chi, se non io, sosterrà il mio paese" 🎉 I vincitori saranno scelti votando su Instagram nel fondo. Le votazioni iniziano il 17 giugno E, naturalmente, le informazioni più importanti. Premi forniti dal negozio di giocattoli per bambini "Bontoy"',
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dzień Dziecka",
        short_desc:
          "Dlatego stworzyliśmy konkurs dla naszych dzieci🥳 Warunki: Dzieci w wieku do 6 lat będą musiały nagrywać swój talent na wideo - poezja, piosenka, taniec, każda kreatywna produkcja💃 A dzieci powyżej 7 lat będą musiały stworzyć dowolny produkt, który wiesz - coś uszyj, zaślep, zawiąż, przyklej, narysuj - lot fantazji nie jest ograniczony. Nie zapomnij nagrać na taśmę wideo procesu pracy i zabrać go z produktem do biura naszej fundacji 🚀 Warunek wstępny dla każdego - w kadrze musi być coś patriotycznego. Albo w ubraniach, np. do haftowanej koszuli lub kolorów flagi, albo w biżuterii - bransoletkach, kolczykach. Może w kadrze będzie zdjęcie lub ukraińska piosenka Na końcu filmu dziecko powie zdanie: „Kto, jeśli nie ja, wesprze mój kraj” 🎉 Zwycięzców wyłoni głosowanie na Instagramie w funduszu. Głosowanie rozpoczyna się 17 czerwca No i oczywiście najważniejsze informacje. Nagrody zapewnione przez sklep z zabawkami dla dzieci „Bontoy”",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Dia das Crianças",
        short_desc:
          'Por isso criamos um concurso para nossas crianças🥳 Condições: Crianças até 6 anos precisarão registrar seu talento em vídeo - poesia, música, dança, qualquer produção criativa💃 E crianças acima de 7 anos precisarão criar qualquer produto que você sabe - costurar, cegar, amarrar, colar, desenhar - o vôo da fantasia não é limitado. Não se esqueça de filmar o processo de trabalho e trazê-lo com o produto para o escritório de nossa fundação 🚀 Um pré-requisito para todos - deve haver algo patriótico no quadro. Ou em roupas, por exemplo, em uma camisa bordada ou nas cores de uma bandeira, ou em joias - pulseiras, brincos. Talvez haja uma foto ou uma música ucraniana no quadro🤗 Ao final do vídeo a criança dirá a seguinte frase: "Quem, se não eu, apoiará meu país" 🎉 Os vencedores serão escolhidos por votação no Instagram no fundo. A votação começa em 17 de junho E, claro, as informações mais importantes. Prêmios fornecidos pela loja de brinquedos infantis "Bontoy"',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-9/1.jpg"),
    time: "02.06.2022",
    img_report: [{ img: require("../assets/img/news/news-9/1.jpg") }],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
  {
    id: 7,
    data: {
      "en-US": {
        title: "Children's Day",
        short_desc:
          "Since the beginning of the Russian invasion, 243 children have died. Every day in our country 2 children die and 4 are injured ... ❤️ Russia steals childhood from our children, but we believe that it will not be able to steal the future from them. From an early age they grow up to be real Ukrainians. They learned what evil is, but they also learned what good, strength, courage, fidelity are! Great love for their homeland, which will grow with them and grow stronger every day. We will win! 🕊 For their sake! For the sake of the future! Bright and kind! Everything will be Ukraine 💙💛 It was on 01.06.2022 that we decided to create a holiday for our children. We had a holiday for 100 children and this is just the beginning) After all, who but us, is able to protect them😍",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `День захисту дітей`,
        short_desc:
          "З початку вторгнення росії загинуло 243 дитини. Кожного дня в нашій країні гине 2 дитини та 4 отримують поранення...❤️ росія краде дитинство у наших дітей, але ми віримо, що майбутнє вона не зможе в них вкрасти. Вже з малечку вони ростуть справжніми українцями Вони дізналися, що таке зло, але вони дізналися і що таке добро, сила, відвага, вірність! Величезна любов до своєї Батьківщини, яка буде рости разом з ними й кріпнути кожен день. Ми переможемо! 🕊 За ради них! За ради майбутнього! Світлого і доброго! Все буде Україна 💙💛 Саме 01.06.2022 ми вирішили створити свято для наших діток. Нас було свято на 100 дітей і це тільки початок) Адже,хто як не ми,в силах їх захистити😍",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Kindertag",
        short_desc:
          "Seit Beginn der russischen Invasion sind 243 Kinder gestorben. Jeden Tag sterben in unserem Land 2 Kinder und 4 werden verletzt ... ❤️ Russland stiehlt unseren Kindern die Kindheit, aber wir glauben, dass es ihnen die Zukunft nicht stehlen kann. Von klein auf werden sie zu echten Ukrainern, sie haben gelernt, was böse ist, aber sie haben auch gelernt, was gut, Stärke, Mut, Treue sind! Große Liebe zu ihrer Heimat, die mit ihnen wachsen und jeden Tag stärker werden wird. Wir werden gewinnen! 🕊 Ihnen zuliebe! Der Zukunft zuliebe! Hell und freundlich! Alles wird Ukraine 💙💛 Am 01.06.2022 haben wir beschlossen, einen Feiertag für unsere Kinder zu schaffen. Wir hatten einen Urlaub für 100 Kinder und das ist erst der Anfang) Denn wer außer uns kann sie beschützen😍",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Día del Niño",
        short_desc:
          "Desde el comienzo de la invasión rusa han muerto 243 niños. Todos los días en nuestro país mueren 2 niños y 4 resultan heridos... ❤️ Rusia les roba la infancia a nuestros hijos, pero creemos que no podrá robarles el futuro. Desde una edad temprana crecen para ser verdaderos ucranianos. Aprendieron lo que es el mal, pero también aprendieron lo que es el bien, la fuerza, el coraje y la fidelidad. Gran amor por su patria, que crecerá con ellos y se fortalecerá cada día. ¡Ganaremos! 🕊 ¡Por su bien! ¡Por el bien del futuro! ¡Brillante y amable! Todo será Ucrania 💙💛 Fue el 01.06.2022 que decidimos crear unas vacaciones para nuestros hijos. Tuvimos unas vacaciones para 100 niños y esto es solo el comienzo) Después de todo, quién sino nosotros puede protegerlos😍",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "La journée des enfants",
        short_desc:
          "Depuis le début de l'invasion russe, 243 enfants sont morts. Chaque jour dans notre pays 2 enfants meurent et 4 sont blessés... ❤️ La Russie vole l'enfance à nos enfants, mais nous pensons qu'elle ne pourra pas leur voler l'avenir. Dès leur plus jeune âge, ils ont grandi pour devenir de vrais Ukrainiens, ils ont appris ce qu'est le mal, mais ils ont aussi appris ce que sont le bien, la force, le courage, la fidélité ! Un grand amour pour leur patrie, qui grandira avec eux et se renforcera chaque jour. Nous gagnerons! 🕊 Pour eux ! Au nom de l'avenir ! Lumineux et gentil ! Tout sera Ukraine 💙💛 C'est le 01.06.2022 que nous avons décidé de créer des vacances pour nos enfants. Nous avons eu des vacances pour 100 enfants et ce n'est que le début) Après tout, qui d'autre que nous est capable de les protéger😍",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Giorno dei bambini",
        short_desc:
          "Dall'inizio dell'invasione russa sono morti 243 bambini. Ogni giorno nel nostro Paese muoiono 2 bambini e 4 rimangono feriti... ❤️ La Russia ruba l'infanzia ai nostri figli, ma crediamo che non riuscirà a rubare loro il futuro. Fin da piccoli sono diventati dei veri ucraini, hanno imparato cos'è il male, ma hanno anche imparato cosa sono il bene, la forza, il coraggio, la fedeltà! Grande amore per la loro terra, che crescerà con loro e si rafforzerà ogni giorno. Vinceremo! 🕊 Per il loro bene! Per il bene del futuro! Luminoso e gentile! Tutto sarà Ucraina 💙💛 È stato il 01.06.2022 che abbiamo deciso di creare una vacanza per i nostri bambini. Abbiamo fatto una vacanza per 100 bambini e questo è solo l'inizio) In fondo chi, se non noi, può proteggerli😍",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dzień Dziecka",
        short_desc:
          "Od początku rosyjskiej inwazji zginęło 243 dzieci. Każdego dnia w naszym kraju ginie dwoje dzieci, a czwórka zostaje rannych… ❤️ Rosja kradnie naszym dzieciom dzieciństwo, ale wierzymy, że nie będzie w stanie ukraść im przyszłości. Od najmłodszych lat wyrośli na prawdziwych Ukraińców, nauczyli się, czym jest zło, ale też nauczyli się, czym jest dobro, siła, odwaga, wierność! Wielka miłość do ojczyzny, która będzie rosła wraz z nimi i z każdym dniem stawała się silniejsza. Wygramy! 🕊 Ze względu na nich! W trosce o przyszłość! Jasny i miły! Wszystko będzie Ukrainą 💙💛 To właśnie 01.06.2022 postanowiliśmy stworzyć wakacje dla naszych dzieci. Mieliśmy wakacje na 100 dzieci i to dopiero początek) W końcu kto oprócz nas jest w stanie je ochronić😍",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Dia das Crianças",
        short_desc:
          "Desde o início da invasão russa, 243 crianças morreram. Todos os dias em nosso país morrem 2 crianças e 4 ficam feridas ... ❤️ A Rússia rouba a infância de nossos filhos, mas acreditamos que não poderá roubar o futuro deles. Desde cedo eles se tornam verdadeiros ucranianos, aprenderam o que é o mal, mas também o que é o bem, a força, a coragem, a fidelidade! Grande amor por sua pátria, que crescerá com eles e se fortalecerá a cada dia. Nós ganharemos! 🕊 Pelo bem deles! Em prol do futuro! Brilhante e gentil! Tudo será Ucrânia 💙💛 Foi no dia 01.06.2022 que decidimos criar um feriado para os nossos filhos. Tivemos um feriado para 100 crianças e isso é só o começo) Afinal, quem além de nós, é capaz de protegê-los😍",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-8/preview.jpg"),
    time: "01.06.2022",
    img_report: [
      { img: require("../assets/img/news/news-8/1.jpg") },
      { img: require("../assets/img/news/news-8/2.jpg") },
      { img: require("../assets/img/news/news-8/3.jpg") },
      { img: require("../assets/img/news/news-8/5.jpg") },
      { img: require("../assets/img/news/news-8/6.jpg") },
      { img: require("../assets/img/news/news-8/8.jpg") },
      { img: require("../assets/img/news/news-8/4.jpg") },
      { img: require("../assets/img/news/news-8/7.jpg") },
      { img: require("../assets/img/news/news-8/9.jpg") },
      { img: require("../assets/img/news/news-8/10.jpg") },
      { img: require("../assets/img/news/news-8/11.jpg") },
      { img: require("../assets/img/news/news-8/12.jpg") },
      { img: require("../assets/img/news/news-8/13.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
  {
    id: 6,
    data: {
      "en-US": {
        title: "Animals from Marshmallow",
        short_desc:
          'Every Saturday we meet for master classes from our team of volunteers. Many thanks to the restaurant "Watercolor" for the hospitable reception. Together to victory🎉',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Звірята з Маршмеллоу`,
        short_desc:
          'Кожну суботу ми зустрічаємось на майстер класах від нашої команди волонтерів. Велика вдячніть ресторану "Акварель" за гостинний прийом. Разом до перемоги🎉',
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Tiere aus Marshmallow",
        short_desc:
          'Jeden Samstag treffen wir uns zu Meisterkursen unseres Teams von Freiwilligen. Vielen Dank an das Restaurant "Watercolor" für den gastfreundlichen Empfang. Gemeinsam zum Sieg🎉',
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Animales de Marshmallow",
        short_desc:
          'Todos los sábados nos reunimos para clases magistrales de nuestro equipo de voluntarios. Muchas gracias al restaurante "Acuarela" por la hospitalaria recepción. Juntos a la victoria 🎉',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Animaux de Guimauve",
        short_desc:
          'Tous les samedis, nous nous réunissons pour des classes de maître de notre équipe de bénévoles. Un grand merci au restaurant "Aquarelle" pour l\'accueil chaleureux. Ensemble vers la victoire🎉',
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Animali da Marshmallow",
        short_desc:
          'Ogni sabato ci incontriamo per i corsi di perfezionamento del nostro team di volontari. Mille grazie al ristorante "Watercolor" per l\'ospitale accoglienza. Insieme alla vittoria🎉',
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Zwierzęta z Marshmallow",
        short_desc:
          'W każdą sobotę spotykamy się na lekcjach mistrzowskich z naszym zespołem wolontariuszy. Serdeczne podziękowania dla restauracji "Akwarela" za gościnne przyjęcie. Razem do zwycięstwa🎉',
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Animais de marshmallow",
        short_desc:
          'Todos os sábados nos reunimos para master classes da nossa equipe de voluntários. Muito obrigado ao restaurante "Aquarela" pela hospitaleira recepção. Juntos para a vitória🎉',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-7/6.jpg"),
    time: "22.05.2022",
    img_report: [
      { img: require("../assets/img/news/news-7/2.jpg") },
      { img: require("../assets/img/news/news-7/3.jpg") },
      { img: require("../assets/img/news/news-7/4.jpg") },
      { img: require("../assets/img/news/news-7/5.jpg") },
      { img: require("../assets/img/news/news-7/6.jpg") },
      { img: require("../assets/img/news/news-7/1.jpg") },
      { img: require("../assets/img/news/news-7/7.jpg") },
      { img: require("../assets/img/news/news-7/8.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
  {
    id: 5,
    data: {
      "en-US": {
        title: "Courage is to be a Ukrainian mother",
        short_desc:
          "Only our mothers are able to continue to delight children in this difficult time. They make every effort to ensure that these gray days do not spoil the bright imagination of children about this world. That everyone remembered the childhood in bright colors🇺🇦. Every Saturday, the Who If Not Us Foundation organizes small but such family events for mothers and children, where we have the opportunity to communicate, and children create masterpieces with their own hands in master classes😇. Our children are strong because they are the daughters and sons of an indomitable people.",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: `Сміливість - бути українською мамою`,
        short_desc:
          "Тільки наші матусі здатні продовжити радувати дітей у цей складний час Вони роблять максимальні зусилля, щоб ці сірі дні, не зіпсували яскраву уяву дітей про цей світ. Щоб кожен згадував дитинство в яскравих кольорах🇺🇦. Фонд «Хто,якщо не ми» кожну суботу влаштовує для матусь та дітей невеликі але такі сімейні заходи де у нас є можливість поспілкуватись , а діти власними руками на майстер-класах створюють шедеври😇. Наші діти-сильні,бо вони доньки та сини незламного народу💪🏻",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Mut ist, eine ukrainische Mutter zu sein",
        short_desc:
          "Nur unsere Mütter können die Kinder in dieser schwierigen Zeit weiterhin erfreuen und setzen alles daran, dass diese grauen Tage die heitere Fantasie der Kinder von dieser Welt nicht verderben. Dass sich alle in bunten Farben an die Kindheit erinnerten🇺🇦. Jeden Samstag organisiert die Who If Not Us Foundation kleine, aber feine Familienevents für Mütter und Kinder, bei denen wir die Möglichkeit haben, uns auszutauschen, und Kinder in Meisterkursen Meisterwerke mit ihren eigenen Händen erschaffen😇. Unsere Kinder sind stark, weil sie die Töchter und Söhne eines unbezwingbaren Volkes sind.",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Valor es ser madre ucraniana",
        short_desc:
          "Solo nuestras madres son capaces de seguir deleitando a los niños en este momento difícil y se esfuerzan al máximo para que estos días grises no echen a perder la brillante imaginación de los niños sobre este mundo. Que todos recordaran la infancia en colores brillantes 🇺🇦. Todos los sábados, la Fundación Who If Not Us organiza pequeños pero tales eventos familiares para madres e hijos, donde tenemos la oportunidad de comunicarnos, y los niños crean obras maestras con sus propias manos en clases magistrales😇. Nuestros hijos son fuertes porque son hijas e hijos de un pueblo indomable.",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Le courage est d'être une mère ukrainienne",
        short_desc:
          "Seules nos mères sont capables de continuer à ravir les enfants en cette période difficile.Elles mettent tout en œuvre pour que ces journées grises ne viennent pas gâcher l'imaginaire lumineux des enfants sur ce monde. Que tout le monde se souvienne de l'enfance aux couleurs vives🇺🇦. Tous les samedis, la Fondation Who If Not Us organise de petits événements familiaux pour les mères et les enfants, où nous avons la possibilité de communiquer, et les enfants créent des chefs-d'œuvre de leurs propres mains lors de master classes😇. Nos enfants sont forts parce qu'ils sont les filles et les fils d'un peuple indomptable.",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Il coraggio è essere una madre ucraina",
        short_desc:
          "Solo le nostre madri possono continuare a deliziare i bambini in questo momento difficile e fanno ogni sforzo per garantire che questi giorni grigi non rovinino la brillante immaginazione dei bambini su questo mondo. Che tutti ricordassero l'infanzia a colori vivaci🇺🇦. Ogni sabato, la Fondazione Who If Not Us organizza piccoli ma tali eventi familiari per mamme e bambini, dove abbiamo l'opportunità di comunicare, e i bambini creano capolavori con le proprie mani nelle masterclass😇. I nostri figli sono forti perché sono figlie e figli di un popolo indomito.",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Odwaga to bycie matką Ukrainką",
        short_desc:
          "Tylko nasze mamy potrafią nadal zachwycać dzieci w tym trudnym czasie, dokładają wszelkich starań, aby te szare dni nie popsuły dziecięcej wyobraźni o tym świecie. Że wszyscy pamiętali dzieciństwo w jasnych kolorach🇺🇦. W każdą sobotę Fundacja Kto Nie My organizuje małe, ale takie rodzinne imprezy dla mam i dzieci, podczas których mamy możliwość komunikowania się, a dzieci własnoręcznie tworzą arcydzieła na lekcjach mistrzowskich😇. Nasze dzieci są silne, ponieważ są córkami i synami niezłomnego ludu.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Coragem é ser mãe ucraniana",
        short_desc:
          "Somente nossas mães são capazes de continuar a encantar as crianças neste momento difícil e fazem todos os esforços para garantir que esses dias cinzentos não estraguem a imaginação brilhante das crianças sobre este mundo. Que todos se lembrassem da infância em cores vivas🇺🇦. Todos os sábados, a Fundação Who If Not Us organiza pequenos eventos familiares para mães e filhos, onde temos a oportunidade de nos comunicar, e as crianças criam obras-primas com as próprias mãos em master classes😇. Nossos filhos são fortes porque são filhas e filhos de um povo indomável.",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-6/preview.jpg"),
    time: "16.05.2022",
    img_report: [
      { img: require("../assets/img/news/news-6/2.jpg") },
      { img: require("../assets/img/news/news-6/3.jpg") },
      { img: require("../assets/img/news/news-6/10.jpg") },
      { img: require("../assets/img/news/news-6/5.jpg") },
      { img: require("../assets/img/news/news-6/6.jpg") },
      { img: require("../assets/img/news/news-6/7.jpg") },
      { img: require("../assets/img/news/news-6/8.jpg") },
      { img: require("../assets/img/news/news-6/9.jpg") },
      { img: require("../assets/img/news/news-6/13.jpg") },
      { img: require("../assets/img/news/news-6/11.jpg") },
      { img: require("../assets/img/news/news-6/12.jpg") },
      { img: require("../assets/img/news/news-6/4.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
  {
    id: 4,
    data: {
      "en-US": {
        title: "Issuance of humanitarian aid in the Dnieper for temporarily displaced families",
        short_desc: "Thank you for your cooperation! Together we are a force!",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "Видача гуманітарної допомоги у Дніпрі для тимчасово переміщенних сімей",
        short_desc: "Дякуємо за співпрацю! Разом ми-сила!",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Bereitstellung humanitärer Hilfe im Dnjepr für vorübergehend vertriebene Familien",
        short_desc: "Danke für Ihre Kooperation! Zusammen sind wir eine Kraft!",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Emisión de ayuda humanitaria en el Dnieper para familias desplazadas temporalmente",
        short_desc: "¡Gracias por su cooperación! ¡Juntos somos una fuerza!",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Distribution d'aide humanitaire dans le Dniepr pour les familles temporairement déplacées",
        short_desc: "Merci de votre collaboration! Ensemble nous sommes une force !",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Emissione di aiuti umanitari nel Dnepr per famiglie temporaneamente sfollate",
        short_desc: "Grazie per la collaborazione! Insieme siamo una forza!",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Wydawanie pomocy humanitarnej w Dnieprze dla tymczasowo przesiedlonych rodzin",
        short_desc: "Dziękuję za współpracę! Razem jesteśmy siłą!",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Emissão de ajuda humanitária no Dnieper para famílias temporariamente deslocadas",
        short_desc: "Obrigado por sua cooperação! Juntos somos uma força!",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-5/preview.jpg"),
    time: "04.05.2022",
    img_report: [
      { img: require("../assets/img/news/news-5/1.jpg") },
      { img: require("../assets/img/news/news-5/2.jpg") },
      { img: require("../assets/img/news/news-5/3.jpg") },
      { img: require("../assets/img/news/news-5/4.jpg") },
      { img: require("../assets/img/news/news-5/5.jpg") },
      { img: require("../assets/img/news/news-5/6.jpg") },
      { img: require("../assets/img/news/news-5/7.jpg") },
    ],
    letters_thanks: {
      state: true,
      img_map: [
        {
          img: require("../assets/img/news/news-5/8.jpg"),
        },
        {
          img: require("../assets/img/news/news-5/9.jpg"),
        },
      ],
    },
    video: {
      state: false,
    },
  },
  {
    id: 3,
    data: {
      "en-US": {
        title: 'Assistance to the Municipal Institution "Vasylkiv Psychoneurological Boarding School "DOR""',
        short_desc:
          'On November 30, the Vasylkiv Psychoneurological Boarding School "DOR" received from the Charitable Foundation "Who but us?" help.',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "Допомога Комунальному закладу «Васильківський психоневрологічний інтернат „ДОР“»",
        short_desc:
          '30 листопада Комунальний заклад "Васильківський психоневрологічний інтернат "ДОР" отримав від Благодійного Фонду «Хто, як не ми?» допомогу.',
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: 'Unterstützung der städtischen Einrichtung "Vasylkiv Psychoneurological Internat "DOR""',
        short_desc:
          'Am 30. November erhielt das psychoneurologische Internat "DOR" von Vasylkiv von der Wohltätigkeitsstiftung "Wer außer uns?" Hilfe.',
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: 'Asistencia a la Institución Municipal "Escuela de Internado Psiconeurológico Vasylkiv "DOR""',
        short_desc:
          'El 30 de noviembre, el internado Psiconeurológico Vasylkiv "DOR" recibió de la Fundación Caritativa "¿Quién sino nosotros?" ayudar.',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: 'Assistance à l\'institution municipale "Internat psychoneurologique Vasylkiv "DOR""',
        short_desc:
          'Le 30 novembre, l\'internat psychoneurologique Vasylkiv "DOR" a reçu de la Fondation caritative "Qui d\'autre que nous?" aider.',
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: 'Assistenza all\'Istituzione Municipale "Convitto Psiconeurologico Vasylkiv "DOR""',
        short_desc:
          'Il 30 novembre, il Collegio Psiconeurologico Vasylkiv "DOR" ha ricevuto dalla Fondazione di beneficenza "Chi se non noi?" aiuto.',
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Pomoc dla Zakładu Miejskiego „Wasylkowa Psychoneurologiczna Szkoła z Internatem ”DOR”",
        short_desc:
          "30 listopada Wasylkowa Psychoneurologiczna Szkoła z internatem „DOR” otrzymała od Fundacji Dobroczynnej „Kto oprócz nas?” Wsparcie.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: 'Assistência à Instituição Municipal "Internato Psiconeurológico Vasylkiv "DOR""',
        short_desc:
          'Em 30 de novembro, o Internato Psiconeurológico Vasylkiv "DOR" recebeu da Fundação de Caridade "Quem senão nós?" ajuda.',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-4/preview.jpg"),
    time: "22.12.2021",
    img_report: [
      { img: require("../assets/img/news/news-4/1.jpg") },
      { img: require("../assets/img/news/news-4/2.jpg") },
      { img: require("../assets/img/news/news-4/3.jpg") },
      { img: require("../assets/img/news/news-4/4.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
  {
    id: 2,
    data: {
      "en-US": {
        title: "On October 16, they visited the village of Zaliniyne, Zachepyliv district, Kharkiv region",
        short_desc:
          "The villagers turned to their compatriots, now the founders of the foundation, for help in purchasing the necessary equipment to improve the work of the staff: the Library, the club, the medical staff.",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "16 жовтня відвідали село Залінійне, у Зачепилівського району, Харківської області",
        short_desc:
          "Жителі села звернулися за допомогою до своїх земляків, наразі засновників фонду у придбанні необхідної техніки для поліпшення роботи працівників: Бібліотеки, клубу, медичного персоналу.",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title:
          'Gemeinnützige Stiftung "Wer außer uns?" Am 16. Oktober besuchten sie das Dorf Zaliniyne im Bezirk Zachepyliv in der Region Charkiw',
        short_desc:
          "Die Dorfbewohner wandten sich an ihre Landsleute, jetzt die Gründer der Stiftung, um Hilfe beim Kauf der notwendigen Ausrüstung zu erhalten, um die Arbeit der Angestellten zu verbessern: Bibliothek, Club, medizinisches Personal.",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title:
          'Fundación Benéfica "¿Quién sino nosotros?" El 16 de octubre visitaron el pueblo de Zaliniyne, distrito de Zachepyliv, región de Kharkiv.',
        short_desc:
          "Los aldeanos recurrieron a sus compatriotas, ahora los fundadores de la fundación, en busca de ayuda en la compra de los equipos necesarios para mejorar el trabajo de los empleados: la Biblioteca, el club, el personal médico.",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title:
          'Fondation caritative "Qui d\'autre que nous?" Le 16 octobre, ils ont visité le village de Zaliniyne, district de Zachepyliv, région de Kharkiv',
        short_desc:
          "Les villageois se sont tournés vers leurs compatriotes, aujourd'hui fondateurs de la fondation, pour les aider à acheter les équipements nécessaires à l'amélioration du travail des salariés : la Bibliothèque, le club, le personnel médical.",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title:
          'Fondazione di beneficenza "Chi se non noi?" Il 16 ottobre hanno visitato il villaggio di Zaliniyne, distretto di Zachepyliv, regione di Kharkiv',
        short_desc:
          "Gli abitanti del villaggio si sono rivolti ai loro connazionali, ora fondatori della fondazione, per un aiuto nell'acquisto delle attrezzature necessarie per migliorare il lavoro dei dipendenti: la Biblioteca, il circolo, il personale medico.",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title:
          "Fundacja Charytatywna „Kto jak nie my?” 16 października odwiedzili wieś Zaliniyne, obwód Zachepylow, obwód charkowski",
        short_desc:
          "Mieszkańcy wsi zwrócili się do rodaków, obecnie założycieli fundacji, o pomoc w zakupie niezbędnego sprzętu usprawniającego pracę pracowników: biblioteki, klubu, personelu medycznego.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title:
          'Fundação de Caridade "Quem senão nós?" Em 16 de outubro, eles visitaram a aldeia de Zaliniyne, distrito de Zachepyliv, região de Kharkiv',
        short_desc:
          "Os aldeões recorreram aos seus compatriotas, agora fundadores da fundação, para obter ajuda na compra dos equipamentos necessários para melhorar o trabalho dos funcionários: a Biblioteca, o clube, o pessoal médico.",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-3/preview.jpg"),
    time: "14.11.2021",
    img_report: [
      { img: require("../assets/img/history/ph-1.jpg") },
      { img: require("../assets/img/history/ph-4.jpg") },
      { img: require("../assets/img/history/ph-7.jpg") },
      { img: require("../assets/img/history/ph-8.jpg") },
      { img: require("../assets/img/history/ph-2.jpg") },
      { img: require("../assets/img/history/ph-3.jpg") },
      { img: require("../assets/img/history/ph-5.jpg") },
      { img: require("../assets/img/history/ph-6.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
  {
    id: 1,
    data: {
      "en-US": {
        title: "Two weeks ago we received such a letter in the mail…",
        short_desc: '"Help buy a specialized stroller for a child with special needs" wrote Mom ...',
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "Два тижні тому до нас на пошту прийшов такий лист…",
        short_desc: "« …допоможіть придбати спеціалізований візок для дитини з особливими потребами» писала Мама... ",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title: "Vor zwei Wochen bekamen wir so einen Brief per Post…",
        short_desc:
          '"Helfen Sie, einen speziellen Kinderwagen für ein Kind mit besonderen Bedürfnissen zu kaufen", schrieb Mama ...',
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Hace dos semanas recibimos una carta así por correo...",
        short_desc: '"Ayuda a comprar un cochecito especializado para un niño con necesidades especiales", escribió mamá...',
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title: "Il y a deux semaines, nous avons reçu une telle lettre par la poste…",
        short_desc: '"Aidez à acheter une poussette spécialisée pour un enfant ayant des besoins spéciaux" a écrit maman ...',
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "Due settimane fa abbiamo ricevuto una lettera del genere per posta...",
        short_desc:
          '"Aiutate a comprare un passeggino specializzato per un bambino con bisogni speciali" ha scritto la mamma...',
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Dwa tygodnie temu otrzymaliśmy taki list pocztą…",
        short_desc: "„Pomóż kupić specjalistyczny wózek dla dziecka ze specjalnymi potrzebami” napisała mama…",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Há duas semanas, recebemos uma carta dessas pelo correio…",
        short_desc:
          '"Ajude a comprar um carrinho especializado para uma criança com necessidades especiais", escreveu a mãe...',
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-2/preview.jpg"),
    time: "14.11.2021",
    img_report: [
      { img: require("../assets/img/news/news-2/1.jpg") },
      { img: require("../assets/img/news/news-2/2.jpg") },
      { img: require("../assets/img/news/news-2/3.jpg") },
      { img: require("../assets/img/news/news-2/4.jpg") },
      { img: require("../assets/img/news/news-2/5.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
  {
    id: 0,
    data: {
      "en-US": {
        title: "Our volunteers lovingly choose the best fruits, because today we go to the orphanage with gifts",
        short_desc: "Visited the Orphanage Periwinkle in the Dnieper on the street. Amber.",
        tags: "News, Charity",
      },
      "ua-UA": {
        title: "Наші волонтери з любов’ю вибирають кращі фрукти, бо ми сьогодні ідемо з подарунками у дитячий будинок",
        short_desc: "Відвідали Дитячий будинок Барвінок у Дніпрі на вул. Янтарній.",
        tags: "Новини, Благодійність",
      },
      "de-DE": {
        title:
          "Unsere Freiwilligen wählen liebevoll die besten Früchte aus, denn heute gehen wir mit Geschenken ins Waisenhaus",
        short_desc: "Besuchte das Waisenhaus Periwinkle im Dnjepr auf der Straße. Bernstein.",
        tags: "Nachrichten, Wohltätigkeit",
      },
      "es-ES": {
        title: "Nuestros voluntarios eligen con amor las mejores frutas, porque hoy vamos al orfanato con regalos",
        short_desc: "Visitó el Orfanato Periwinkle en el Dnieper en la calle. Ámbar.",
        tags: "Noticias, Caridad",
      },
      "fr-FR": {
        title:
          "Nos bénévoles choisissent avec amour les meilleurs fruits, car aujourd'hui nous allons à l'orphelinat avec des cadeaux",
        short_desc: "Visité l'orphelinat Pervenche dans le Dniepr dans la rue. Ambre.",
        tags: "Actualités, Charité",
      },
      "it-IT": {
        title: "I nostri volontari scelgono amorevolmente i frutti migliori, perché oggi andiamo all'orfanotrofio con i regali",
        short_desc: "Ho visitato l'orfanotrofio Pervinca nel Dnepr per strada. Ambra.",
        tags: "Notizie, carità",
      },
      "pl-PL": {
        title: "Nasi wolontariusze z miłością wybierają najlepsze owoce, bo dzisiaj idziemy do Domu Dziecka z prezentami",
        short_desc: "Odwiedziłem sierociniec Barwinek w Dnieprze na ulicy. Bursztyn.",
        tags: "Aktualności, Dobroczynność",
      },
      "pt-PT": {
        title: "Nossos voluntários escolhem com carinho as melhores frutas, pois hoje vamos ao orfanato com presentes",
        short_desc: "Visitei o Orfanato Periwinkle no Dnieper na rua. Âmbar.",
        tags: "Notícias, caridade",
      },
    },
    img_preview: require("../assets/img/news/news-1/preview.jpg"),
    time: "12.11.2021",
    img_report: [
      { img: require("../assets/img/news/news-1/1.jpg") },
      { img: require("../assets/img/news/news-1/2.jpg") },
      { img: require("../assets/img/news/news-1/3.jpg") },
      { img: require("../assets/img/news/news-1/4.jpg") },
    ],
    letters_thanks: {
      state: false,
    },
    video: {
      state: false,
    },
  },
];
