import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingMedical } from "@fortawesome/free-solid-svg-icons";

const OurHelp = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "home.our_help.";

  const settings = {
    dots: false,
    arrows: true,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderItem = [
    { img: require("../../assets/img/help/1.jpg").default },
    { img: require("../../assets/img/help/2.jpg").default },
    { img: require("../../assets/img/help/3.jpg").default },
    { img: require("../../assets/img/help/4.jpg").default },
    { img: require("../../assets/img/help/5.jpg").default },
    { img: require("../../assets/img/help/6.jpg").default },
  ];

  return (
    <section className="our-help">
      <Container>
        {/* <Row>
          <Col lg={6} className="left-side">
            <h2>{t(basePath + "title")}</h2>
            <p>{t(basePath + "desc")}</p>
            <div className="d-inline-block">
              <Button href="#help" className="btn-st">
                <FontAwesomeIcon icon={faHandHoldingMedical} />
                {t(basePath + "btn")}
              </Button>
            </div>
          </Col>
          <Col lg={6}>
            <Slider {...settings}>
              {sliderItem.map((item, i) => (
                <div className="slid" key={i}>
                  <div className="item">
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${item.img})`,
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </Slider>
          </Col>
        </Row> */}
      </Container>
    </section>
  );
};

export default OurHelp;
