export const TRANSLATIONS_EN = {
  navBar: {
    site: "Our website",
    btnHelp: "HOW YOU CAN HELP",
    btnRequ: "ASK FOR HELP",

    link: {
      home: "Home",
      center: "Report",
      about: "About our fund",
      news: "News",
      nft: "NFT Gallery",
      contact: "Contacts",
    },

    lang: {
      title: "Language",
      "en-US": "English",
      "ua-UA": "Ukrainian",
      "es-ES": "Spanish",
      "fr-FR": "French",
      "de-DE": "German",
      "it-IT": "Italian",
      "pl-PL": "Polish",
      "pt-PT": "Portuguese",
    },
  },

  pageBanner: {
    home: "Home",
    about: "About our fund",
    news: "News",
    nft: "NFT Gallery",
    contact: "Contacts",
    report: 'Report',
  },

  copy: {
    copy: "Copy",
    success: "Copied",
  },

  home: {
    banner: {
      title: {
        first: "CHARITABLE FOUNDATION",
        second: '"WHO IF NOT US"',
      },
      desc: 'Our fund "WHO IF NOT US" is fully responsible for the use of funds raised, each contribution will have a direct purpose. The main thing for us is your trust!',
      btnHelp: "How can you help",
      btnRequ: "Ask for help",
      btnDonat: "Donate",
    },

    company: {
      title: '"WHO IF NOT US" Charitable Foundation',
      desc: "Children are our future. Together, we can overcome everything and make our future healthy, happy and with faith in our country. The Who, If Not Us Charitable Foundation organizes fundraising to help children and their families in need. Assistance to seriously ill children, namely payment for treatment and rehabilitation period.",
      descMil: "Continuous procurement of necessary ammunition for the Armed Forces of Ukraine.",
      mission: {
        title: "Foundation mission",
        desc: "Give as much as possible the joy of life to children and provide psychological support to parents who need it now.",
      },
      purpose: {
        title: "The purpose of the fund",
        desc: "Organization of children's activities that affect children's development. Psychological support for children and their parents. Financial and humanitarian support to the victims of the armed conflict in Ukraine.",
      },
      military: {
        title: "Military support",
        desc: "Supply of defenders with ammunition and cars in the hottest spots of Ukraine.",
      },
      widget: {
        title: {
          first: "Together - we will change the world!",
          second: "Start with us now!",
        },
      },
      work: {
        disability: "We help people with disabilities",
        retirees: "We help retirees",
        orphans: "We help orphans",
        difficult: "We help children affected during the armed conflict in Ukraine",
        military: "Provision of the Armed Forces",
      },
    },

    fees: {
      title: "When there is no hope, no strength, and no money, we can be there for you!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "collected to help children",
        },
        {
          title: "93 500 UAH.",
          desc: 'spent on the "Cure with Joy" program',
        },
        {
          title: "196 800 UAH.",
          desc: 'spent on the program "Restorative Therapy"',
        },
        {
          title: "342 000 UAH.",
          desc: 'spent on the program "Saving our Life Together"',
        },
      ],
    },

    our_help: {
      title: "Our help",
      desc: "The Soul fish restaurant opened a relief headquarters during the war. They help with food for the Armed Forces, the police and refugees-displaced persons “For 41 days now, each of us has been helping as much as we can. This is all strong and invincible energy, it united us into one whole. And thanks to that we are moving.",
      btn: "Help",
    },

    donat: {
      title: "Assistance to children injured during the war on the territory of Ukraine",
      btn: "Donate",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Wallet for donations BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Wallet for donations USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Current account",
        },
      },
    },

    help: {
      title: "How do we help?",
      desc: "Perhaps you have a way to help this or that family. Please write us and we will contact you.",
      form: {
        name: "Your name",
        phone: "+380 99 999-99-99",
        select: {
          one: "Choose how you can help",
          two: "Money",
          three: "Volunteer",
          four: "Specialist",
          five: "My Help",
        },
        mass: "Your message",
        error: "Message not sent! Please fill in all the fields.",
        success: "Message sent successfully!",
        btn: "Send message",
      },
      specialityContent: [
        {
          title: "As a foundation volunteer",
          desc: "Participate in foundation projects permanently or as a one-time volunteer. You must be over 18 years of age.",
        },
        {
          title: "As a specialist",
          desc: "As a doctor to help the sick, as a psychologist or other specialist.",
        },
        {
          title: "As an internet blogger",
          desc: "Ready to help with the spread of information on the Internet.",
        },
        {
          title: "As a partner store",
          desc: " Conducting promotions related to things and basic necessities.",
        },
        {
          title: "Volunteer help with money",
          desc: "We accept voluntary transfers to the foundation's card account.",
        },
      ],
    },

    request: {
      title: "Ask for help",
      desc: "Write your contacts, describe the situation in detail:",
      info: {
        title: "You can get detailed information by contacting our specialist:",
        name: "Victoria Oleksandrivna Chernyshova",
      },
    },

    transfer: {
      title: "Money transfer",
      error: "Error",
      success: "Successfully",
      thank: "Thank you for the support of the children of Ukraine",
    },
  },

  about: {
    info: {
      title: '"WHO IF NOT US" Charitable Foundation:',
      subtitle: "How it all started…",
      desc: {
        one: "The charity fund was registered in May 2021, but the actual work of supporting Ukrainians has been underway since 2020.",
        two: 'The main goal of the fund "WHO IF NOT US" - help for children. Our tasks also include support for people with disabilities and retirees. Our foundation believes that generosity and generosity are the most important human qualities.',
        three:
          "We are convinced that by doing good, we become stronger and happier, improve relationships, create a better world. Everyone should be able to share time, money or energy. This is a reliable way to create harmony between people.",
      },

      war: {
        title: "The war changed each of us",
        desc: {
          first: 'The "WHO IF NOT US" Foundation continues to work 24/7 to help anyone in need.',
          second: {
            main: "Clothes, food, personal hygiene products were all sent to us from Poland. We encourage everyone to join our fight against our common enemy and join the team! Thank you to everyone who has already made a significant contribution! Together we are able to create incredible things! Together we are marching to victory! Glory to Ukraine!",
            sub: "Victory will be ours!",
          },
        },
      },
    },

    what: {
      title: "What we do",
      item: {
        one: "We help people with disabilities",
        two: "We help orphans",
        three: "We help retirees",
        four: "We help children affected during the armed conflict in Ukraine",
      },
    },

    history: {
      title: "Our story",
      desc: {
        fond: 'The "WHO IF NOT US" Charitable Foundation was established with the sole purpose of doing good to people. We try to work in different directions:',
        believ:
          "Our foundation believes that kindness and generosity are the main human qualities that separate us from animals.",
        generate:
          'The "WHO IF NOT US" Charitable Foundation was created to bring together people who are willing to help. We try to make sure that everyone, regardless of financial status and age, has the opportunity to help children. Our organization wants every unhappy child to have a happy and peaceful childhood.',
      },

      list: {
        money: "Financial and psychological support for people in critical situations;",
        therapy: "Treatment of seriously ill children;",
        moral:
          "Support for children affected by the armed conflict in Ukraine;",
      },
    },
  },

  news: {
    btn: "Read more",
    report: "Photo report",
    thanks: "Letters of thanks",
    page: {
      latest: "Latest news",
      help: {
        title: "Help",
        desc: "Be a volunteer, or help as a specialist. Press the button!",
        btn: "Donate",
      },
    },
  },

  contact: {
    title: "Contact Information",
    address: {
      title: "Address",
      desc: "Dnipro, street Korolenka, 8, office 10",
    },
    mail: {
      title: "Mail",
    },
    phone: {
      title: "Phone",
    },
    work: {
      title: "Working hours",
      desc: "Monday to Friday: 10:00 to 17:00",
    },
  },

  nft: {
    title: "Gallery",
    btn: "Buy",
    price: "Price",

    block: {
      title: {
        main: "The team created the NFT of our fund, the money from the sale goes to help the Armed Forces of Ukraine",
        sub: "and homeless people.",
      },
    },

    info: {
      title: {
        main: "February 2022 to Ukraine",
        sub: "the war came.",
      },

      desc: {
        war: "The war started by Russia. All the citizens of our country have united in one whole - in a steel shield in the struggle and confrontation of a country that has already broken the millions of ordinary Ukrainians.",
        team: 'The team "WHO IF NOT US", like thousands of other charities, from the first day of the war takes every possible part in the fate and assistance to our country and our citizens.',
        friend: "Dear friends and philanthropists, we look forward to any help and support your organization can provide.",
      },
      together: "After all, together we are Ukraine",
    },

    nftContent: [
      {
        img: "4",
        cat: "Hero cities of Ukraine",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Hero cities of Ukraine",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Hero cities of Ukraine",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Hero cities of Ukraine",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Hero cities of Ukraine",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Hero cities of Ukraine",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Hero cities of Ukraine",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Hero cities of Ukraine",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Hero cities of Ukraine",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Hero cities of Ukraine",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  report: {
    donat: {
      title: "We will be grateful for your help",

      switch: {
        one: "One time",
        monthly: "Monthly",
      },

      other: "Another amount",
      btn: "Donate",
      btnCrypto: "Donate in Cryptocurrency",

      modal: {
        title: "Cryptocurrency",
        btc: "BTC donation wallet",
        usdt: "USDT Donation Wallet TRC-20",
      },
    },

    statistic: {
      title: "Our Report",

      items: {
        one: "We constantly give help and support children who need it.",
        two: "We cooperate with star partners who support our activities.",
        three: "We organize social events to support every child who suffered from the war.",
      },

      data: {
        item1: {
          title: "Flash mob for children in Dnipro",
          desc: "⏳There will never be a convenient moment in life <br/> My team and I wanted something that could relieve parents and interest children💃 <br/> The solution was printed in 5 minutes - FLASHMOB🤩 <br/><br/>Here we laughed and cried Getting ready instead of going out with friends<br/> Many things didn't work out for us, but the most important result was to give children the opportunity to breathe in the life they had before the war Giving laughter and joy, giving mothers carefreeness and the opportunity to dance on an equal footing with children <br/><br/> We don't strive for the ideal, we strive to live here and now And give happiness and goodness today🎉 <br/><br/> Thank you to our incredible mothers who were with us at this time<br/><br/> And a big thank you to the whole team❤️<br/> Follow us on Instagram @hto_yaksho_ne_my"
        },
        item2: {
          title: "Cars for the Armed Forces of Ukraine",
          desc: "WE BOUGHT A CAR FOR THE AFU 🔥<br/><br/>We bought an ambulance, started repairing it, painting it, and equipping it...<br/>And now - we are ready to present you the result 🔥<br/><br/>This car will go straight to the assault unit in Bakhmut❗️We all understand that cars, like any other equipment on the front, are consumables🤷🏻‍♀️<br/><br/>But it is difficult and even almost impossible to fight without it ❌<br/><br/>But we still hope that this car will serve our military as long and as well as possible"
        },
        item3: {
          title: "Cars for the military",
          desc: "🔥15 CARS TO BAKHMUT<br/><br/>Together with the Pastoral restaurant and Dnipro benefactors, we bought 15 cars and a large batch of ammunition for our military personnel who are in the Bakhmut direction<br/><br/>We are grateful to everyone 🙏🏻 who joined this cool cause 🫶🏻<br/><br/>We are grateful to our Armed Forces and call on you to donate, help and believe in our victory"
        },
        item4: {
          title: "Year of war",
          desc: "What did it mean for our foundation and children's center? <br/><br/> ••• We started our work without knowing anyone, without experience, all we had was a strong desire to be useful <br/><br/> 📎We met our volunteers completely by accident, they just came to us for help and showed initiative! They wanted to work with us and help the same people who suffered from the war. <br/>📎For a long time, we brought aid to the military hospital/Mechnikov regional hospital. <br/>📎Over the entire time, we have provided assistance to over 1,500 families with children <br/>📎We supported our military: we purchased uniforms, tactical gloves, a thermal imager, and 40 pairs of shoes for the Donetsk direction, which we bought from @vadrus.ua <br/>📎This year, we regularly received assistance from @opir.sambir2022 @polovkoandrey @revivalfund.ua @kolo_fund <br/>📎The restaurants \"Akvarel\" and \"Soul Fish\" have been preparing food for our defenders and for people who had to leave their homes and start life anew in the Dnipro since the first days of the war. And we, in turn, helped them with products in this <br/>📎The OK Psychology Center @ok.psyspace has been providing psychological support for children and parents since the beginning of the war, and also provided us with their hall for conducting master classes <br/>📎We organized events with bloggers and our partners <br/>📎We held a dance flash mob together with parents and children <br/>📎We opened the \"Ballet Training\" direction <br/>📎And ensured the full-fledged operation of the children's center and the provision of humanitarian aid to children and pregnant women on an ongoing basis This year was the most difficult for all of us... <br/><br/>But there is only one recipe for victory: to be united, strong, do everything you can for the sake of victory and be dear to those who need it!"
        },
        item5: {
          title: "We report!",
          desc: "We held a holiday on 17.06 \"Land of the Invincible\" with the support of the Dnipropetrovsk Regional Military Administration 🫂<br/><br/>A holiday for children of IDPs from different parts of the country 🫶🏻at the Lavina location @lavinapark<br/><br/>With the support of our star friends @annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila.eremeeva @viktoriya_tka4 and @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official <br/><br/>Our military, who defend our country and provide the opportunity to create holidays, received 28 cars and more than 20 pieces of equipment 🚐"
        },
        item6: {
          title: "Cars for the Armed Forces of Ukraine",
          desc: "Thanks to our donors, on June 17, we, the charity foundation \"Who, if not us\" were able to collect UAH 1,074,075🤩<br/><br/>And with our efforts, we were able to purchase 35 DJI Mavic 3Ts and transfer them on July 5 to the Dnipropetrovsk Regional State Administration, our defenders, who enable us to hold such events and bring victory closer<br/><br/>We are grateful to everyone who attended the \"Land of the Indestructible\" event<br/><br/>Thank you @a.wellboy @annatrincher_official @demchukmusic @dianagloster @uliana_royce @mashaa.kondratenko @viktoriya_tka4 @yuriy_tkach"
        },
        item7: {
          title: "Humanitarian aid Odesa",
          desc: "Our foundation \"Who if not us\" visited the city of Odesa with humanitarian aid🥰<br/><br/>Over 110 sets were distributed to IDP families with children under 3 years old🫶🏻<br/><br/>Thank you to our partners @revivalfund.ua and @opir.sambir2022 🫂<br/><br/>We help and bring victory closer together"
        },
        item8: {
          title: "Help Kherson",
          desc: "We - the charitable foundation \"Who if not us\" visited the city of Kherson with humanitarian aid for the victims of the Kakhovka hydroelectric power plant explosion 🙏<br/><br/>We delivered: <br/>• Outerwear - 500 units <br/>• Men's clothing - 300 units <br/>• Women's clothing - 500 units <br/>• Children's toys - 7 boxes <br/>• Children's textbooks (Ukrainian/English) - 3 boxes <br/>• Strollers/cradles/highchair - 6 units <br/>• Baby food - 2 boxes <br/>• Hair conditioner - 2 boxes <br/>• Toothbrushes - 1 box <br/>• Women's/men's shoes - 50 pairs <br/>• Blankets - 30 pieces <br/>• Antiseptic - 20 liters <br/>• Underwear - 30 packages <br/>• Children's mattress - 10 pieces <br/>• Children's clothing - 50 boxes <br/>• Towel - 1 box <br/>• Slippers - 20 pairs <br/>• Drawing board - 1 piece <br/>• Pillows/blankets - 50 pieces <br/>• Food sets (canned food, butter, sugar, tea, cookies, pasta, toilet paper, soap, buckwheat) - 380 sets <br/>• Charging station - 10 pieces <br/>• Power banks - 25 pieces <br/>• Carbonated drinking water - 1500 tons <br/>• Still drinking water - 8500 tons <br/><br/>📍Nezlamnosti Point, Kherson, based on: Kherson General Educational Educational Complex 48 At the address: Kherson, st. 28 Armies, 14<br/><br/>We continue to help🙏 We believe in Victory"
        },
        item9: {
          title: "Billiards tournament",
          desc: "CHARITY BILLIARD TOURNAMENT dedicated to the INDEPENDENCE DAY OF UKRAINE<br/><br/>For the first time in many years, we managed to gather the best athletes of Ukraine in Dnipro‼️32 athletes from 11 regions and 14 cities of Ukraine are taking part in the competition. All charitable contributions of the participants are 100% transferred to help our military! The amount is 243,000 UAH 🔥<br/><br/>In the final of the tournament, the military was given important things necessary to protect our country!<br/><br/>Thank you to our defenders for the opportunity to hold this tournament💙💛"
        },
        item10: {
          title: "Kherson Pumping Station",
          desc: "We, the charitable foundation \"Who, if not us\" visited the village of Kalinivske, Kherson region with help!<br/><br/>The village was under occupation for 8 months🐖.<br/><br/>The residents who remained on their native land are simply surviving, because the village was completely destroyed 😥<br/><br/>We took a PUMPING STATION so that people would have water🙏<br/><br/>We continue to help! We are bringing victory closer, because it is very soon😉"
        },
        item11: {
          title: "City Day with Lasunka ice cream",
          desc: "On 09.09, on Dnipro City Day, we, together with \"Lasunka\" @lasunka_icecream, gave away ice cream to everyone who wanted it, and entertained the kids 🌸<br/><br/>Let's see together how it was"
        },
        item12: {
          title: "Holiday \"MOVING TOWARDS VICTORY\"",
          desc: "A sports event for 500 children, created with the support of the regional administration so that our children move only forward, do not forget about their goals and achievements, despite the war 🙏<br/><br/>For organizing the flash mob, we are infinitely grateful to @_oleksandrleshchenko <br/><br/>World-class athletes🏅 and also star friends such as @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official took part <br/><br/>Thank you to everyone who took part in this event <br/>Namely to the organizers without whom our event would not have taken place:<br/>@viktoriya_tka4 <br/>@deniskonovalov <br/>@m_galat <br/>@vladimir_drachuk <br/>@franz_audio <br/>You are the best 🫂"
        },
        item13: {
          title: "Machines for the Armed Forces of Ukraine",
          desc: "Our charitable foundation \"Who if not us\" constantly supports defenders in the hottest spots on the front!<br/><br/>Recently, we donated 13 cars and three ambulances together with the sports club @vataga_dp and the military administration!<br/><br/>These funds were collected at a charity MMA tournament!🥊<br/><br/>With the help of a unique lottery draw, we were able to collect over 3,000,000 hryvnias, which was conducted by our unrivaled hosts @mila.eremeeva @yuriy_tkach <br/><br/>The event took place in this difficult time with the support of the head of the Dnipropetrovsk regional administration! We are very grateful to everyone who joins us and helps our soldiers!!!<br/><br/>Together for Victory!"
        },
        item14: {
          title: "ALL-UKRAINIAN CHESS TOURNAMENT ♟️",
          desc: "On October 22, the chess tournament \"Strategy of the Unbreakable\" was held🙌🏻<br/><br/>Over 350 people participated, 125 of whom were children☺️<br/><br/>The participants received gifts from our foundation<br/><br/>🎁We support sports in Ukraine<br/><br/>We create the future, Glory to Ukraine 🫂"
        },
        item15: {
          title: "Tournament in memory of Master of Sports Yanchenko O.O.",
          desc: "Together with vataga, on November 23-26, a tournament in memory of the master of sports Yanchenko O.O. was held in the city of Kamyanske. <br/><br/>The tournament brought together 1️⃣3️⃣9️⃣ participants from 7️⃣ regions of Ukraine. Extremely bright and competitive matches, where each of the winners and prize-winners, together with their coaches, received individual awards and cash prizes. <br/><br/>🏆 The best boxers and boxers were awarded memorable awards for their skills<br/><br/>👏🏼 The award ceremony was held by honored guests, including:<br/>- Vice-President of the Boxing Federation of Ukraine, Master of Sports Gennady Tkachenko;<br/>- Vice-President of the Boxing Federation of Ukraine, Master of Sports of International Class, World Champion Jamal Medzhidov;<br/>- President of the SC \"VATAGA\" Igor Khromov;<br/>- Member of the Supervisory Board of the Boxing Federation of Ukraine Ivan Gilevich.<br/><br/>Let's support sports in Ukraine together"
        },
        item16: {
          title: "13 cars and three ambulances handed over",
          desc: "Our charitable foundation \"Who, if not us\" constantly supports the defenders in the hottest spots on the front!<br/><br/>Recently, we handed over 13 cars and three speedboats to the sports club @vataga_dp and the military administration!<br/><br/>These funds were collected at a charity MMA tournament!🥊<br/><br/>With the help of a unique lottery draw, we were able to collect over 3,000,000 hryvnias, which was conducted by our unrivaled hosts @mila.eremeeva @yuriy_tkach <br/><br/>The event took place in this difficult time with the support of the head of the Dnipropetrovsk regional administration!<br/>We are very grateful to everyone who joins us and helps our soldiers!!!<br/><br/>Together for Victory!"
        },
        item17: {
          title: "OPENING OF A CHILDREN'S CENTER",
          desc: "The long-awaited event has taken place 👏🏻<br/><br/>On April 19, we opened the doors of our children's space for our little ones 🙌🏻<br/><br/>Now we will draw, study, play sports together, and all this thanks to the caring benefactors of the city of Dnipro!<br/><br/>Thank you! We sincerely thank everyone who helped us create this space 🙏<br/>Thank you for the gifts @bontoy_job ❤️<br/><br/>We create good and benefit for our children to ensure they have a happy childhood despite everything!"
        },
        item18: {
          title: "CHILDREN'S PROTECTION DAY CELEBRATION",
          desc: "An event that brought together over 2,000 children 🙌🏻<br/><br/>Food courts, animation, face painting and much more for our children 🫶🏻<br/><br/>Star guests: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @amadorlopez_rumberos @nadyadorofeeva <br/><br/>Sincere gratitude to everyone who participated ☺️ <br/><br/>We do everything for a happy childhood for little children 😘"
        },
        item19: {
          title: "Evacuation of local residents",
          desc: "Local residents of the following settlements were evacuated:<br/><br/>Kurakhovo <br/>Selidovo<br/>Ukrainian<br/>Pokrovsk <br/><br/>More than 200 residents were evacuated <br/>Thank you to all those who care and joined us!<br/><br/>Together we will win!"
        },
        item20: {
          title: "Charity concert for Defenders' Day",
          desc: "SINCERELY GRATEFUL 🙏 <br/><br/>@retroville_kyiv and @musicboxua for holding a charity concert for the Day of Defenders of the Fatherland<br/><br/>All contributions are transferred to our fund, we continue to help families in need and certainly military personnel at the front 🫡<br/><br/>Grateful for the support of our star guests — @roxolanas @ado.khayat @nicolas_karma_official @_kristonko_ and @ruslana.lyzhychko<br/><br/>The presence of each guest and viewer is extremely important to us! Thank you! Glory to Ukraine 🙌🏻"
        },
        item21: {
          title: "SHELTER ASSISTANCE",
          desc: "We provided assistance to families evacuated from hot spots in our country ✨<br/><br/>We helped 46 families, including 80 children under 1 year old 👶🏼<br/><br/>We delivered:<br/>• food kits<br/>• stationery for children<br/>• sweets 🍭 <br/><br/>LET'S HELP! TOGETHER TO VICTORY!"
        },
        item22: {
          title: "St. Nicholas Day",
          desc: "It has become a good tradition for us to visit our little friends🫂<br/><br/>And this time we also had a special occasion - St. Nicholas Day🎄<br/><br/>So together with our beloved @yuriy_tkach and @mila.eremeeva we decided to invite the incredible @kalush.official to join us, and they didn't refuse 🫶And it was great! A real holiday-concert for our kids🥳🙌<br/><br/>Everyone left this meeting with gifts, smiles, wishes for each other and promises to meet again soon❤️‍🩹<br/><br/>Thank you @dniprooda for your support"
        },
        item23: {
          title: "Visited one of the schools in the city of Dnipro",
          desc: "We visited one of the schools in the city of Dnipro 🙏<br/><br/>KZO \"Multidisciplinary Educational and Rehabilitation Center No. 6 \"DOR\"<br/><br/>We congratulated the little children on the holiday of St. Nicholas 🎅🏻<br/>And as a gift for us, the children put on an incredibly interesting concert 😍<br/><br/>We are proud of such talented, kind and sincere children 🥰<br/><br/>Happy Holidays 🎉"
        },
        item24: {
          title: "St. Nicholas Day",
          desc: "Every year at the beginning of December, we gather as a big team to spend a few days together and give a holiday to those who deserve it🫂<br/><br/>Children from temporarily occupied territories, children of our military, our rescuers and doctors - we invited them all to spend St. Nicholas Day with us and tried to make this day unforgettable for them ❤️‍🔥<br/><br/>On this day, the Opera and Ballet Theater had treats, animation, face painting, creative master classes, and gifts 🎁 We watched a wonderful performance \"Snow White and the 7 Dwarfs\"✨<br/><br/>Sincerely grateful to @kazka.band for participating and supporting our holiday, the children were delighted, this is a real gift for all of us🫶<br/><br/>@yuriy_tkach, @mila.eremeeva - our hearts are with you❤️‍🔥 <br/>It is impossible to imagine our holidays without you! Thank you, dear ones, for your support. You have long become the main faces and ambassadors of our foundation. We are proud and appreciate you."
        },
        item25: {
          title: "Dnipro Regional Children's Clinical Hospital",
          desc: "This weekend we visited the Dnipro Regional Children's Clinical Hospital, where children of all ages are treated, from infants to teenagers❤️‍🩹<br/><br/>Our relatives @yuriy_tkach and @mila.eremeeva were with us, so the holiday was a success! They say the truth - a smile and a good mood are the key to a speedy recovery 🫂<br/><br/>And the highlight was the improvised performances of our friends from the band @kazka.band ✨Sasha, children adore you and we understand them🤍<br/><br/>We are very happy to congratulate our little ones on the holidays🎅🏻 Get well soon, our bunnies!🫶"
        },
      },

      btnMore: "Read more",
      btnHide: "Hide",
    },
  },

  footer: {
    desc: {
      first: "Be a volunteer, or help as a specialist.",
      second: "Press the button!",
    },
    btn: "I want to be useful",
    list: {
      title: "Useful links",
      about: "About our fund",
      news: "News",
      contact: "Contacts",
    },
    info: {
      title: "Additional Information",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'in JSC "ALPHA-BANK"',
      four: "MFIs 300346",
      five: "USREOU 23494714",
    },
  },
};
