export const TRANSLATIONS_ES = {
  navBar: {
    site: "Nuestro sitio web",
    btnHelp: "CÓMO PUEDES AYUDAR",
    btnRequ: "PEDIR AYUDA",

    link: {
      home: "Casa",
      center: "Informe",
      about: "Sobre nuestro fondo",
      news: "Noticias",
      nft: "Galería NFT",
      contact: "Contactos",
    },

    lang: {
      title: "Idioma",
      "en-US": "inglés",
      "ua-UA": "ucranio",
      "es-ES": "español",
      "fr-FR": "francés",
      "de-DE": "alemán",
      "it-IT": "italiano",
      "pl-PL": "polaco",
      "pt-PT": "portugués",
    },
  },

  pageBanner: {
    home: "Casa",
    about: "Sobre nuestro fondo",
    news: "Noticias",
    nft: "Galería NFT",
    contact: "Contactos",
    report: 'Informe',
  },

  copy: {
    copy: "Dupdo",
    success: "copiado",
  },

  home: {
    banner: {
      title: {
        first: "FUNDACIÓN CARITATIVA",
        second: '"QUIÉN, SI NO NOSOTROS"',
      },
      desc: 'Nuestro fondo "QUIÉN SI NO NOSOTROS" es totalmente responsable del uso de los fondos recaudados, cada aporte tendrá un fin directo. ¡Lo principal para nosotros es su confianza!',
      btnHelp: "Como puedes ayudar",
      btnRequ: "Pedir ayuda",
      btnDonat: "Sacrificio",
    },

    company: {
      title: "Fundación Benéfica Quién Si No Nosotros",
      desc: "Los niños son nuestro futuro. Juntos podemos superar todo y hacer nuestro futuro saludable, feliz y con fe en nuestro país. La fundación benéfica Who If Not Us organiza la recaudación de fondos para ayudar a los niños necesitados y sus familias. Asistencia a niños gravemente enfermos, a saber, pago del período de tratamiento y rehabilitación.",
      descMil: "Adquisición continua de munición necesaria para las Fuerzas Armadas de Ucrania.",
      mission: {
        title: "La misión de la Fundación",
        desc: "Dar tanto como sea posible la alegría de vivir a los niños y brindar apoyo psicológico a los padres que lo necesitan ahora.",
      },
      purpose: {
        title: "Objetivo del fondo",
        desc: "Organización de actividades infantiles que inciden en el desarrollo infantil. Apoyo psicológico a los niños y sus padres. Apoyo financiero y humanitario a las víctimas del conflicto armado en Ucrania.",
      },
      military: {
        title: "Apoyo militar",
        desc: "Suministro de municiones y automóviles a los defensores en los puntos más calientes de Ucrania.",
      },
      widget: {
        title: {
          first: "¡Juntos, cambiaremos el mundo!",
          second: "¡Comienza con nosotros ahora!",
        },
      },
      work: {
        disability: "Ayudamos a las personas con discapacidad",
        retirees: "Ayudamos a los jubilados",
        orphans: "Ayudamos a los huérfanos",
        difficult: "Ayudamos a los niños afectados durante el conflicto armado en Ucrania",
        military: "Provisión de las Fuerzas Armadas",
      },
    },

    fees: {
      title: "Cuando no hay esperanza, ni fuerza, ni dinero... ¡podemos estar ahí para ti!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "recaudado para ayudar a los niños",
        },
        {
          title: "93 500 UAH.",
          desc: 'gastado en el programa "Sanar con alegría"',
        },
        {
          title: "196 800 UAH.",
          desc: 'gastado en el programa "Terapia de recuperación"',
        },
        {
          title: "342 000 UAH.",
          desc: 'gastado en el programa "Salvar nuestra vida juntos"',
        },
      ],
    },

    our_help: {
      title: "Nuestra ayuda",
      desc: "El restaurante de pescado Soul abrió un cuartel general de socorro durante la guerra. Ayudan con alimentos para las Fuerzas Armadas, la policía y los refugiados-desplazados “Desde hace 41 días, cada uno de nosotros ayuda en lo que puede. Todo esto es energía fuerte e invencible, nos unió en un todo. Y gracias a eso nos estamos moviendo.",
      btn: "Ayudar",
    },

    donat: {
      title: "Asistencia a los niños heridos durante la guerra en el territorio de Ucrania",
      btn: "Donar",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Monedero para donaciones BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Monedero para donaciones USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Cuenta actual",
        },
      },
    },

    help: {
      title: "¿Cómo podemos ayudar?",
      desc: "Tal vez tenga una forma de ayudar a una familia en particular. Escríbanos y nos pondremos en contacto con usted.",
      form: {
        name: "Su nombre",
        phone: "+380 99 999-99-99",
        select: {
          one: "Elige cómo puedes ayudar",
          two: "Dinero",
          three: "Voluntario",
          four: "Especialista",
          five: "Nuestra ayuda",
        },
        mass: "Tu mensaje",
        error: "¡Mensaje no enviado! Porfavor llene todos los campos.",
        success: "¡Mensaje enviado con éxito!",
        btn: "Enviar mensaje",
      },
      specialityContent: [
        {
          title: "Como voluntario de la Fundación",
          desc: "Debe poder participar en los proyectos de la fundación de forma permanente o puntual. Debes tener al menos 18 años.",
        },
        {
          title: "Como especialista",
          desc: "Como médico para ayudar a los enfermos, como psicólogo u otro especialista.",
        },
        {
          title: "Como blogger de internet",
          desc: "Listo para ayudar con la difusión de información en Internet.",
        },
        {
          title: "Como tienda asociada",
          desc: "Realización de promociones relacionadas con cosas y necesidades básicas.",
        },
        {
          title: "Ayuda voluntaria con dinero",
          desc: "Aceptamos transferencias voluntarias a la cuenta de la tarjeta de la Fundación.",
        },
      ],
    },

    request: {
      title: "Pedir ayuda",
      desc: "Escriba sus contactos, describa la situación en detalle:",
      info: {
        title: "Puede obtener información detallada contactando a nuestro especialista:",
        name: "Malajova Angelina Konstantinovna",
      },
    },

    transfer: {
      title: "Transferencia de dinero",
      error: "Error",
      success: "Exitosamente",
      thank: "Gracias por el apoyo a los niños de Ucrania.",
    },
  },

  about: {
    info: {
      title: "QUIÉN, SI NO NOSOTROS Fundación Benéfica",
      subtitle: "Cómo empezó todo…",
      desc: {
        one: "El fondo de caridad se registró en mayo de 2021, pero el trabajo real de apoyo a los ucranianos ha estado en marcha desde 2020.",
        two: 'El objetivo principal del fondo "¿Quién sino nosotros?" - ayuda para los niños. Nuestras tareas también incluyen el apoyo a personas con discapacidad y jubilados. Nuestra fundación cree que la generosidad y la generosidad son las cualidades humanas más importantes.',
        three:
          "Estamos convencidos de que haciendo el bien nos volvemos más fuertes y felices, mejoramos las relaciones, creamos un mundo mejor. Todo el mundo debería poder compartir tiempo, dinero o energía. Esta es una forma confiable de crear armonía entre las personas.",
      },

      war: {
        title: "La guerra nos cambió a cada uno de nosotros.",
        desc: {
          first:
            "La Fundación Who If Not Us continúa trabajando las 24 horas del día, los 7 días de la semana para ayudar a cualquier persona que lo necesite.",
          second: {
            main: "Desde Polonia nos enviaban ropa, comida, productos de higiene personal. ¡Animamos a todos a unirse a nuestra lucha contra nuestro enemigo común y unirse al equipo! ¡Gracias a todos los que ya han hecho una contribución significativa! ¡Juntos somos capaces de crear cosas increíbles! ¡Juntos marchamos hacia la victoria! ¡Gloria a Ucrania!",
            sub: "¡La victoria será nuestra!",
          },
        },
      },
    },

    what: {
      title: "lo que hacemos",
      item: {
        one: "Ayudamos a las personas con discapacidad",
        two: "Ayudamos a los huérfanos",
        three: "Ayudamos a los jubilados",
        four: "Ayudamos a los niños afectados durante el conflicto armado en Ucrania",
      },
    },

    history: {
      title: "Nuestra historia",
      desc: {
        fond: "La fundación benéfica WHO IF NOT WE se estableció con el único propósito de hacer el bien a las personas. Intentamos trabajar en diferentes direcciones:",
        believ:
          "Nuestra fundación cree que la amabilidad y la generosidad son las principales cualidades humanas que nos diferencian de los animales.",
        generate:
          "La fundación benéfica WHO IF NOT fue creada para reunir a personas que están dispuestas a ayudar. Tratamos de asegurarnos de que todos, independientemente de su estado financiero y edad, tengan la oportunidad de ayudar a los niños. Nuestra organización quiere que todos los niños infelices tengan una infancia feliz y pacífica.",
      },

      list: {
        money: "Apoyo económico y psicológico a personas en situaciones críticas;",
        therapy: "Tratamiento de niños gravemente enfermos;",
        moral:
          "Apoyo a los niños afectados por el conflicto armado en Ucrania;",
      },
    },
  },

  news: {
    btn: "Lee mas",
    report: "reportaje fotográfico",
    thanks: "cartas de agradecimientoі",
    page: {
      latest: "Últimas noticias",
      help: {
        title: "Ayudar",
        desc: "Sea voluntario, o ayude como especialista. ¡Presiona el botón!",
        btn: "Sacrificio",
      },
    },
  },

  contact: {
    title: "Información del contacto",
    address: {
      title: "Dirección",
      desc: "Dnipro, calle Korolenka, 8, oficina 10",
    },
    mail: {
      title: "Correo",
    },
    phone: {
      title: "Teléfono",
    },
    work: {
      title: "Horas Laborales",
      desc: "Lunes a Viernes: 10:00 a 17:00",
    },
  },

  nft: {
    title: "Galería",
    btn: "Comprar",
    price: "Precio",

    block: {
      title: {
        main: "El equipo creó el NFT de nuestro fondo, el dinero de la venta se destina a ayudar a las Fuerzas Armadas de Ucrania.",
        sub: "y personas sin hogar.",
      },
    },

    info: {
      title: {
        main: "Febrero 2022 a Ucrania",
        sub: "vino la guerra.",
      },

      desc: {
        war: "La guerra iniciada por Rusia. Todos los ciudadanos de nuestro país se han unido en un todo, en un escudo de acero en la lucha y el enfrentamiento de un país que ya ha derrotado a millones de ucranianos comunes.",
        team: 'El equipo "Quién, si no nosotros", como miles de otras organizaciones benéficas, desde el primer día de la guerra toma todas las partes posibles en el destino y la asistencia a nuestro país y nuestros ciudadanos.',
        friend: "Estimados amigos y filántropos, esperamos cualquier ayuda y apoyo que su organización pueda brindar.",
      },
      together: "Después de todo, juntos somos Ucrania.",
    },

    nftContent: [
      {
        img: "4",
        cat: "Ciudades heroicas de Ucrania",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Ciudades heroicas de Ucrania",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Ciudades heroicas de Ucrania",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Ciudades heroicas de Ucrania",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Ciudades heroicas de Ucrania",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Ciudades heroicas de Ucrania",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Ciudades heroicas de Ucrania",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Ciudades heroicas de Ucrania",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Ciudades heroicas de Ucrania",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Ciudades heroicas de Ucrania",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  report: {
    donat: {
      title: "Estaremos agradecidos por su ayuda.",

      switch: {
        one: "Una vez",
        monthly: "Mensual",
      },

      other: "Otra cantidad",
      btn: "Donar",
      btnCrypto: "Donar en criptomoneda",

      modal: {
        title: "Criptomoneda",
        btc: "Monedero de donación BTC",
        usdt: "Cartera de donación USDT TRC-20",
      },
    },

    statistic: {
      title: "Nuestro informe",

      items: {
        one: "Constantemente damos ayuda y apoyamos a los niños que lo necesitan.",
        two: "Cooperamos con socios estrella que apoyan nuestras actividades.",
        three: "Organizamos eventos sociales para apoyar a todos los niños que sufrieron la guerra.",
      },

      data: {
        item1: {
          title: "Flash mob para niños en Dnipro",
          desc: "⏳Nunca habrá un momento conveniente en la vida<br/> Mi equipo y yo queríamos algo que pudiera aliviar a los padres e interesar a los niños💃<br/> La solución se imprimió en 5 minutos: FLASHMOB🤩<br/><br/> Aquí estamos y reímos y lloramos Preparándonos en lugar de salir con amigos<br/> No tuvimos éxito en muchas cosas, pero el resultado más importante fue darles a los niños la oportunidad de respirar la vida que tenían antes de la guerra. Dando risas y alegría, dando a las madres despreocupación y la oportunidad de bailar a la par de los niños<br/><br/> No luchamos por el ideal, nos esforzamos por vivir aquí y ahora Y dar felicidad y bondad hoy🎉 <br /><br/>Gracias a nuestras increíbles mamás que estuvieron con nosotros esta vez<br/><br/>Y un gran agradecimiento a todo el equipo❤️<br/>Síguenos en Instagram @hto_yaksho_ne_my"
        },
        item2: {
          title: "Vehículos para las Fuerzas Armadas de Ucrania",
          desc: "COMPRAMOS UN COCHE PARA LA AFU🔥<br/><br/>Compramos una ambulancia, empezamos a repararla, pintarla y equiparla…<br/>Y ahora estamos listos para presentarles el resultado 🔥<br/ ><br/>Este es el auto que irá directamente a la unidad de asalto en Bakhmut❗️Todos entendemos que los autos, como cualquier otro equipo en el frente, son consumibles🤷🏻‍♀️<br/><br/>Pero es Es difícil y casi imposible luchar sin él ❌ <br/><br/>Pero todavía esperamos que esta máquina sirva a nuestro ejército durante el mayor tiempo y lo mejor posible."
        },
        item3: {
          title: "Авто для військових",
          desc: "🔥15 COCHES A BAKHMUT<br/><br/>Junto con el restaurante Pastoral y los benefactores de Dnipro, compramos 15 coches y un gran lote de municiones para nuestro personal militar estacionado en la dirección de Bakhmut<br/><br/> Estamos agradecidos con todos 🙏🏻 que se sumaron a esta genial causa 🫶🏻<br/><br/>Estamos agradecidos con nuestras Fuerzas Armadas y los llamamos a donar, ayudar y creer en nuestra victoria."
        },
        item4: {
          title: "Año de guerra",
          desc: "¿Qué significó para nuestra fundación y centro infantil? <br/><br/> ••• Empezamos nuestro trabajo sin conocer a nadie, sin tener experiencia, todo lo que teníamos era un fuerte deseo de ser útiles. <br/><br/> 📎Conocimos a nuestros voluntarios completamente por casualidad. ¡Simplemente vinieron a nosotros en busca de ayuda y mostraron iniciativa! Querían trabajar con nosotros y ayudar a las mismas personas que sufrieron la guerra. <br/>📎Llevamos mucho tiempo llevando ayuda al hospital militar/hospital regional de Mechnikov. <br/>📎Durante todo el tiempo, hemos brindado asistencia a más de 1.500 familias con niños. <br/>📎Apoyamos a nuestros militares: compramos uniformes, guantes tácticos, una cámara termográfica y 40 pares de zapatos para la dirección de Donetsk. que compramos en @vadrus.ua <br/ >📎Este año hemos recibido regularmente ayuda de @opir.sambir2022 @polovkoandrey @revivalfund.ua @kolo_fund <br/>📎Los restaurantes \"Akvarel\" y \"Soul Fish\" han sido preparando comida para nuestros defensores y las personas que tuvieron que abandonar sus hogares y comenzar una nueva vida en Dnipro. Y nosotros, a su vez, les ayudamos con productos en este <br/>📎El Centro de Psicología OK @ok.psyspace ha estado brindando apoyo psicológico a niños y padres desde el comienzo de la guerra, y también nos proporcionó su sala para talleres. -clases<br/>📎Organizamos eventos con bloggers y nuestros socios<br/>📎Realizamos un flash mob de baile junto con padres e hijos<br/>📎Abrimos la dirección de \"Entrenamiento de Ballet\"<br/>📎 Y aseguró el pleno funcionamiento del centro infantil y la prestación de ayuda humanitaria a niños y mujeres embarazadas de forma permanente. Este año ha sido el más difícil para todos nosotros... <br/><br/>Pero solo hay una receta. para la victoria: estar unidos, fuertes, hacer todo lo posible por la victoria y ser queridos por aquellos que necesitan esto!"
        },
        item5: {
          title: "Nosotros informamos!",
          desc: "El 17 de junio celebramos la fiesta \"Tierra de los Invencibles\" con el apoyo de la Administración Militar Regional de Dnipropetrovsk 🫂<br/><br/>Una fiesta para los hijos de los desplazados internos de diferentes partes del país 🫶🏻en el local de Lavina @lavinapark <br/><br/>Con el apoyo de nuestros amigos estrellas @annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila.eremeeva @viktoriya_tka4 y @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official < br/><br/>Nuestros militares, que defienden nuestro país y dan la oportunidad de crear fiestas, recibieron 28 automóviles y más de 20 piezas de equipamiento 🚐"
        },
        item6: {
          title: "Vehículos para las Fuerzas Armadas de Ucrania",
          desc: "Gracias a nuestros donantes, el 17 de junio, nosotros, la fundación benéfica \"¿Quién, si no nosotros?\", pudimos recaudar 1.074.075 UAH🤩<br/><br/>Y con nuestros esfuerzos, pudimos comprar 35 piezas. DJI Mavic 3T y entrega 05.07 a la Administración Estatal Regional de Dnipropetrovsk, nuestros defensores, que nos dan la oportunidad de realizar tales eventos y acercar la victoria<br/><br/>Estamos agradecidos a todos los que asistieron al evento \"Tierra de El Invencible\"<br/><br/ >Gracias @a.wellboy @annatrincher_official @demchukmusic @dianagloster @uliana_royce @mashaa.kondratenko @viktoriya_tka4 @yuriy_tkach"
        },
        item7: {
          title: "Ayuda humanitaria Odesa",
          desc: "Nuestra fundación \"¿Quién si no nosotros?\" visitó la ciudad de Odesa con ayuda humanitaria🥰<br/><br/>Se distribuyeron más de 110 kits a familias desplazadas con niños menores de 3 años🫶🏻<br/><br/>Gracias A nuestros socios @revivalfund.ua y @opir.sambir2022 🫂<br/><br/>Ayudando y acercando juntos la victoria"
        },
        item8: {
          title: "Ayuda a Kherson",
          desc: "Nosotros, la fundación benéfica \"Quién si no nosotros\" visitamos la ciudad de Kherson con ayuda humanitaria para las víctimas de la explosión de la central hidroeléctrica de Kakhovka 🙏<br/><br/>Transferido: <br/>• Ropa de abrigo - 500 unidades < br/>• Ropa de hombre - 300 unidades<br/>• Ropa de mujer - 500 unidades<br/>• Juguetes para niños - 7 cajas<br/>• Libros de texto para niños (ucraniano/inglés) - 3 cajas<br/>• Cochecitos /cunas/sillas para alimentación - 6 unidades<br/>• Alimentos para bebés - 2 cajas<br/>• Acondicionador para el cabello - 2 cajas<br/>• Cepillos de dientes - 1 caja<br/>• Zapatos de mujer/hombre - 50 pares <br/>• Mantas - 30 piezas<br/>• Antiséptico - 20 litros<br/>• Ropa interior - 30 paquetes<br/>• Colchón infantil - 10 piezas<br/>• Ropa infantil - 50 cajas<br/>• Toalla - 1 caja<br/>• Zapatillas - 20 pares<br/>• Tablero de dibujo - 1 pieza <br/>• Almohadas/mantas - 50 piezas<br/>• Juegos de comestibles (comida enlatada, mantequilla, azúcar, té, galletas, pasta, papel higiénico, jabón, trigo sarraceno) - 380 juegos<br/>• Estación recargable - 10 piezas<br/>• Bancos de energía - 25 piezas<br/>• Agua potable carbonatada - 1500 toneladas<br/>• Agua potable sin gas - 8500 toneladas<br/><br/>📍Punto Nezlamnosti, ciudad. Kherson basado en: Complejo Educativo General de Formación de Kherson 48 En la dirección: ciudad. Jersón, calle. 28 Ejércitos, 14<br/><br/>Seguimos ayudando🙏 Creemos en la Victoria"
        },
        item9: {
          title: "Torneo de billar",
          desc: "TORNEO DE BILLAR BENÉFICO dedicado al DÍA DE LA INDEPENDENCIA DE UCRANIA<br/><br/>Por primera vez en muchos años, logramos reunir a los mejores atletas de Ucrania en Dnipro‼️32 atletas de 11 regiones y 14 ciudades de Ucrania están participando Participa en el concurso. ¡Todas las contribuciones caritativas de los participantes se donaron al 100 % para ayudar a nuestros militares! La cantidad es de 243 000 UAH 🔥<br/><br/>En la final del torneo, los militares recibieron artículos esenciales para proteger a nuestro país. !<br/><br/>Gracias a nuestros defensores por la oportunidad de realizar este torneo 💙💛"
        },
        item10: {
          title: "Estación de bombeo de Kherson",
          desc: "Nosotros, la fundación benéfica \"¿Quién, si no nosotros?\", visitamos el pueblo de Kalinivske, en la región de Kherson, para brindar ayuda.<br/><br/>El pueblo estuvo ocupado durante 8 meses🐖.<br/><br/>Residentes que permanecieron en su tierra natal La tierra simplemente sobrevive, porque el pueblo está completamente destruido 😥<br/><br/>Tomamos una ESTACIÓN DE BOMBEO para que la gente tenga agua🙏<br/><br/>Seguimos ayudando ! Nos estamos acercando a la victoria, porque llegará muy pronto😉"
        },
        item11: {
          title: "Día de la ciudad con helado Lasunka",
          desc: "El 09.09, en el Día de la Ciudad de Dnipro, junto con \"Lasunka\" @lasunka_icecream, regalamos helado a todos los que lo quisieron y entretuvimos a los niños 🌸<br/><br/>Veamos juntos cómo fue"
        },
        item12: {
          title: "Vacaciones \"AVANZANDO HACIA LA VICTORIA\"",
          desc: "Un evento deportivo para 500 niños, creado con el apoyo de la administración regional para que nuestros niños solo sigan adelante, no se olviden de sus metas y logros, a pesar de la guerra 🙏<br/><br/>Estamos infinitamente agradecidos a @ por organizar el flash mob _oleksandrleshchenko<br/><br/>Atletas de clase mundial🏅 y amigos estrellas como @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official<br/><br/>Gracias A todos los que participaron en este evento. En particular, a los organizadores, sin los cuales nuestro evento no habría tenido lugar: @viktoriya_tka4 @deniskonovalov @m_galat @vladimir_drachuk <br/>@franz_audio <br />Eres el mejor 🫂"
        },
        item13: {
          title: "Maquinaria para las Fuerzas Armadas de Ucrania",
          desc: "¡Nuestra fundación benéfica \"Quién si no nosotros\" apoya constantemente a los defensores en los puntos más calientes del frente!<br/><br/>¡Recientemente, junto con el club deportivo @vataga_dp y la administración militar, donamos 13 automóviles y tres ambulancias! <br/> <br/>¡Estos fondos se recaudaron en un torneo benéfico de MMA! 🥊<br/><br/>Con la ayuda de un sorteo de lotería único, pudimos recaudar más de 3.000.000 de grivnas, que fue realizado por nuestro Anfitriones inigualables @mila.eremeeva @yuriy_tkach <br/> <br/>¡El evento se llevó a cabo en este momento difícil con el apoyo del jefe de la administración regional de Dnipropetrovsk! ¡Estamos muy agradecidos a todos los que se unen a nosotros y ayudan a nuestros soldados! !!<br/><br/>¡Juntos por la victoria!"
        },
        item14: {
          title: "TORNEO DE AJEDREZ DE UCRANIA ♟️",
          desc: "El 22 de octubre se celebró el torneo de ajedrez \"Estrategia de lo inquebrantable\"🙌🏻<br/><br/>Participaron más de 350 personas, de las cuales 125 eran niños☺️<br/><br/>Los participantes recibieron obsequios de nuestra Fundación<br/><br/ >🎁Apoyamos el deporte en Ucrania<br/><br/>Creamos el futuro, Gloria a Ucrania 🫂"
        },
        item15: {
          title: "Torneo en memoria del Maestro de Deportes Yanchenko O.O.",
          desc: "Junto con Vataga, del 23 al 26 de noviembre se celebró en la ciudad de Kamianske un torneo en memoria del Maestro de Deportes Yanchenko O.O. <br/><br/>El torneo reunió a 1️⃣3️⃣9️⃣ participantes de 7️⃣ regiones de Ucrania. Partidos muy vivos y competitivos, donde cada uno de los ganadores y premiados, junto a sus entrenadores, recibieron premios individuales y premios en metálico. <br/><br/>🏆 Los mejores boxeadores fueron galardonados con premios memorables por sus habilidades<br/><br/>👏🏼 La ceremonia de premiación fue realizada por invitados de honor, entre ellos:<br/>- Vicepresidente de la Asociación de Boxeo Federación de Ucrania, maestro de deportes Gennady Tkachenko;<br/>- vicepresidente de la Federación de Boxeo de Ucrania, maestro de deportes de clase internacional, campeón del mundo Jamal Medzhidov;<br/>- presidente de la SC \"VATAGA\" Igor Khromov ;<br/>- miembro del consejo de supervisión de la Federación de Boxeo de Ucrania Ivan Gilevich .<br/><br/>Apoyemos juntos el deporte en Ucrania"
        },
        item16: {
          title: "Se entregaron 13 vehículos y tres ambulancias",
          desc: "¡Nuestra fundación benéfica \"Quién si no nosotros\" apoya constantemente a los defensores en los puntos más calientes del frente!<br/><br/>¡Recientemente, entregamos 13 automóviles y tres lanchas rápidas al club deportivo @vataga_dp y a la administración militar! <br/><br />¡Estos fondos se recaudaron en un torneo benéfico de MMA! 🥊<br/><br/>Con la ayuda de un sorteo de lotería único, pudimos recolectar más de 3.000.000 de grivnas, que fue realizado por nuestro anfitriones inigualables @mila.eremeeva @yuriy_tkach<br/><br />¡El evento tuvo lugar durante este momento difícil con el apoyo del jefe de la administración regional de Dnipropetrovsk!<br/>Estamos muy agradecidos a todos los que se unen a nosotros y ¡¡¡Ayuda a nuestros soldados!!!<br/><br/>¡Juntos por la victoria!"
        },
        item17: {
          title: "APERTURA DE UN CENTRO INFANTIL",
          desc: "El evento tan esperado ya tuvo lugar 👏🏻<br/><br/>El 19 de abril abrimos las puertas de nuestro espacio infantil para nuestros peques 🙌🏻<br/><br/>Ahora dibujaremos, estudiaremos , practicar deportes juntos, y todo esto gracias a los benefactores solidarios de la ciudad de Dnipro.<br/><br/>¡Gracias! Agradecemos sinceramente a todos los que nos ayudaron a crear este espacio 🙏<br/>Gracias por los regalos @bontoy_job ❤️<br/><br/>¡Creamos bondad y beneficios para nuestros niños para asegurarnos de que tengan una infancia feliz a pesar de todo!"
        },
        item18: {
          title: "CELEBRACIÓN DEL DÍA DE PROTECCIÓN DE LA INFANCIA",
          desc: "Un evento que reunió a más de 2.000 niños🙌🏻<br/><br/>Patios de comidas, animación, pintacaras y mucho más para nuestros niños 🫶🏻<br/><br/>Invitados estrella: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @amadorlopez_rumberos @nadyadorofeeva <br/><br/>Sincero agradecimiento a todos los que participaron ☺️ <br/><br/>Hacemos todo lo posible para que los niños pequeños tengan una infancia feliz 😘"
        },
        item19: {
          title: "Evacuación de residentes locales",
          desc: "Los residentes de los siguientes asentamientos fueron evacuados:<br/><br/>Kurakhovo <br/>Selidovo<br/>Ucrania<br/>Pokrovsk <br/><br/>Más de 200 residentes fueron evacuados <br/ >¡Gracias a todos los que se preocupan y se han unido a nosotros!<br/><br/>¡Juntos venceremos!"
        },
        item20: {
          title: "Concierto benéfico por el Día de los Defensores",
          desc: "SINCERAMENTE AGRADECIDO 🙏 <br/><br/>@retroville_kyiv y @musicboxua por realizar un concierto benéfico para el Día de los Defensores<br/><br/>Todas las contribuciones han sido transferidas a nuestro fondo, seguimos ayudando a las familias necesitadas y Sin duda los militares en el frente 🫡<br/><br/>Agradecidos por el apoyo de nuestros invitados estrella: @roxolanas @ado.khayat @nicolas_karma_official @_kristonko y @ruslana.lyzhychko<br/><br/>La presencia de ¡Cada invitado y espectador es extremadamente importante para nosotros! ¡Gracias! Gloria a Ucrania 🙌🏻"
        },
        item21: {
          title: "ASISTENCIA DE REFUGIO",
          desc: "Brindamos asistencia a familias evacuadas de puntos críticos de nuestro país ✨<br/><br/>Ayudamos a 46 familias, incluidos 80 niños menores de 1 año 👶🏼<br/><br/>Brindamos:<br/> • kits de alimentos< br/>• papelería para niños<br/>• dulces 🍭 <br/><br/>¡AYUDAMOS! ¡JUNTOS HASTA LA VICTORIA!"
        },
        item22: {
          title: "Día de San Nicolás",
          desc: "Se ha convertido en una buena tradición para nosotros visitar a nuestros pequeños amigos🫂<br/><br/>Y esta vez también tuvimos una ocasión especial: el día de San Nicolás🎄<br/><br/>Así que junto con nuestros queridos @yuriy_tkach y @mila.eremeeva decidimos invitar a los increíbles @kalush.official a unirse a nosotros, y no se negaron 🫶 ¡Y fue genial! Un verdadero concierto navideño para nuestros niños🥳🙌<br/><br/>Todos salieron de esta reunión con regalos, sonrisas, deseos mutuos y promesas de volver a encontrarse pronto❤️‍🩹<br/><br/>Gracias @ Dniprooda por su apoyo"
        },
        item23: {
          title: "Visitamos una de las escuelas de la ciudad de Dnipro.",
          desc: "Visitamos una de las escuelas de la ciudad de Dnipro 🙏<br/><br/>KZO \"Centro multidisciplinario de educación y rehabilitación nº 6 \"DOR\"<br/><br/>Felicitamos a los niños pequeños por la fiesta de San Nicolás 🎅🏻<br/>Y como regalo, los niños nos ofrecieron un concierto increíblemente interesante 😍<br/><br/>Estamos orgullosos de unos niños tan talentosos, amables y sinceros 🥰<br/>< Felices fiestas 🎉"
        },
        item24: {
          title: "Día de San Nicolás",
          desc: "Cada año, a principios de diciembre, nos reunimos como un gran equipo para pasar unos días juntos y darles unas vacaciones a quienes se lo merecen🫂<br/><br/>Niños de territorios ocupados temporalmente, hijos de nuestros militares, nuestros Rescatistas y médicos: todos estamos invitados a pasar el día de San Nicolás con nosotros y tratamos de hacer que este día sea inolvidable para ellos ❤️‍🔥<br/><br/>En este día, el Teatro de Ópera y Ballet tenía golosinas, animación , pintura facial, clases magistrales creativas y regalos 🎁 Nos ​​pareció maravillosa la actuación \"Blancanieves y los 7 enanitos\"✨<br/><br/>sinceramente agradecidos a @kazka.band por participar y apoyar nuestras vacaciones, los niños estaban encantados , este es un verdadero regalo para todos nosotros🫶<br/><br/> @yuriy_tkach , @mila.eremeeva - nuestros corazones están con ustedes❤️‍🔥 <br/>¡Es imposible imaginar nuestras vacaciones sin ustedes! Gracias, queridos, por vuestro apoyo. Hace tiempo que os habéis convertido en los rostros principales y embajadores de nuestra fundación. Estamos orgullosos de vosotros y os agradecemos."
        },
        item25: {
          title: "Hospital clínico infantil regional de Dnipro",
          desc: "Este fin de semana visitamos el Hospital Clínico Infantil Regional de Dnipro, donde se tratan a niños de todas las edades, desde bebés hasta adolescentes❤️‍🩹<br/><br/>Nuestros familiares @yuriy_tkach y @mila.eremeeva estaban con nosotros, así que las vacaciones ¡fue un éxito! Dicen que es verdad: una sonrisa y un buen humor son la clave para una pronta recuperación 🫂<br/><br/>Y lo más destacado fueron las actuaciones improvisadas de nuestros amigos de la banda @kazka.band ✨Sasha, los niños adoran Tú y nosotros los entendemos🤍<br/><br />Estamos muy contentos de felicitar a nuestros pequeños por las fiestas🎅🏻 ¡Mejórate pronto, nuestros conejitos!🫶"
        },
      },

      btnMore: "Leer más",
      btnHide: "Ocultar",
    },
  },

  footer: {
    desc: {
      first: "Sea voluntario, o ayude como especialista.",
      second: "¡Presiona el botón!",
    },
    btn: "Quiero ser útil",
    list: {
      title: "Enlaces útiles",
      about: "Sobre nuestro fondo",
      news: "Noticias",
      contact: "Contactos",
    },
    info: {
      title: "información adicional",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'en JSC "ALFA-BANCO"',
      four: "IMF 300346",
      five: "USREOU 23494714",
    },
  },
};
