import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useLanguage } from "../../contexts/LanguageContext";
import { NEWS_ARRAY } from "../../utils/constants";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faFileCircleCheck,
  faFolder,
  faHeart,
  faImages,
} from "@fortawesome/free-solid-svg-icons";

import PageBanner from "../PageBanner";
import Footer from "../Footer";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const NewsPage = () => {
  // Translation
  const { t } = useTranslation();
  const basePath = "news.";

  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const { language } = useLanguage();

  useEffect(() => {
    if (id) {
      const integerId = parseInt(id);
      const temp = NEWS_ARRAY.find((elem) => elem.id === integerId);
      setProduct(temp);
    }
  }, [id]);

  const options = {
    thumbnails: {
      showThumbnails: false,
    },
  };

  return product ? (
    <>
      <PageBanner />
      <div>
        <Container>
          <Row className="news page">
            <Col lg={8}>
              <h2>{product.data[language].title}</h2>
              <div className="date">
                <span>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  {product.time}
                </span>
                <span>
                  <FontAwesomeIcon icon={faFolder} />
                  {product.data[language].tags}
                </span>
              </div>
              <p>{product.data[language].short_desc}</p>
              <div className="title">
                <h3>
                  <FontAwesomeIcon icon={faImages} />
                  {t(basePath + "report")}
                </h3>
              </div>
              <SimpleReactLightbox>
                <SRLWrapper options={options}>
                  <Row>
                    {product.img_report.map((item, i) => (
                      <Col lg={3} md={6} key={i}>
                        <div className="img">
                          <a href={item.img.default}>
                            <img src={item.img.default} alt="" />
                          </a>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </SRLWrapper>
              </SimpleReactLightbox>
              {product.video.state === true ? (
                <div className="video">
                  <iframe
                    width="100%"
                    height="400px"
                    src={`https://www.youtube.com/embed/${product.video.link}`}
                    title="Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              ) : (
                <></>
              )}
              {product.letters_thanks.state === true ? (
                <>
                  <div className="title">
                    <h3>
                      <FontAwesomeIcon icon={faFileCircleCheck} />
                      {t(basePath + "thanks")}
                    </h3>
                  </div>
                  <SimpleReactLightbox>
                    <SRLWrapper options={options}>
                      <Row>
                        {product.letters_thanks.img_map.map((item, i) => (
                          <Col lg={3} md={6} key={i}>
                            <div className="img">
                              <a href={item.img.default}>
                                <img src={item.img.default} alt="" />
                              </a>
                            </div>
                          </Col>
                        ))}
                        ;
                      </Row>
                    </SRLWrapper>
                  </SimpleReactLightbox>
                </>
              ) : (
                <></>
              )}
            </Col>
            <Col lg={4}>
              <div className="item-left">
                <h5>{t(basePath + "page.latest")}</h5>
                {NEWS_ARRAY.slice(0, 4).map((item) => (
                  <Col lg={12} key={item.id}>
                    <div className="post">
                      <div className="image">
                        <img src={item.img_preview.default} alt="" />
                      </div>
                      <div className="info">
                        <Link
                          to={{
                            pathname: `/news/${item.id}`,
                          }}
                        >
                          {`${item.data[language].title.substring(0, 24)}...`}
                        </Link>
                        <span>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                          {item.time}
                        </span>
                      </div>
                    </div>
                  </Col>
                ))}
              </div>

              <div className="item-left">
                <h5>{t(basePath + "page.help.title")}</h5>
                <div className="donat-left">
                  <img
                    src={require("../../assets/img/blog-help.jpg").default}
                    alt=""
                  />
                  <p>{t(basePath + "page.help.desc")}</p>
                  <div className="btn-groups">
                    <Button href="/#help" className="btn-st">
                      <FontAwesomeIcon icon={faHeart} />
                      {t(basePath + "page.help.btn")}
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  ) : (
    <>Error</>
  );
};

export default NewsPage;
