export const TRANSLATIONS_PL = {
  navBar: {
    site: "Nasza strona internetowa",
    btnHelp: "JAK MOŻESZ POMÓC?",
    btnRequ: "SZUKAJ POMOCY",

    link: {
      home: "Dom",
      center: "Raport",
      about: "O naszym funduszu",
      news: "Aktualności",
      nft: "Galeria NFT",
      contact: "Łączność",
    },

    lang: {
      title: "Język",
      "en-US": "język angielski",
      "ua-UA": "ukraiński",
      "es-ES": "hiszpański",
      "fr-FR": "Francuski",
      "de-DE": "Niemiecki",
      "it-IT": "Włoski",
      "pl-PL": "Polski",
      "pt-PT": "portugalski",
    },
  },

  pageBanner: {
    home: "Dom",
    about: "O naszym funduszu",
    news: "Aktualności",
    nft: "Galeria NFT",
    contact: "Łączność",
    report: 'Raport',
  },

  copy: {
    copy: "Kopiuj",
    success: "Skopiowane",
  },

  home: {
    banner: {
      title: {
        first: "FUNDACJA CHARYTATYWNA",
        second: "„KTO, JEŚLI NIE MY”",
      },
      desc: "Nasz fundusz „KTO, JEŚLI NIE MY” jest w pełni odpowiedzialny za wykorzystanie zebranych środków, każda wpłata będzie miała bezpośredni cel. Najważniejsze dla nas jest Twoje zaufanie!",
      btnHelp: "Jak możesz pomóc",
      btnRequ: "Zapytaj o pomoc",
      btnDonat: "Poświęcenie",
    },

    company: {
      title: "Fundacja Charytatywna Kto Jeśli Nie My",
      desc: "Dzieci to nasza przyszłość. Razem możemy przezwyciężyć wszystko i sprawić, by nasza przyszłość była zdrowa, szczęśliwa iz wiarą w nasz kraj. Fundacja Charytatywna „Kto jeśli nie my” organizuje zbiórki pieniędzy na pomoc potrzebującym dzieciom i ich rodzinom. Pomoc ciężko chorym dzieciom, czyli opłata za leczenie i okres rehabilitacji.",
      descMil: "Ciągłe pozyskiwanie niezbędnej amunicji dla Sił Zbrojnych Ukrainy.",
      mission: {
        title: "Misja Fundacji",
        desc: "Daj jak najwięcej radości życia dzieciom i zapewnij wsparcie psychologiczne rodzicom, którzy teraz tego potrzebują.",
      },
      purpose: {
        title: "Cel funduszu",
        desc: "Organizacja zajęć dla dzieci wpływających na rozwój dzieci. Wsparcie psychologiczne dla dzieci i ich rodziców. Wsparcie finansowe i humanitarne dla ofiar konfliktu zbrojnego na Ukrainie.",
      },
      military: {
        title: "Wsparcie wojskowe",
        desc: "Zaopatrzenie obrońców w amunicję i samochody w najgorętszych punktach Ukrainy.",
      },
      widget: {
        title: {
          first: "Razem - zmienimy świat!",
          second: "Zacznij z nami już teraz!",
        },
      },
      work: {
        disability: "Pomagamy osobom niepełnosprawnym",
        retirees: "Pomagamy emerytom",
        orphans: "Pomagamy sierotom",
        difficult: "Pomagamy dzieciom poszkodowanym podczas konfliktu zbrojnego na Ukrainie",
        military: "Zaopatrzenie Sił Zbrojnych",
      },
    },

    fees: {
      title: "Kiedy nie ma nadziei, siły ani pieniędzy - możemy być tam dla Ciebie!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "zbierane na pomoc dzieciom",
        },
        {
          title: "93 500 UAH.",
          desc: 'wydatki na program "Uzdrawianie z radością"',
        },
        {
          title: "196 800 UAH.",
          desc: 'spędzonych na programie "Terapia regeneracyjna"',
        },
        {
          title: "342 000 UAH.",
          desc: 'wydane na program "Razem ratujemy życie"',
        },
      ],
    },

    our_help: {
      title: "Nasza pomoc",
      desc: "Restauracja rybna Soul otworzyła podczas wojny kwaterę główną pomocy humanitarnej. Pomagają z żywnością dla Sił Zbrojnych, policji i przesiedleńców „Od 41 dni każdy z nas pomaga, ile tylko może. To wszystko jest mocną i niezwyciężoną energią, połączyła nas w jedną całość. I dzięki temu ruszamy.",
      btn: "Pomoc",
    },

    donat: {
      title: "Pomoc dzieciom poszkodowanym w czasie wojny na terytorium Ukrainy",
      btn: "Przekaż darowiznę",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Portfel na darowizny BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Portfel na darowizny USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Rachunek bieżący",
        },
      },
    },

    help: {
      title: "Jak możemy pomóc?",
      desc: "Być może masz sposób, aby pomóc konkretnej rodzinie. Napisz do nas, a my skontaktujemy się z Tobą.",
      form: {
        name: "Twoje imię",
        phone: "+380 99 999-99-99",
        select: {
          one: "Wybierz, jak możesz pomóc",
          two: "Pieniądze",
          three: "Wolontariusz",
          four: "Specjalista",
          five: "Nasza pomoc",
        },
        mass: "Twoja wiadomość",
        error: "Wiadomość nie została wysłana! Proszę wypełnić wszystkie pola.",
        success: "Wiadomość wysłano poprawnie!",
        btn: "Wyślij wiadomość",
      },
      specialityContent: [
        {
          title: "Jako wolontariusz fundacji",
          desc: "Musisz być w stanie brać udział w projektach fundacji na stałe lub jednorazowo. Uczestnik musi mieć ukończone 18 lat.",
        },
        {
          title: "Jako specjalista",
          desc: "Jako lekarz pomagający chorym ludziom, jako psycholog lub inny specjalista.",
        },
        {
          title: "Jako bloger internetowy",
          desc: "Gotowy do pomocy w rozpowszechnianiu informacji w Internecie.",
        },
        {
          title: "Jako sklep partnerski",
          desc: "Prowadzenie promocji związanych z rzeczami i podstawowymi potrzebami.",
        },
        {
          title: "Dobrowolna pomoc pieniężna",
          desc: "Akceptujemy dobrowolne przelewy na rachunek karty Fundacji.",
        },
      ],
    },

    request: {
      title: "Zapytaj o pomoc",
      desc: "Napisz swoje kontakty, opisz szczegółowo sytuację:",
      info: {
        title: "Szczegółowe informacje uzyskasz kontaktując się z naszym specjalistą:",
        name: "Wiktoria Oleksandrivna Czernyszowa",
      },
    },

    transfer: {
      title: "Transfer pieniędzy",
      error: "Błąd",
      success: "Z powodzeniem",
      thank: "Dziękuję za wsparcie dzieci Ukrainy",
    },
  },

  about: {
    info: {
      title: "KTO, JEŚLI NIE MY Fundacja Charytatywna:",
      subtitle: "Jak to się wszystko zaczęło…",
      desc: {
        one: "Fundusz charytatywny został zarejestrowany w maju 2021 r., ale faktyczna praca na rzecz wsparcia Ukraińców trwa od 2020 r.",
        two: "Główny cel funduszu „Kto oprócz nas?” - pomoc dla dzieci. Do naszych zadań należy również wsparcie osób niepełnosprawnych i emerytów. Nasza fundacja wierzy, że hojność i hojność to najważniejsze cechy człowieka.",
        three:
          "Jesteśmy przekonani, że czyniąc dobro stajemy się silniejsi i szczęśliwsi, poprawiamy relacje, tworzymy lepszy świat. Każdy powinien mieć możliwość dzielenia się czasem, pieniędzmi lub energią. To niezawodny sposób na stworzenie harmonii między ludźmi.",
      },

      war: {
        title: "Wojna zmieniła każdego z nas",
        desc: {
          first:
            "Fundacja Who If Not Us nieprzerwanie pracuje 24 godziny na dobę, 7 dni w tygodniu, pomagając każdemu, kto jest w potrzebie.",
          second: {
            main: "Z Polski przysłano do nas odzież, żywność, środki higieny osobistej. Zachęcamy wszystkich do przyłączenia się do naszej walki z naszym wspólnym wrogiem i dołączenia do drużyny! Dziękuję wszystkim, którzy już wnieśli znaczący wkład! Razem jesteśmy w stanie tworzyć niesamowite rzeczy! Razem idziemy do zwycięstwa! Chwała Ukrainie!",
            sub: "Zwycięstwo będzie nasze!",
          },
        },
      },
    },

    what: {
      title: "Co robimy",
      item: {
        one: "Pomagamy osobom niepełnosprawnym",
        two: "Pomagamy sierotom",
        three: "Pomagamy emerytom",
        four: "Pomagamy dzieciom poszkodowanym podczas konfliktu zbrojnego na Ukrainie",
      },
    },

    history: {
      title: "Nasza historia",
      desc: {
        fond: "Fundacja Charytatywna „KTO JEŻELI NIE MY” powstała wyłącznie w celu czynienia dobra ludziom. Staramy się działać w różnych kierunkach:",
        believ: "Nasza fundacja wierzy, że życzliwość i hojność to główne cechy ludzkie, które oddzielają nas od zwierząt.",
        generate:
          "Fundacja Charytatywna WHO IF NOT powstała, aby zrzeszać ludzi chętnych do pomocy. Staramy się, aby każdy, bez względu na stan majątkowy i wiek, miał możliwość pomocy dzieciom. Nasza organizacja pragnie, aby każde nieszczęśliwe dziecko miało szczęśliwe i spokojne dzieciństwo.",
      },

      list: {
        money: "Wsparcie finansowe i psychologiczne dla osób w sytuacjach krytycznych;",
        therapy: "Leczenie ciężko chorych dzieci;",
        moral: "Wsparcie dla dzieci dotkniętych konfliktem zbrojnym na Ukrainie;",
      },
    },
  },

  news: {
    btn: "Czytaj więcej",
    report: "Fotoreportaż",
    thanks: "Listy z podziękowaniami",
    page: {
      latest: "Najnowsze wiadomości",
      help: {
        title: "Pomoc",
        desc: "Bądź wolontariuszem lub pomóż jako specjalista. Naciśnij przycisk!",
        btn: "Poświęcenie",
      },
    },
  },

  nft: {
    title: "Galeria",
    btn: "Kupić",
    price: "Cena £",

    block: {
      title: {
        main: "Zespół stworzył NFT naszego funduszu, pieniądze ze sprzedaży trafiają na pomoc Siłom Zbrojnym Ukrainy",
        sub: "i bezdomni.",
      },
    },

    info: {
      title: {
        main: "Luty 2022 na Ukrainę",
        sub: "nadeszła wojna.",
      },

      desc: {
        war: "Wojna rozpoczęta przez Rosję. Wszyscy obywatele naszego kraju są zjednoczeni w jednej całości – w stalowej tarczy w walce i konfrontacji kraju, który rozbił już miliony zwykłych Ukraińców.",
        team: "Zespół „Kto, jeśli nie my”, podobnie jak tysiące innych organizacji charytatywnych, od pierwszego dnia wojny bierze udział w losach i pomocy naszemu krajowi i naszym obywatelom.",
        friend: "Drodzy przyjaciele i filantropi, czekamy na wszelką pomoc i wsparcie, jakie może zapewnić Wasza organizacja.",
      },
      together: "W końcu razem jesteśmy Ukrainą",
    },

    nftContent: [
      {
        img: "4",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Bohaterskie miasta Ukrainy",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  contact: {
    title: "Informacje kontaktowe",
    address: {
      title: "Adres",
      desc: "Dniepr, ulica Korolenka, 8, biuro 10",
    },
    mail: {
      title: "Poczta",
    },
    phone: {
      title: "Telefon",
    },
    work: {
      title: "Godziny pracy",
      desc: "Od poniedziałku do piątku: 10:00 do 17:00",
    },
  },

  report: {
    donat: {
      title: "Będziemy wdzięczni za pomoc",

      switch: {
        one: "Jeden raz",
        monthly: "Miesięczny",
      },

      other: "Kolejna kwota",
      btn: "Poświęcenie",
      btnCrypto: "Przekaż darowiznę w kryptowalucie",

      modal: {
        title: "Kryptowaluty",
        btc: "Portfel darowizn BTC",
        usdt: "Portfel darowizn USDT TRC-20",
      },
    },

    statistic: {
      title: "Nasz raport",

      items: {
        one: "Nieustannie niesiemy pomoc i wspieramy dzieci, które jej potrzebują.",
        two: "Współpracujemy z gwiazdorskimi partnerami, którzy wspierają nasze działania.",
        three: "Organizujemy wydarzenia społeczne, aby wesprzeć każde dziecko, które ucierpiało w wyniku wojny.",
      },

      data: {
        item1: {
          title: "Flash mob dla dzieci w Dnieprze",
          desc: "⏳Nigdy nie będzie wygodnego momentu w życiu <br/> Mój zespół i ja chcieliśmy czegoś, co mogłoby odciążyć rodziców i zainteresować dzieci💃 <br/> Rozwiązanie zostało wydrukowane w 5 minut - FLASHMOB🤩 <br/><br/> Oto jesteśmy i śmialiśmy się i płakaliśmy. Przygotowywaliśmy się zamiast wychodzić ze znajomymi. Nie udało nam się wiele, ale najważniejszym rezultatem było danie dzieciom możliwości oddychania życiem, jakie miały przed wojną. Dając śmiech i radość, dając matkom beztroskę i możliwość tańca na równi z dziećmi <br/><br/> Nie dążymy do ideału, staramy się żyć tu i teraz I dajemy szczęście i dobroć dzisiaj🎉 <br /><br/> Dziękujemy naszym wspaniałym mamom, które były z nami tym razem<br/><br/> I wielkie podziękowania dla całego zespołu❤️<br/> Obserwuj nas na Instagramie @hto_yaksho_ne_my"
        },
        item2: {
          title: "Samochody dla Sił Zbrojnych Ukrainy",
          desc: "KUPILIŚMY SAMOCHÓD DLA AFU🔥<br/><br/>Kupiliśmy karetkę, zabraliśmy się za jej naprawę, malowanie i wyposażanie…<br/>I teraz - jesteśmy gotowi zaprezentować Wam efekt 🔥<br/ ><br/>To jest samochód, który trafi prosto do jednostki szturmowej w Bakhmut❗️Wszyscy rozumiemy, że samochody, podobnie jak każdy inny sprzęt na froncie, są materiałami eksploatacyjnymi🤷🏻‍♀️<br/><br/>Ale to jest trudno, a nawet prawie niemożliwa jest walka bez niej ❌ <br/><br/>Mamy jednak nadzieję, że maszyna ta będzie służyć naszym siłom zbrojnym jak najdłużej i jak najlepiej."
        },
        item3: {
          title: "Авто для військових",
          desc: "🔥15 SAMOCHODÓW DO BACHMUTU<br/><br/>Wspólnie z restauracją Pastoral i dobroczyńcami Dniepru kupiliśmy 15 samochodów i dużą partię amunicji dla naszego personelu wojskowego stacjonującego w kierunku Bachmutu<br/><br/>My Jesteśmy wdzięczni wszystkim 🙏🏻, którzy przyłączyli się do tej wspaniałej sprawy 🫶🏻<br/><br/>Jesteśmy wdzięczni naszym Siłom Zbrojnym i wzywamy Was do przekazywania darowizn, pomocy i wiary w nasze zwycięstwo"
        },
        item4: {
          title: "Rok wojny",
          desc: "Co on oznaczał dla naszej fundacji i ośrodka dla dzieci? <br/><br/> ••• Rozpoczęliśmy naszą pracę nie znając nikogo, nie mając żadnego doświadczenia, mieliśmy tylko silne pragnienie bycia użytecznym <br/><br/> 📎Naszych wolontariuszy poznaliśmy zupełnie przypadkiem , po prostu zwrócili się do nas o pomoc i wykazali się inicjatywą! Chcieli pracować z nami i pomagać tym samym ludziom, którzy cierpieli na wojnie. <br/>📎Od dłuższego czasu niesiemy pomoc do szpitala wojskowego/Szpitala Obwodowego im. Miecznikowa. <br/>📎Przez cały czas udzielaliśmy pomocy ponad 1500 rodzinom z dziećmi <br/>📎wspieraliśmy nasze wojsko: zakupiliśmy mundury, rękawice taktyczne, kamerę termowizyjną i 40 par butów dla kierunku donieckiego, które zakupiliśmy od @vadrus.ua <br/ >📎W tym roku regularnie otrzymywaliśmy pomoc od @opir.sambir2022 @polovkoandrey @revivalfund.ua @kolo_fund <br/>📎Restauracje „Akvarel” i „Soul Fish” zostały przygotowywanie jedzenia dla naszych obrońców i ludzi, którzy musieli opuścić swoje domy i zacząć nowe życie w Dnieprze. A my z kolei pomagaliśmy im w tym za pomocą produktów <br/>📎Ok Psychology Center @ok.psyspace od początku wojny zapewnia dzieciom i rodzicom wsparcie psychologiczne, a także udostępniło nam salę na warsztaty -zajęcia <br/>📎Zorganizowaliśmy wydarzenia z blogerami i naszymi partnerami <br/>📎Zorganizowaliśmy wspólny flash mob taneczny z rodzicami i dziećmi <br/>📎Otworzyliśmy kierunek „Trening baletowy” <br/>📎 I zapewnił pełne funkcjonowanie ośrodka dla dzieci i stałe udzielanie pomocy humanitarnej dzieciom i kobietom w ciąży. Ten rok był najtrudniejszy dla nas wszystkich... <br/><br/>Ale jest tylko jeden przepis dla zwycięstwa: być zjednoczonym, silnym, zrobić wszystko co możliwe dla zwycięstwa i być miłym tym, którzy tego potrzebują!"
        },
        item5: {
          title: "Zgłaszamy!",
          desc: "Zorganizowaliśmy święto 17.06 „Kraina Niezwyciężonych” przy wsparciu Dniepropietrowskiej Obwodowej Administracji Wojskowej 🫂<br/><br/>Święto dla dzieci przesiedleńców wewnętrznych z różnych części kraju 🫶🏻w lokalizacji Lavina @lavinapark <br/><br/>Dzięki wsparciu naszych przyjaciół gwiazd @annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila.eremeeva @viktoriya_tka4 i @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official < br/><br/>Nasze wojsko, które broni naszego kraju i daje możliwość organizowania świąt, otrzymało 28 samochodów i ponad 20 sztuk sprzętu 🚐"
        },
        item6: {
          title: "Samochody dla Sił Zbrojnych Ukrainy",
          desc: "Dzięki naszym darczyńcom, 17 czerwca my, fundacja charytatywna „Kto, jak nie my”, udało się zebrać 1 074 075 UAH🤩<br/><br/>I dzięki naszym wysiłkom udało się zakupić 35 szt. DJI Mavic 3T i przekazanie 05.07 Dniepropietrowskiej Państwowej Administracji Obwodowej, naszym obrońcom, którzy dają nam możliwość organizowania takich wydarzeń i przybliżania zwycięstwa<br/><br/>Jesteśmy wdzięczni wszystkim, którzy wzięli udział w wydarzeniu „Kraj Niezwyciężony<br/><br/ >Dziękuję @a.wellboy @annatrincher_official @demchukmusic @dianagloster @uliana_royce @mashaa.kondratenko @viktoriya_tka4 @yuriy_tkach"
        },
        item7: {
          title: "Pomoc humanitarna Odesaа",
          desc: "Nasza fundacja „Kto, jeśli nie my” odwiedziła miasto Odessa z pomocą humanitarną🥰<br/><br/>Ponad 110 zestawów zostało rozdystrybuowanych do rodzin przesiedlonych z dziećmi poniżej 3 roku życia🫶🏻<br/><br/>Dziękujemy Tobie, naszym partnerom @revivalfund.ua i @opir.sambir2022 🫂<br/><br/>Pomagamy i zbliżamy zwycięstwo razem"
        },
        item8: {
          title: "Pomóż Chersoniu",
          desc: "My - fundacja charytatywna „Kto jak nie my” odwiedziliśmy miasto Chersoń z pomocą humanitarną dla ofiar wybuchu elektrowni wodnej w Kachowce 🙏<br/><br/>Przekazano: <br/>• Odzież wierzchnia - 500 sztuk < br/>• Odzież męska - 300 sztuk <br/>• Odzież damska - 500 sztuk <br/>• Zabawki dziecięce - 7 pudełek <br/>• Podręczniki dla dzieci (ukraiński/angielski) - 3 pudełka <br/>• Wózki dziecięce /kołyski/krzesełka do karmienia - 6 szt. <br/>• Żywność dla niemowląt - 2 pudełka <br/>• Odżywka do włosów - 2 pudełka <br/>• Szczoteczki do zębów - 1 pudełko <br/>• Buty damskie/męskie - 50 par <br/ >• Koce - 30 sztuk <br/>• Antyseptyk - 20 litrów <br/>• Bielizna - 30 opakowań <br/>• Materac dziecięcy - 10 sztuk <br/>• Ubranka dziecięce - 50 pudełek <br/>• Ręcznik - 1 pudełko <br/>• Kapcie - 20 par <br/>• Tablica do rysowania - 1 sztuka <br/>• Poduszki/koce - 50 sztuk <br/>• Zestawy spożywcze (konserwy, masło, cukier, herbata, ciasteczka, makaron, papier toaletowy, mydło, gryka) - 380 zestawów <br/>• Stacja do ładowania - 10 sztuk <br/>• Power banki - 25 sztuk <br/>• Woda pitna gazowana - 1500 ton <br/>• Woda pitna niegazowana - 8500 ton <br/><br/>📍Nezlamnosti Point, miasto. Chersoń na podstawie: Chersoński Kompleks Edukacyjno-Kształcący 48 Pod adresem: miasto. Chersoń, ul. 28 Armii, 14<br/><br/>Kontynuujemy pomoc🙏 Wierzymy w Zwycięstwo"
        },
        item9: {
          title: "Turniej bilardowy",
          desc: "CHARYTATYWNY TURNIEJ BILARDOWY poświęcony DNIU NIEPODLEGŁOŚCI UKRAINY<br/><br/>Po raz pierwszy od wielu lat udało nam się zgromadzić najlepszych sportowców Ukrainy w Dnieprze‼️W zawodach bierze udział 32 sportowców z 11 regionów i 14 miast Ukrainy. udział w konkursie. Wszystkie datki charytatywne uczestników w 100% przekazane na pomoc naszym wojskom! Kwota wynosi 243 000 UAH 🔥<br/><br/>W finale turnieju wojsko otrzymało niezbędne przedmioty do ochrony naszego kraju !<br/><br/>Dziękujemy naszym obrońcom za możliwość rozegrania tego turnieju 💙💛"
        },
        item10: {
          title: "Stacja pompowa Chersoniu",
          desc: "My, fundacja charytatywna „Kto, jak nie my” odwiedziliśmy z pomocą wieś Kaliniwske, obwód chersoński!<br/><br/>Wieś była okupowana przez 8 miesięcy🐖.<br/><br/>Mieszkańcy którzy pozostali w swojej ojczystej ziemi Ziemia po prostu przetrwała, ponieważ wioska została całkowicie zniszczona 😥<br/><br/>Zabraliśmy STACJĘ POMPOWNICZĄ, aby ludzie mieli wodę🙏<br/><br/>Kontynuujemy pomoc ! Zbliżamy się do zwycięstwa, bo ono już niedługo nadejdzie😉"
        },
        item11: {
          title: "Dzień Miasta z lodami Lasunka",
          desc: "09.09, w Dniu Miasta Dniepr, wspólnie z „Lasunką” @lasunka_icecream rozdaliśmy lody wszystkim chętnym i zabawialiśmy dzieciaki 🌸<br/><br/>Zobaczmy razem, jak było"
        },
        item12: {
          title: "Wakacje „KIERUJĄC SIĘ DO ZWYCIĘSTWA”",
          desc: "Wydarzenie sportowe dla 500 dzieci, zorganizowane przy wsparciu administracji regionalnej, aby nasze dzieci szły tylko do przodu, nie zapominając o swoich celach i osiągnięciach, pomimo wojny 🙏<br/><br/>Jesteśmy nieskończenie wdzięczni @ za zorganizowanie flash moba _oleksandrleshchenko <br/><br/>Światowej klasy sportowcy🏅 i gwiazdy takie jak @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official <br/><br/>Dziękuję do wszystkich, którzy wzięli udział w tym wydarzeniu, <br/>Mianowicie do organizatorów, bez których nasze wydarzenie nie mogłoby się odbyć:<br/>@viktoriya_tka4 <br/>@deniskonovalov <br/>@m_galat <br/>@vladimir_drachuk <br/>@franz_audio <br />Jesteś najlepszy 🫂"
        },
        item13: {
          title: "Maszyny dla Sił Zbrojnych Ukrainy",
          desc: "Nasza fundacja charytatywna „Kto, jak nie my” nieustannie wspiera obrońców w najgorętszych punktach frontu!<br/><br/>Niedawno przekazaliśmy 13 samochodów i trzy karetki wspólnie z klubem sportowym @vataga_dp i administracją wojskową! <br/> <br/>Te środki zostały zebrane podczas charytatywnego turnieju MMA! 🥊<br/><br/>Dzięki wyjątkowej loterii udało nam się zebrać ponad 3 000 000 hrywien, którą przeprowadziła nasza niezrównani gospodarze @mila.eremeeva @yuriy_tkach <br/> <br/>Wydarzenie odbyło się w tym trudnym czasie przy wsparciu szefa administracji obwodowej Dniepropietrowska! Jesteśmy bardzo wdzięczni wszystkim, którzy dołączyli do nas i pomagają naszym żołnierzom! !!<br/><br/>Razem dla zwycięstwa!"
        },
        item14: {
          title: "Wszechkrajowy Turniej Szachowy ♟️",
          desc: "22 października odbył się turniej szachowy „Strategia niezniszczalnego”🙌🏻<br/><br/>W turnieju wzięło udział ponad 350 osób, w tym 125 dzieci☺️<br/><br/>Uczestnicy otrzymali upominki od naszych fundacja<br/><br/ >🎁Wspieramy sport na Ukrainie<br/><br/>Tworzymy przyszłość, Chwała Ukrainie 🫂"
        },
        item15: {
          title: "Turniej ku pamięci Mistrza Sportu Janczenki O.O.",
          desc: "W dniach 23-26 listopada w mieście Kamieńskie odbył się turniej poświęcony pamięci Mistrza Sportu O.O. Janczenki. <br/><br/>W turnieju wzięło udział 1️⃣3️⃣9️⃣ uczestników z 7️⃣ regionów Ukrainy. Niezwykle emocjonujące i zacięte mecze, w których zwycięzcy i laureaci wraz ze swoimi trenerami otrzymali nagrody indywidualne i pieniężne. <br/><br/>🏆 Najlepsi bokserzy otrzymali niezapomniane nagrody za swoje umiejętności<br/><br/>👏🏼 W ceremonii wręczenia nagród wzięli udział znamienici goście, w tym:<br/>- Wiceprezes ds. Boksu Federacja Ukrainy, Mistrz sportu Giennadij Tkaczenko;<br/>- wiceprezes Federacji Boksu Ukrainy, mistrz sportu klasy międzynarodowej, mistrz świata Dżamal Miedżidow;<br/>- prezes SC „WATAGA” Igor Chromow ;<br/>- członek rady nadzorczej Ukraińskiej Federacji Boksu Iwan Gilewicz.<br/><br/>Wspierajmy wspólnie sport na Ukrainie"
        },
        item16: {
          title: "Przekazano 13 samochodów i trzy karetki",
          desc: "Nasza fundacja charytatywna „Kto, jak nie my” nieustannie wspiera obrońców w najgorętszych punktach frontu!<br/><br/>Niedawno przekazaliśmy 13 samochodów i trzy łodzie motorowe klubowi sportowemu @vataga_dp i administracji wojskowej! <br/><br />Te środki zostały zebrane podczas charytatywnego turnieju MMA! 🥊<br/><br/>Dzięki wyjątkowej loterii udało nam się zebrać ponad 3 000 000 hrywien, którą przeprowadziła nasza niezrównani gospodarze @mila.eremeeva @yuriy_tkach <br/><br />Wydarzenie odbyło się w tym trudnym czasie przy wsparciu szefa administracji obwodowej Dniepropietrowska!<br/>Jesteśmy bardzo wdzięczni wszystkim, którzy dołączyli do nas i pomaga naszym żołnierzom!!!<br/><br/>Razem dla zwycięstwa!"
        },
        item17: {
          title: "OTWARCIE CENTRUM DZIECIĘCEGO",
          desc: "Długo oczekiwane wydarzenie już się odbyło 👏🏻<br/><br/>19 kwietnia otworzyliśmy drzwi naszej przestrzeni dziecięcej dla naszych maluchów 🙌🏻<br/><br/>Teraz będziemy rysować, uczyć się , uprawiać wspólnie sport, a wszystko to dzięki troskliwym dobroczyńcom miasta Dniepr!<br/><br/>Dziękujemy! Serdecznie dziękujemy wszystkim, którzy pomogli nam stworzyć tę przestrzeń 🙏<br/>Dziękujemy za prezenty @bontoy_job ❤️<br/><br/>Tworzymy dobro i korzyści dla naszych dzieci, aby zapewnić im szczęśliwe dzieciństwo pomimo wszystko!"
        },
        item18: {
          title: "OBCHODY DNIA OCHRONY DZIECI",
          desc: "Wydarzenie, które zgromadziło ponad 2000 dzieci🙌🏻<br/><br/>Strefy gastronomiczne, animacje, malowanie twarzy i wiele więcej dla naszych dzieci 🫶🏻<br/><br/>Główni goście: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @amadorlopez_rumberos @nadyadorofeeva <br/><br/>Szczere podziękowania dla wszystkich, którzy wzięli udział ☺️ <br/><br/>Robimy wszystko, aby zapewnić małym dzieciom szczęśliwe dzieciństwo 😘"
        },
        item19: {
          title: "Ewakuacja mieszkańców",
          desc: "Ewakuowano mieszkańców następujących miejscowości:<br/><br/>Kurachowo <br/>Selidowo<br/>ukraińska<br/>Pokrovsk <br/><br/>Ewakuowano ponad 200 mieszkańców<br/ >Dziękujemy wszystkim, którym zależy i którzy dołączyli do nas!<br/><br/>Razem wygramy!"
        },
        item20: {
          title: "Koncert charytatywny z okazji Dnia Obrońców",
          desc: "SZCZERZE WDZIĘCZNI 🙏 <br/><br/>@retroville_kyiv i @musicboxua za zorganizowanie koncertu charytatywnego z okazji Dnia Obrońców<br/><br/>Wszystkie wpłaty zostały przekazane na nasz fundusz, nadal pomagamy rodzinom w potrzebie i z pewnością wojsko na froncie 🫡 <br/><br/>Wdzięczni za wsparcie naszym gościom-gwiazdom — @roxolanas @ado.khayat @nicolas_karma_official @_kristonko i @ruslana.lyzhychko<br/><br/>Obecność Każdy gość i widz jest dla nas niezwykle ważny! Dziękuję! Chwała Ukrainie 🙌🏻"
        },
        item21: {
          title: "POMOC W ZAKRESIE SCHRONIENIA",
          desc: "Zapewniliśmy pomoc rodzinom ewakuowanym z miejsc objętych kryzysem w naszym kraju ✨<br/><br/>Pomogliśmy 46 rodzinom, w tym 80 dzieciom poniżej 1 roku życia 👶🏼<br/><br/>Zapewniliśmy:<br/> • zestawy żywnościowe< br/>• artykuły papiernicze dla dzieci<br/>• słodycze 🍭 <br/><br/>POMAGAMY! RAZEM KU ZWYCIĘSTWU!"
        },
        item22: {
          title: "Dzień Świętego Mikołaja",
          desc: "Odwiedzanie naszych małych przyjaciół stało się już dla nas dobrą tradycją🫂<br/><br/>I tym razem mieliśmy także specjalną okazję - Mikołajki🎄<br/><br/>Więc razem z naszą ukochaną @yuriy_tkach i @mila.eremeeva postanowiliśmy zaprosić niesamowitą @kalush.official, żeby do nas dołączyła i nie odmówiła 🫶 I było wspaniale! Prawdziwy koncert świąteczny dla naszych dzieci🥳🙌<br/><br/>Wszyscy wyszli ze spotkania z prezentami, uśmiechami, życzeniami dla siebie nawzajem i obietnicami, że wkrótce się spotkamy❤️‍🩹<br/><br/>Dziękuję @ dniprooda za wsparcie"
        },
        item23: {
          title: "Odwiedziliśmy jedną ze szkół w mieście Dniepr",
          desc: "Odwiedziliśmy jedną ze szkół w mieście Dniepr 🙏<br/><br/>KZO \"Multidyscyplinarne Centrum Edukacyjno-Rehabilitacyjne Nr 6 \"DOR\"<br/><br/>Składamy najmłodszym dzieciom życzenia z okazji ich święta Mikołaj 🎅🏻<br/>A jako prezent, dzieciaki przygotowały dla nas niesamowicie interesujący koncert 😍<br/><br/>Jesteśmy dumni z tak utalentowanych, miłych i szczerych dzieciaków 🥰<br/>< br/>Wesołych Świąt 🎉"
        },
        item24: {
          title: "Dzień Świętego Mikołaja",
          desc: "Co roku na początku grudnia zbieramy się jako duży zespół, aby spędzić razem kilka dni i dać urlop tym, którzy na to zasługują🫂<br/><br/>Dzieci z terenów tymczasowo okupowanych, dzieci naszych żołnierzy, nasi ratownicy i lekarze - wszyscy zostaliśmy zaproszeni, aby spędzić z nami Mikołajki i staraliśmy się, aby ten dzień był dla nich niezapomniany ❤️‍🔥<br/><br/>Tego dnia Teatr Opery i Baletu przygotował dla nich słodkości, animacje , malowanie twarzy, kreatywne zajęcia mistrzowskie i prezenty 🎁 Wspaniale obejrzeliśmy występ „Królewna Śnieżka i 7 krasnoludków”✨<br/><br/>Jesteśmy szczerze wdzięczni zespołowi @kazka.band za udział i wsparcie naszego święta, dzieci były zachwycone , to prawdziwy prezent dla nas wszystkich🫶<br/><br/> @yuriy_tkach , @mila.eremeeva - nasze serca są z Wami❤️‍🔥 <br/>Nie sposób wyobrazić sobie naszych świąt bez Was! Dziękujemy Wam, Kochani, za Wasze wsparcie. Już od dawna jesteście głównymi twarzami i ambasadorami naszej fundacji. Jesteśmy dumni i doceniamy Was."
        },
        item25: {
          title: "Dnieprowski Obwodowy Dziecięcy Szpital Kliniczny",
          desc: "W ten weekend odwiedziliśmy Dnieprowski Dziecięcy Szpital Kliniczny, w którym leczone są dzieci w różnym wieku, od niemowląt po nastolatków❤️‍🩹<br/><br/>Byli z nami nasi krewni @yuriy_tkach i @mila.eremeeva, więc święta były odniosło sukces! Mówią, że to prawda - uśmiech i dobry humor to klucz do szybkiego powrotu do zdrowia 🫂<br/><br/>A punktem kulminacyjnym były improwizowane występy naszych przyjaciół z zespołu @kazka.band ✨Saszę, dzieciaki uwielbiają Wy i my ich rozumiemy🤍<br/><br />Jesteśmy bardzo szczęśliwi, że możemy złożyć naszym maluchom życzenia świąteczne🎅🏻 Wracajcie szybko do zdrowia, nasze króliczki!🫶"
        },
      },

      btnMore: "Czytaj więcej",
      btnHide: "Ukryj",
    },
  },

  footer: {
    desc: {
      first: "Bądź wolontariuszem lub pomóż jako specjalista.",
      second: "Naciśnij przycisk!",
    },
    btn: "chcę być użyteczny",
    list: {
      title: "Przydatne linki",
      about: "O naszym funduszu",
      news: "Aktualności",
      contact: "Łączność",
    },
    info: {
      title: "Dodatkowe informacje",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'w UAB "ALFA-BANK"',
      four: "MIF 300346",
      five: "USREOU 23494714",
    },
  },
};
