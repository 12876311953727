export const TRANSLATIONS_PT = {
  navBar: {
    site: "O nosso sítio web",
    btnHelp: "COMO VOCÊ PODE AJUDAR",
    btnRequ: "PEDIR AJUDA",

    link: {
      home: "Casa",
      center: "Relatório",
      about: "Sobre nosso fundo",
      news: "Notícia",
      nft: "Galeria NFT",
      contact: "Contatos",
    },

    lang: {
      title: "Linguagem",
      "en-US": "inglês",
      "ua-UA": "ucraniano",
      "es-ES": "espanhol",
      "fr-FR": "francês",
      "de-DE": "alemão",
      "it-IT": "italiano",
      "pl-PL": "polonês",
      "pt-PT": "português",
    },
  },

  pageBanner: {
    home: "Casa",
    about: "Sobre nosso fundo",
    news: "Notícia",
    nft: "Galeria NFT",
    contact: "Contatos",
    report: "Relatório",
  },

  copy: {
    copy: "cópia de",
    success: "Copiado",
  },

  home: {
    banner: {
      title: {
        first: "FUNDAÇÃO DE CARIDADE",
        second: '"QUEM, SE NÃO NÓS"',
      },
      desc: 'Nosso fundo "QUEM, SE NÃO NÓS" é totalmente responsável pela utilização dos recursos arrecadados, cada contribuição terá uma finalidade direta. O principal para nós é a sua confiança!',
      btnHelp: "Como você pode ajudar",
      btnRequ: "Peça por ajuda",
      btnDonat: "Sacrifício",
    },

    company: {
      title: "Fundação Beneficente Quem Se Não Nós",
      desc: "As crianças são o nosso futuro. Juntos, podemos superar tudo e tornar nosso futuro saudável, feliz e com fé em nosso país. A Who If Not Us Charitable Foundation organiza angariação de fundos para ajudar crianças e suas famílias necessitadas. Assistência a crianças gravemente doentes, nomeadamente pagamento de tratamento e período de reabilitação.",
      descMil: "Aquisição contínua de munições necessárias para as Forças Armadas da Ucrânia.",
      mission: {
        title: "A missão da fundação",
        desc: "Dê o máximo possível a alegria da vida às crianças e forneça apoio psicológico aos pais que precisam agora.",
      },
      purpose: {
        title: "A finalidade do fundo",
        desc: "Organização de atividades infantis que afetam o desenvolvimento infantil. Apoio psicológico para crianças e seus pais. Apoio financeiro e humanitário às vítimas do conflito armado na Ucrânia.",
      },
      military: {
        title: "Apoio militar",
        desc: "Fornecendo munições e carros aos defensores nos pontos mais quentes da Ucrânia.",
      },
      widget: {
        title: {
          first: "Juntos - vamos mudar o mundo!",
          second: "Comece conosco agora!",
        },
      },
      work: {
        disability: "Ajudamos pessoas com deficiência",
        retirees: "Ajudamos aposentados",
        orphans: "Ajudamos os órfãos",
        difficult: "Ajudamos as crianças afetadas durante o conflito armado na Ucrânia",
        military: "Provisão das Forças Armadas",
      },
    },

    fees: {
      title: "Quando não há esperança, não há força e não há dinheiro - podemos estar lá para si!",
      feesContent: [
        {
          title: "650 000 UAH.",
          desc: "criado para ajudar as crianças",
        },
        {
          title: "93 500 UAH.",
          desc: "gasto no programa 'Cura com Alegria'",
        },
        {
          title: "196 800 UAH.",
          desc: "gasto no programa Salvar Vidas em Conjunto",
        },
        {
          title: "342 000 UAH.",
          desc: "gasto no programa Salvar Vidas em Conjunto",
        },
      ],
    },

    our_help: {
      title: "Nossa ajuda",
      desc: "O restaurante Soul Fish abriu um quartel-general de ajuda durante a guerra. Ajudam com alimentos para as Forças Armadas, a polícia e os deslocados refugiados “Há 41 dias, cada um de nós está ajudando como pode. Tudo isso é energia forte e invencível, nos uniu em um todo. E graças a isso estamos nos movendo.",
      btn: "Ajuda",
    },

    donat: {
      title: "Assistência a crianças feridas durante a guerra no território da Ucrânia",
      btn: "Faça o seu donativo",
      btc: {
        title: "BTC",
        modal: {
          header: "BTC",
          title: "Carteira para doações BTC",
        },
      },
      usdt: {
        title: "USDT",
        modal: {
          header: "USDT TRC-20",
          title: "Carteira para doações USDT TRC-20",
        },
      },
      visa: {
        title: "Visa/MasterCard",
      },
      p2p: {
        title: "Р/Р",
        modal: {
          header: "Conta corrente",
        },
      },
    },

    help: {
      title: "Como podemos ajudar?",
      desc: "Talvez tenha uma forma de ajudar uma determinada família. Por favor, escreva-nos e entraremos em contacto consigo.",
      form: {
        name: "Seu nome",
        phone: "+380 99 999-99-99",
        select: {
          one: "Escolha como pode ajudar",
          two: "Dinheiro",
          three: "Voluntário",
          four: "Especialista",
          five: "A nossa ajuda",
        },
        mass: "Sua mensagem",
        error: "Mensagem não enviada! Por favor preencha todos os campos.",
        success: "Mensagem enviada com sucesso!",
        btn: "Enviar mensagem",
      },
      specialityContent: [
        {
          title: "Como voluntário da fundação",
          desc: "Participar dos projetos do fundo de forma permanente ou única. Você deve ter mais de 18 anos.",
        },
        {
          title: "Como especialista",
          desc: "Como médico para ajudar pacientes, como psicólogo ou outro especialista.",
        },
        {
          title: "Como blogueiro da internet",
          desc: "Pronto para ajudar na divulgação de informações na Internet.",
        },
        {
          title: "Como loja parceira",
          desc: "Realização de promoções relacionadas a coisas e necessidades básicas.",
        },
        {
          title: "Ajuda voluntária com dinheiro",
          desc: "Aceitamos transferências voluntárias para a conta do cartão da Fundação.",
        },
      ],
    },

    request: {
      title: "Peça por ajuda",
      desc: "Escreva seus contatos, descreva a situação em detalhes:",
      info: {
        title: "Você pode obter informações detalhadas entrando em contato com nosso especialista:",
        name: "Victoria Oleksandrivna Chernyshova",
      },
    },

    transfer: {
      title: "Transferência de dinheiro",
      error: "Erro",
      success: "Com sucesso",
      thank: "Obrigado pelo apoio das crianças da Ucrânia",
    },
  },

  about: {
    info: {
      title: "QUEM, SE NÃO NÓS Fundação de Caridade",
      subtitle: "Como tudo começou…",
      desc: {
        one: "O fundo de caridade foi registrado em maio de 2021, mas o trabalho real de apoio aos ucranianos está em andamento desde 2020.",
        two: 'O principal objetivo do fundo "Quem senão nós?" - ajuda para crianças. Nossas tarefas também incluem o apoio a pessoas com deficiência e aposentados. Nossa fundação acredita que generosidade e generosidade são as qualidades humanas mais importantes.',
        three:
          "Estamos convencidos de que fazendo o bem nos tornamos mais fortes e felizes, melhoramos relacionamentos, criamos um mundo melhor. Todos devem poder compartilhar tempo, dinheiro ou energia. Esta é uma maneira confiável de criar harmonia entre as pessoas.",
      },

      war: {
        title: "A guerra mudou cada um de nós",
        desc: {
          first:
            "A Who If Not Us Foundation continua trabalhando 24 horas por dia, 7 dias por semana, para ajudar quem precisa.",
          second: {
            main: "Roupas, alimentos, produtos de higiene pessoal nos foram enviados da Polônia. Incentivamos todos a se juntarem à nossa luta contra nosso inimigo comum e se juntarem ao time! Obrigado a todos que já deram uma contribuição significativa! Juntos somos capazes de criar coisas incríveis! Juntos estamos marchando para a vitória! Glória à Ucrânia!",
            sub: "A vitória será nossa!",
          },
        },
      },
    },

    what: {
      title: "O que nós fazemos",
      item: {
        one: "Ajudamos pessoas com deficiência",
        two: "Ajudamos os órfãos",
        three: "Ajudamos aposentados",
        four: "Ajudamos as crianças afetadas durante o conflito armado na Ucrânia",
      },
    },

    history: {
      title: "Nossa história",
      desc: {
        fond: "A WHO IF NOT WE Charitable Foundation foi criada com o único propósito de fazer o bem às pessoas. Tentamos trabalhar em diferentes direções:",
        believ:
          "Nossa fundação acredita que bondade e generosidade são as principais qualidades humanas que nos separam dos animais.",
        generate:
          "A WHO IF NOT Charitable Foundation foi criada para reunir pessoas dispostas a ajudar. Tentamos garantir que todos, independentemente da situação financeira e da idade, tenham a oportunidade de ajudar as crianças. Nossa organização quer que cada criança infeliz tenha uma infância feliz e pacífica.",
      },

      list: {
        money: "Apoio financeiro e psicológico para pessoas em situações críticas;",
        therapy: "Tratamento de crianças gravemente doentes;",
        moral: "Apoio às crianças afetadas pelo conflito armado na Ucrânia;",
      },
    },
  },

  news: {
    btn: "Consulte Mais informação",
    report: "Foto reportagem",
    thanks: "Cartas de agradecimento",
    page: {
      latest: "Últimas notícias",
      help: {
        title: "Ajuda",
        desc: "Seja um voluntário ou ajude como um especialista. Aperte o botão!",
        btn: "Sacrifício",
      },
    },
  },

  nft: {
    title: "Galeria",
    btn: "Comprar",
    price: "Preço",

    block: {
      title: {
        main: "A equipe criou o NFT do nosso fundo, o dinheiro da venda vai para ajudar as Forças Armadas da Ucrânia",
        sub: "e moradores de rua.",
      },
    },

    info: {
      title: {
        main: "Fevereiro 2022 para a Ucrânia",
        sub: "a guerra veio.",
      },

      desc: {
        war: "A guerra começou pela Rússia. Todos os cidadãos do nosso país estão unidos em um todo - em um escudo de aço na luta e confronto de um país que já quebrou milhões de ucranianos comuns.",
        team: 'A equipe "Quem, se não nós", como milhares de outras instituições de caridade, desde o primeiro dia da guerra toma todas as partes possíveis no destino e assistência ao nosso país e nossos cidadãos.',
        friend: "Caros amigos e filantropos, estamos ansiosos por qualquer ajuda e apoio que sua organização possa fornecer.",
      },
      together: "Afinal, juntos somos a Ucrânia",
    },

    nftContent: [
      {
        img: "4",
        cat: "Cidades heróicas da Ucrânia",
        title: "Irpen",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304559263746621540",
      },
      {
        img: "1",
        cat: "Cidades heróicas da Ucrânia",
        title: "Bucha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304558164234993764",
      },
      {
        img: "7",
        cat: "Cidades heróicas da Ucrânia",
        title: "Okhtirka",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304557064723365988",
      },
      {
        img: "10",
        cat: "Cidades heróicas da Ucrânia",
        title: "Chernihiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304553766188482660",
      },
      {
        img: "6",
        cat: "Cidades heróicas da Ucrânia",
        title: "Mykolayiv",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304552666676854884",
      },
      {
        img: "2",
        cat: "Cidades heróicas da Ucrânia",
        title: "Volnovakha",
        price: "0,14",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304551567165227108",
      },
      {
        img: "9",
        cat: "Cidades heróicas da Ucrânia",
        title: "Kherson",
        price: "0,1",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304550467653599332",
      },
      {
        img: "3",
        cat: "Cidades heróicas da Ucrânia",
        title: "Gostomel",
        price: "0,068",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304548268630343780",
      },
      {
        img: "8",
        cat: "Cidades heróicas da Ucrânia",
        title: "Kharkiv",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304547169118716004",
      },
      {
        img: "5",
        cat: "Cidades heróicas da Ucrânia",
        title: "Mariupol",
        price: "0,17",
        link: "https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/95964194932533880224009272647516556414967480601747153551965304546069607088228",
      },
    ],
  },

  contact: {
    title: "Informações de contato",
    address: {
      title: "Endereço",
      desc: "Dnipro, rua Korolenka, 8, escritório 10",
    },
    mail: {
      title: "Correspondência",
    },
    phone: {
      title: "Telefone",
    },
    work: {
      title: "Jornada de trabalho",
      desc: "Segunda a Sexta: 10h00 às 17h00",
    },
  },

  report: {
    banner: {
      subtitle: "Nosso centro infantil",
      title: "\"Quem, senão nós\" crie um conto de fadas incrivelmente criativo para crianças na forma de master classes",
      btn: "Mais detalhes",
    },

    donat: {
      title: "Ficaremos gratos por sua ajuda",

      switch: {
        one: "Um tempo",
        monthly: "Por mês",
      },

      other: "Outra quantia",
      btn: "Sacrifício",
      btnCrypto: "Doe em criptomoeda",

      modal: {
        title: "Criptomoeda",
        btc: "Carteira de doação BTC",
        usdt: "Carteira de doação USDT TRC-20",
      },
    },

    statistic: {
      title: "O nosso relatório",

      items: {
        one: "Constantemente damos ajuda e apoiamos as crianças que precisam.",
        two: "Cooperamos com parceiros famosos que apoiam nossas atividades.",
        three: "Organizamos eventos sociais para apoiar todas as crianças que sofreram com a guerra.",
      },

      data: {
        item1: {
          title: "Flash mob para crianças em Dnipro",
          desc: "⏳Nunca haverá um momento conveniente na vida <br/> A minha equipa e eu queríamos algo que aliviasse a carga de trabalho dos pais e mantivesse as crianças interessadas💃 <br/> A solução foi impressa em 5 minutos - Flashmob🤩 <br/><br/>É aqui que nos rimos e choramos Preparar em vez de sair com os amigos<br/> Falhámos em muitas coisas, mas o resultado mais importante foi dar às crianças a oportunidade de respirar a vida que tinham antes da guerra Para dar riso e alegria, para dar às mães oportunidades iguais e despreocupadas com os seus filhos  <br/><br/> Não lutamos por um ideal, esforçamo-nos por viver aqui e agora E dar felicidade e bondade hoje🎉 <br/><br/> Agradecemos às nossas incríveis mães por estarem connosco durante este tempo<br/><br/> E muito obrigado a toda a equipa❤️<br/> Siga-nos no Instagram @hto_yaksho_ne_my"
        },
        item2: {
          title: "Automóveis para as Forças Armadas da Ucrânia",
          desc: "COMPRÁMOS UM CARRO PARA O EXÉRCITO🔥<br/><br/>Comprámos uma ambulância, reparámo-la, pintámo-la e equipámo-la...<br/>E agora estamos prontos para vos apresentar o resultado 🔥<br/><br/>Este veículo irá diretamente para a unidade de assalto em Bakhmut❗️Todos nós sabemos que os veículos, como qualquer outro equipamento na linha da frente, são consumíveis🤷🏻‍♀️<br/><br/>Mas é difícil e mesmo quase impossível lutar sem ele ❌<br/><br/>No entanto, continuamos a esperar que este veículo sirva os nossos militares durante tanto tempo e tão bem quanto possível"
        },
        item3: {
          title: "Carros para os militares",
          desc: "🔥15 CARROS PARA BAKHMUT<br/><br/>Juntamente com o restaurante Pastoral e os filantropos de Dnipro, comprámos 15 veículos e um grande lote de munições para os nossos militares que se encontram na direção de Bakhmut<br/><br/>Estamos gratos a todos 🙏🏻 que se juntou a esta causa fixe 🫶🏻<br/><br/>Estamos gratos às nossas Forças Armadas e apelamos a que façam um donativo, ajudem e acreditem na nossa vitória"
        },
        item4: {
          title: "Um ano de guerra",
          desc: "O que é que isso significou para a nossa fundação e para o centro infantil? <br/><br/> ••• Começámos o nosso trabalho sem conhecer ninguém, sem experiência, tudo o que tínhamos era um forte desejo de sermos úteis <br/><br/> 📎Encontrámos os nossos voluntários por acaso, eles vieram pedir-nos ajuda e tomaram a iniciativa! Queriam trabalhar connosco e ajudar as mesmas pessoas que sofreram com a guerra. <br/>📎Há muito tempo que levamos ajuda ao hospital militar/hospital regional de Technikov. <br/>📎Ao longo dos anos, prestámos assistência a mais de 1500 famílias com crianças <br/>📎Apoio às nossas forças armadas: compra de uniformes, luvas tácticas, termovisores e 40 pares de sapatos para o sector de Donetsk, que foram adquiridos a @vadrus.ua <br/>📎Durante este ano, recebemos regularmente assistência de @opir.sambir2022 @polovkoandrey @revivalfund.ua @kolo_fund <br/>📎Desde os primeiros dias da guerra, os restaurantes Aquarelle e Soul Fish têm cozinhado para os nossos defensores e para as pessoas que tiveram de deixar as suas casas e recomeçar as suas vidas em Dnipro. E nós, por nossa vez, ajudámo-los com alimentos para o fazerem <br/>📎Desde o início da guerra, o Centro de Psicologia OK @ok.psyspace presta regularmente apoio psicológico às crianças e aos pais e também nos cedeu a sua sala para as masterclasses <br/>📎Organizámos eventos com bloguistas e os nossos parceiros <br/>📎Realizámos um flash mob de dança com pais e filhos <br/>📎Lançamento do programa de formação em ballet <br/>📎E assegurámos o pleno funcionamento do centro infantil e a distribuição regular de ajuda humanitária a crianças e mulheres grávidas Este ano foi o mais difícil para todos nós... <br/><br/>Mas só há uma receita para a vitória: estar unido, ser forte, fazer tudo para vencer e ajudar quem precisa!"
        },
        item5: {
          title: "Relatórios!",
          desc: "Realizámos um feriado em 17.06 “País dos Inquebráveis” com o apoio da Administração Militar Regional de Dnipro 🫂<br/><br/>Férias para as crianças deslocadas de diferentes partes do país 🫶🏻 na localidade de Lavina @lavinapark<br/><br/>Com o apoio das nossas amigas celebridades @annatrincher_official, @a.wellboy, @demchukmusic, @uliana_royce @mila.eremeeva @viktoriya_tka4 e @yuriy_tkach @positiff @mishvirmish @mashaa.kondratenko @miusli_ua @lidalee_official <br/><br/>Os nossos militares, que defendem o nosso país e tornam possível a criação de férias, receberam 28 veículos e 20 unidades de equipamento 🚐"
        },
        item6: {
          title: "Automóveis para as Forças Armadas da Ucrânia",
          desc: "Graças aos nossos doadores, no dia 17.06, nós, a fundação de caridade Who Else but Us, conseguimos angariar 1074075 UAH 🤩<br/><br/>E, com os nossos esforços, conseguimos comprar 35 unidades do DJI Mavic 3T e entregámo-las em 05.07 à Administração Estatal Regional de Dnipropetrovs'k, os nossos defensores, que nos permitem realizar tais eventos e aproximar a vitória. DJI Mavic 3T e entregámo-las em 05.07 à Administração Estatal Regional de Dnipropetrovs'k, os nossos defensores, que nos permitem levar a cabo tais eventos e aproximar a vitória<br/><br/>Agradecemos a todos os que participaram no evento “País dos Inquebráveis”<br/><br/>Obrigado @a.wellboy @annatrincher_official @demchukmusic @dianagloster @uliana_royce @mashaa.kondratenko @viktoriya_tka4 @yuriy_tkach"
        },
        item7: {
          title: "Ajuda humanitária Odesa",
          desc: "A nossa Fundação “Who Else but Us” visitou Odesa com ajuda humanitária 🥰<br/><br/>Foram distribuídos mais de 110 conjuntos a famílias de deslocados com crianças com menos de 3 anos de idade 🫶🏻<br/><br/>Graças aos nossos parceiros @revivalfund.ua e @opir.sambir2022 🫂<br/><br/>Ajudar e aproximar a vitória"
        },
        item8: {
          title: "Ajuda para Kherson",
          desc: "Nós, a Fundação de Beneficência “Quem mais além de nós”, visitámos Kherson com ajuda humanitária para as vítimas da explosão da central hidroelétrica de Kakhovka 🙏<br/><br/>Enviámos: <br/>• Roupa exterior - 500 unidades <br/>• Roupa de homem - 300 unidades <br/>• Vestuário de senhora - 500 unidades <br/>• Brinquedos para crianças - 7 caixas <br/>• Livros de texto para crianças (ucraniano/inglês) - 3 caixas <br/>• Carrinhos de bebé/cadeirinhas/cadeiras de alimentação - 6 unidades <br/>• Comida para bebé - 2 caixas <br/>• Amaciador de cabelo - 2 caixas <br/>• Зубні щітки - 1 ящик <br/>• Calçado de senhora/homem - 50 pares <br/>• Cobertores - 30 peças <br/>• Anti-sético - 20 litros <br/>• Roupa interior - 30 embalagens <br/>• Colchão de criança - 10 peças <br/>• Roupa de criança - 50 caixas <br/>• Toalha - 1 caixa <br/>• Chinelos - 20 pares <br/>• Prancheta de desenho - 1 peça <br/>• Almofadas/colchas - 50 peças <br/>• Pacotes de alimentos (alimentos enlatados, manteiga, açúcar, chá, biscoitos, massas, papel higiénico, sabão, trigo sarraceno) - 380 pacotes <br/>• Estação de carregamento - 10 peças <br/>• Frascos de alimentação - 25 unidades <br/>• Água potável gaseificada - 1500 toneladas <br/>• Água potável não carbonatada - 8,500 toneladas <br/><br/>📍Ponto de Inquebrabilidade em Kherson com base em: Kherson General Education Complex 48 Endereço: 14, 28 Armii St., Kherson<br/><br/>Continuamos a ajudar 🙏 Acreditamos na vitória"
        },
        item9: {
          title: "Torneio de bilhar",
          desc: "Torneio de bilhar de beneficência dedicado ao Dia da Independência da Ucrânia<br/><br/>Pela primeira vez em muitos anos, conseguimos reunir os melhores atletas da Ucrânia em Dnipro! A competição conta com a participação de 32 atletas de 11 regiões e 14 cidades da Ucrânia. Todas as contribuições de caridade dos participantes são 100% doadas para ajudar os nossos militares!<br/><br/>Na final do torneio, os militares receberam coisas importantes para proteger o nosso país!<br/><br/>Agradecemos aos nossos defensores a oportunidade de realizar este torneio💙💛"
        },
        item10: {
          title: "Estação de bombagem de Kherson",
          desc: "Nós, a fundação de beneficência “Who Else but Us”, visitámos a aldeia de Kalinivske, na região de Kherson, com ajuda!<br/><br/>A aldeia esteve ocupada durante 8 meses🐖.<br/><br/>Os habitantes que permaneceram na sua terra natal estão simplesmente a sobreviver, uma vez que a aldeia foi totalmente destruída 😥<br/><br/>Entregámos uma estação de bombagem para que as pessoas pudessem ter água🙏<br/><br/>Continuamos a ajudar! Vamos aproximar a vitória, porque ela está a chegar😉"
        },
        item11: {
          title: "Dia da Cidade com gelado Lasunka",
          desc: "09.09 No Dia da cidade de Dnipro, nós, juntamente com a “Lasunka” @lasunka_icecream, oferecemos gelados a toda a gente e divertimos as crianças 🌸<br/><br/>Vejamos como foi o nosso encontro"
        },
        item12: {
          title: "O feriado “MOVING TO WIN”",
          desc: "Um evento desportivo para 500 crianças, criado com o apoio da administração regional para garantir que as nossas crianças avancem, não se esqueçam dos seus objectivos e conquistas, apesar da guerra 🙏<br/><br/>Estamos extremamente gratos a @_oleksandrleshchenko por ter organizado o flash mob<br/><br/>Participaram atletas de todo o mundo🏅, bem como amigos famosos como @ilonagvozdeva @amadorlopez_rumberos @nadyadorofeeva @lusyakava @yuriy_tkach @mila.eremeeva @mila_nitich_official <br/><br/>Agradecemos a todos os que participaram neste evento <br/>Nomeadamente, aos organizadores, sem os quais o nosso evento não teria sido possível:<br/>@viktoriya_tka4 <br/>@deniskonovalov <br/>@m_galat <br/>@vladimir_drachuk <br/>@franz_audio <br/>Tu és o melhor 🫂"
        },
        item13: {
          title: "Máquinas para as Forças Armadas da Ucrânia",
          desc: "A nossa fundação de beneficência “Who Else but Us” está constantemente a apoiar os nossos defensores nos locais mais quentes da linha da frente!<br/><br/>Entregámos recentemente 13 carros e três ambulâncias em conjunto com o clube desportivo @vataga_dp e a administração militar!<br/><br/>Estes fundos foram angariados num torneio de MMA de beneficência !🥊<br/><br/>Com a ajuda de um sorteio de lotes únicos, conseguimos angariar mais de 3000000 hryvnias, que foi realizado pelos nossos inigualáveis anfitriões @mila.eremeeva @yuriy_tkach <br/><br/>O evento foi realizado neste momento difícil com o apoio do chefe da Administração Regional de Dnipro! Estamos muito gratos a todos os que se juntam a nós e ajudam os nossos soldados!<br/><br/>Juntos para a vitória!"
        },
        item14: {
          title: "TORNEIO DE XADREZ TOTALMENTE UCRANIANO ♟️",
          desc: "22.10 torneio de xadrez “Estratégia do Inquebrável” 🙌🏻<br/><br/>Participaram mais de 350 pessoas, das quais 125 eram crianças☺️<br/><br/>Os participantes receberam presentes da nossa fundação<br/><br/>🎁Apoiamos o desporto na Ucrânia<br/><br/>Criar o futuro, Glória à Ucrânia 🫂"
        },
        item15: {
          title: "Torneio em memória do mestre dos desportos Yanchenko O.O.",
          desc: "Juntamente com a vataga, realizou-se em Kamianske, de 23 a 26 de novembro, um torneio em memória do Mestre dos Desportos Oleksandr Yanchenko. <br/><br/>O torneio reuniu 1️⃣3️⃣9️⃣ participantes de 7️⃣ regiões da Ucrânia. Jogos extremamente brilhantes e competitivos, em que cada um dos vencedores e vice-campeões, juntamente com os seus treinadores, receberam prémios individuais e prémios em dinheiro. <br/><br/>🏆 Os melhores pugilistas receberam prémios memoráveis pelas suas capacidades<br/><br/>👏🏼 A cerimónia de entrega dos prémios foi apresentada por convidados de honra, incluindo<br/>- Vice-presidente da Federação de Boxe da Ucrânia, Mestre dos Desportos Gennady Tkachenko;<br/>- Jamal Medzhidov, Vice-Presidente da Federação de Boxe da Ucrânia, mestre internacional do desporto, campeão mundial;<br/>- Igor Khromov, Presidente da Companhia de Seguros VATAGA;<br/>- Ivan Gilevich, membro do Conselho de Supervisão da Federação Ucraniana de Boxe.<br/><br/>Apoiemos juntos o desporto na Ucrânia"
        },
        item16: {
          title: "Foram entregues 13 viaturas e três ambulâncias",
          desc: "A nossa fundação de beneficência “Who Else but Us” está constantemente a apoiar os nossos defensores nos locais mais quentes da linha da frente!<br/><br/>Entregámos recentemente 13 carros e três ambulâncias ao clube desportivo @vataga_dp e à administração militar!<br/><br/>Estes fundos foram angariados num torneio de MMA de beneficência!🥊<br/><br/>Com a ajuda de um sorteio de lotes únicos, conseguimos angariar mais de 3000000 hryvnias, que foi realizado pelos nossos inigualáveis anfitriões @mila.eremeeva @yuriy_tkach <br/><br/>O evento foi realizado nesta altura difícil com o apoio do Chefe da Administração Regional de Dnipro!<br/>Estamos muito gratos a todos os que se juntam a nós e ajudam os nossos soldados!!!<br/><br/>Juntos para a vitória!"
        },
        item17: {
          title: "ABERTURA DE UM CENTRO INFANTIL",
          desc: "O evento, há muito esperado, teve lugar 👏🏻<br/><br/>19.04 abrimos as portas do espaço infantil para os nossos mais pequenos 🙌🏻<br/><br/>Agora vamos desenhar, estudar e praticar desporto juntos, e tudo isto graças aos benfeitores atenciosos da cidade de Dnipro!<br/><br/>Agradecimentos! Agradecemos sinceramente a todos os que nos ajudaram a criar este espaço 🙏<br/>Obrigado pelas prendas @bontoy_job ❤️<br/><br/>Criamos o bem e o benefício para os nossos filhos para garantir que tenham uma infância feliz, aconteça o que acontecer!"
        },
        item18: {
          title: "CELEBRAÇÃO DO DIA DA CRIANÇA",
          desc: "O evento reuniu mais de 2000 crianças 🙌🏻<br/><br/>Praças de alimentação, animação, pinturas faciais e muito mais para as nossas crianças 🫶🏻<br/><br/>Convidados: @yuriy_tkach @mila.eremeeva @rukhankoman @roxolanas @uliana_royce.official @positiff @amadorlopez_rumberos @nadyadorofeeva <br/><br/>Estamos sinceramente gratos a todos os que participaram ☺️ <br/><br/>Fazemos tudo para que as crianças tenham uma infância feliz 😘"
        },
        item19: {
          title: "Evacuação dos residentes locais",
          desc: "Os residentes locais destes aglomerados foram evacuados:<br/><br/>Kurakhovo <br/>Selidovo<br/>Ucraniano<br/>Pokrovsk <br/><br/>Mais de 200 residentes foram evacuados <br/>Obrigado a todos os que se preocuparam e se juntaram a nós!<br/><br/>Juntos venceremos!"
        },
        item20: {
          title: "Concerto de beneficência dedicado ao Dia dos Defensores",
          desc: "ESTAMOS SINCERAMENTE GRATOS 🙏 <br/><br/>@retroville_kyiv e @musicboxua pela realização de um concerto de beneficência dedicado ao Dia dos Defensores da Ucrânia<br/><br/>Todas as contribuições são transferidas para o nosso fundo, continuamos a ajudar as famílias necessitadas e, claro, os militares na frente de batalha 🫡<br/><br/>Estamos gratos pelo apoio dos nossos convidados de renome - @roxolanas @ado.khayat @nicolas_karma_official @_kristonko_ e @ruslana.lyzhychko<br/><br/>A presença de cada convidado e telespetador é extremamente importante para nós! Obrigado! Glória à Ucrânia 🙌🏻"
        },
        item21: {
          title: "AJUDA PARA O ABRIGO",
          desc: "Prestámos assistência às famílias evacuadas dos pontos quentes do nosso país ✨<br/><br/>Ajudámos 46 famílias, incluindo 80 crianças com menos de 1 ano de idade 👶🏼<br/><br/>Enviámos:<br/>• conjunto de alimentosи<br/>• artigos de papelaria para crianças<br/>• doces 🍭 <br/><br/>AJUDA! JUNTOS PARA A VITÓRIA!"
        },
        item22: {
          title: "Dia de São Nicolau",
          desc: "Tornou-se uma boa tradição para nós visitarmos os nossos pequenos amigos🫂<br/><br/>E desta vez tivemos também uma ocasião especial - o dia de São Nicolau🎄<br/><br/>Por isso, juntamente com os nossos queridos @yuriy_tkach e @mila.eremeeva, decidimos convidar o incrível @kalush.official para se juntar a nós, e eles não recusaram 🫶 E foi ótimo! Um verdadeiro concerto de férias para os nossos filhos 🥳🙌<br/><br/>Todos saíram deste encontro com presentes, sorrisos, desejos uns para os outros e promessas de se voltarem a encontrar em breve ❤️‍🩹<br/><br/>Дякуємо @dniprooda за підтримку"
        },
        item23: {
          title: "Visita a uma das escolas da cidade de Dnipro",
          desc: "Visita a uma das escolas da cidade de Dnipro 🙏<br/><br/>Instituição Municipal de Solidariedade Social “Centro Multidisciplinar de Formação e Reabilitação n.º 6 ”DOR”<br/><br/>Felicitámos as crianças no dia de São Nicolau 🎅🏻<br/>E como presente para nós, os miúdos deram-nos um concerto incrivelmente interessante 😍<br/><br/>Estamos orgulhosos de ter crianças tão talentosas, amáveis e sinceras 🥰<br/><br/>Boas férias 🎉"
        },
        item24: {
          title: "Dia de São Nicolau",
          desc: "Todos os anos, no início de dezembro, reunimo-nos como uma grande equipa para passarmos alguns dias juntos e darmos umas férias a quem as merece🫂<br/><br/>Convidámos crianças dos territórios temporariamente ocupados, filhos dos nossos militares, dos nossos socorristas e médicos para passarem o dia de São Nicolau connosco e tentámos tornar este dia inesquecível para eles ❤️‍🔥<br/><br/>Neste dia, o Teatro de Ópera e Ballet ofereceu doces, animação, maquilhagem aquática, oficinas criativas e presentes 🎁 Assistimos a uma maravilhosa representação de Branca de Neve e os 7 anões✨<br/><br/>estamos sinceramente gratos à @kazka.band pela sua participação e apoio às nossas férias, as crianças ficaram encantadas, é um verdadeiro presente para todos nós 🫶<br/><br/>@yuriy_tkach , @mila.eremeeva - os nossos corações estão convosco ❤️‍🔥 <br/>É impossível imaginar as nossas férias sem vocês! Obrigado, queridos, pelo vosso apoio, há muito que são os principais rostos e embaixadores da nossa fundação, estamos orgulhosos e agradecemos-vos🫰🫂"
        },
        item25: {
          title: "Hospital Clínico Infantil Regional de Dnipro",
          desc: "Este fim de semana visitámos o Hospital Clínico Regional de Crianças de Dnipro, onde são tratadas crianças de todas as idades, desde bebés a adolescentes❤️‍🩹<br/><br/>Os nossos familiares @yuriy_tkach e @mila.eremeeva estiveram connosco, por isso as férias foram um sucesso! É verdade o que dizem - um sorriso e uma boa disposição são a chave para uma recuperação rápida 🫂<br/><br/>E a secção foi uma atuação improvisada dos nossos amigos da @kazka.band ✨ Sasha, as crianças adoram-na e nós compreendemo-las 🤍<br/><br/>Estamos muito felizes por felicitar os nossos pequenos nas férias 🎅🏻 As melhoras dos nossos coelhinhos! 🫶"
        },
      },

      btnMore: "Ler mais",
      btnHide: "Esconder",
    },
  },

  footer: {
    desc: {
      first: "Seja um voluntário ou ajude como um especialista.",
      second: "Aperte o botão!",
    },
    btn: "Eu quero ser útil",
    list: {
      title: "Links Úteis",
      about: "Sobre nosso fundo",
      news: "Notícia",
      contact: "Contatos",
    },
    info: {
      title: "informação adicional",
      one: "USREOU 44341172",
      two: "UA763003460000026004098334201",
      three: 'em JSC "ALPHA-BANK"',
      four: "IMFs 300346",
      five: "USREOU 23494714",
    },
  },
};
