import React, { useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDove } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import ModalCrypto from "../ModalCrypto";

const oncePay = [
  {
    id: "one",
    value: "pXOwcPHCB",
    amount: "10",
  },
  {
    id: "two",
    value: "powGF534z5TTV1J",
    amount: "25",
  },
  {
    id: "three",
    value: "wsmKP5HA3",
    amount: "50",
  },
  {
    id: "four",
    value: "Urty7QFf",
    amount: "100",
  },
  {
    id: "five",
    value: "doz0JFFDpesTUvk",
    amount: "Інша сума",
  },
];

const monthlyPay = [
  {
    id: "one",
    value: "0s07UyIFDNDvwX",
    amount: "10",
  },
  {
    id: "two",
    value: "05iSx6STQU",
    amount: "25",
  },
  {
    id: "three",
    value: "2pFt",
    amount: "50",
  },
  {
    id: "four",
    value: "8U6weXqyM4cXHwk",
    amount: "100",
  },
  {
    id: "five",
    value: "doz0JFFDpesTUvk",
    amount: "Інша сума",
  },
];

function Donat(props) {
  // Translation
  const { t } = useTranslation();
  const basePath = "report.donat.";

  const [payValue, setPayValue] = useState(oncePay);

  const [modalShow, setModalShow] = useState(false);
  const [amountValue, setAmountValue] = useState("pXOwcPHCB");

  const handleAmountToggle = (e) => {
    setAmountValue(e.target.value);
  };

  const handlePay = () => {
    const item = document.querySelector("#value-pay");

    if (item.checked) {
      setPayValue(monthlyPay);
    } else {
      setPayValue(oncePay);
    }
  };

  return (
    <>
      <section className="k-donat">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <h2 className="title">
                <FontAwesomeIcon icon={faDove} />
                {t(basePath + "title")}
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <div className="tabs">
              <div className="switch-button" onClick={handlePay}>
                <span className="switch-monthly">{t(basePath + "switch.monthly")}</span>
                <input id="value-pay" className="switch-button-checkbox" type="checkbox" />
                <label className="switch-button-label" htmlFor="">
                  <span className="switch-button-label-span">{t(basePath + "switch.one")}</span>
                </label>
              </div>
            </div>
            <div className="amount">
              {payValue.map((item, i) => (
                <label key={i}>
                  <input
                    type="radio"
                    id={item.id}
                    value={item.value}
                    name="tab-control"
                    checked={amountValue === item.value}
                    onChange={handleAmountToggle}
                  />
                  <span>
                    {item.amount}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 325 435" width="16" height="16">
                      <g>
                        <path
                          fill="#444444"
                          d="M226.8,197.6l-49.1,39.7h144.2v43.9h-187c-12.5,9.4-19.9,20.9-19.9,35.5c0,25.1,19.9,38.7,55.4,38.7   c30.3,0,56.4-14.6,77.3-44.9l57.5,38.7c-31.3,48.1-87.8,72.1-141.1,72.1c-70,0-122.2-33.4-122.2-88.8c0-17.8,6.3-36.6,18.8-51.2   H3.2v-43.9h91.9l50.2-39.7H3.2v-43.9h186c13.6-12.5,19.9-24,19.9-37.6c0-21.9-19.9-37.6-50.2-37.6c-29.3,0-54.3,16.7-72.1,44.9   L31.4,85.8c30.3-48.1,79.4-72.1,132.7-72.1c72.1,0,119.1,37.6,119.1,88.8c0,18.8-6.3,37.6-16.7,51.2h55.4v43.9H226.8z"
                        />
                      </g>
                    </svg>
                  </span>
                </label>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              <Button href={`https://pay.fondy.eu/s/${amountValue}`} className="btn-st">
                <img src={require("../../assets/img/visa.png").default} alt="" />
                {t(basePath + "btn")}
              </Button>
            </div>
            <div className="crypto">
              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <rect x="0" y="0" width="24" height="24" />
                  <circle opacity=".3" fill="#000000" cx="5" cy="12" r="2" />
                  <circle fill="#000000" cx="12" cy="12" r="2" />
                  <circle opacity=".3" fill="#000000" cx="19" cy="12" r="2" />
                </g>
              </svg>
              <Button onClick={() => setModalShow(true)} className="btn-st">
                {t(basePath + "btnCrypto")}
              </Button>
            </div>
          </Row>
        </Container>
      </section>

      <ModalCrypto show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default Donat;
