import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Components List
import NavBar from "../components/NavBar";
import Home from "../pages/Home";
import About from "../pages/About";
import News from "../pages/News";
import NewsPage from "../components/News/NewsPage";
import NFT from "../pages/NFT";
import Contact from "../pages/Contact";
import Report from "../pages/Report";

function Routes() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/about" component={About} />
          <Route exact path="/news" component={News} />
          <Route path="/news/:id" component={NewsPage} />
          <Route path="/nft" component={NFT} />
          <Route path="/contact" component={Contact} />
          <Route path="/report" component={Report} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default Routes;
