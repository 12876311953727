import React, { useRef, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChild,
  faHandHoldingHeart,
  faRankingStar,
  faAngleDown,
  faAngleUp,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

function Statistic() {
  // Translation
  const { t } = useTranslation();
  const basePath = "report.statistic.";

  const [expandedIds, setExpandedIds] = useState([]);
  const contentRefs = useRef(new Map());

  const toggleExpand = (id) => {
    setExpandedIds((prev) =>
      prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
    );
  };

  const data = [
    {
      id: 1,
      title: t(basePath + "data.item1.title"),
      date: "10.09.2022",
      videoUrl: "https://www.youtube.com/embed/xNMlk4qLJXo",
      content: <Trans i18nKey={basePath + "data.item1.desc"} />,
    },
    {
      id: 2,
      title: t(basePath + "data.item2.title"),
      date: "14.10.2022",
      videoUrl: "https://www.youtube.com/embed/APLl8EsVECw",
      content: <Trans i18nKey={basePath + "data.item2.desc"} />,
    },
    {
      id: 3,
      title: t(basePath + "data.item3.title"),
      date: "20.11.2022",
      videoUrl: "https://www.youtube.com/embed/sXbZj1EOdjU",
      content: <Trans i18nKey={basePath + "data.item3.desc"} />,
    },
    {
      id: 4,
      title: t(basePath + "data.item4.title"),
      date: "24.02.2023",
      videoUrl: "https://www.youtube.com/embed/FZB7-gpJo7A",
      content: <Trans i18nKey={basePath + "data.item4.desc"} />,
    },
    {
      id: 5,
      title: t(basePath + "data.item5.title"),
      date: "17.06.2023",
      videoUrl: "https://www.youtube.com/embed/I_3hYLp_lsU",
      content: <Trans i18nKey={basePath + "data.item5.desc"} />,
    },
    {
      id: 6,
      title: t(basePath + "data.item6.title"),
      date: "18.06.2023",
      videoUrl: "https://www.youtube.com/embed/tA2Uy4wcUYY",
      content: <Trans i18nKey={basePath + "data.item6.desc"} />,
    },
    {
      id: 7,
      title: t(basePath + "data.item7.title"),
      date: "27.06.2023",
      videoUrl: "https://www.youtube.com/embed/ByDPwz822lw",
      content: <Trans i18nKey={basePath + "data.item7.desc"} />,
    },
    {
      id: 8,
      title: t(basePath + "data.item8.title"),
      date: "15.07.2023",
      videoUrl: "https://www.youtube.com/embed/2FdxRGDTnjw",
      content: <Trans i18nKey={basePath + "data.item8.desc"} />,
    },
    {
      id: 9,
      title: t(basePath + "data.item9.title"),
      date: "24.08.2023",
      videoUrl: "https://www.youtube.com/embed/BlXwWkvyE4M",
      content: <Trans i18nKey={basePath + "data.item9.desc"} />,
    },
    {
      id: 10,
      title: t(basePath + "data.item10.title"),
      date: "05.09.2023",
      videoUrl: "https://www.youtube.com/embed/KPScEPvacUA",
      content: <Trans i18nKey={basePath + "data.item10.desc"} />,
    },
    {
      id: 11,
      title: t(basePath + "data.item11.title"),
      date: "09.09.2023",
      videoUrl: "https://www.youtube.com/embed/yklckct56-E",
      content: <Trans i18nKey={basePath + "data.item11.desc"} />,
    },
    {
      id: 12,
      title: t(basePath + "data.item12.title"),
      date: "06.10.2023",
      videoUrl: "https://www.youtube.com/embed/bvMJDdlBfpE",
      content: <Trans i18nKey={basePath + "data.item12.desc"} />,
    },
    {
      id: 13,
      title: t(basePath + "data.item13.title"),
      date: "14.10.2023",
      videoUrl: "https://www.youtube.com/embed/UnhRVJ8QsEU",
      content: <Trans i18nKey={basePath + "data.item13.desc"} />,
    },
    {
      id: 14,
      title: t(basePath + "data.item14.title"),
      date: "22.10.2023",
      videoUrl: "https://www.youtube.com/embed/surxDtbGmcw",
      content: <Trans i18nKey={basePath + "data.item14.desc"} />,
    },
    {
      id: 15,
      title: t(basePath + "data.item15.title"),
      date: "26.11.2023",
      videoUrl: "https://www.youtube.com/embed/Lk0ziXdXJqo",
      content: <Trans i18nKey={basePath + "data.item15.desc"} />,
    },
    {
      id: 16,
      title: t(basePath + "data.item16.title"),
      date: "28.11.2023",
      videoUrl: "https://www.youtube.com/embed/umAyQE_19Xw",
      content: <Trans i18nKey={basePath + "data.item16.desc"} />,
    },
    {
      id: 17,
      title: t(basePath + "data.item17.title"),
      date: "19.04.2024",
      videoUrl: "https://www.youtube.com/embed/pa2_nlSl2Sg",
      content: <Trans i18nKey={basePath + "data.item17.desc"} />,
    },
    {
      id: 18,
      title: t(basePath + "data.item18.title"),
      date: "01.06.2024",
      videoUrl: "https://www.youtube.com/embed/5wwdc0HB6XY",
      content: <Trans i18nKey={basePath + "data.item18.desc"} />,
    },
    {
      id: 19,
      title: t(basePath + "data.item19.title"),
      date: "04.09.2024",
      videoUrl: "https://www.youtube.com/embed/P3KW_eC9z8s",
      content: <Trans i18nKey={basePath + "data.item19.desc"} />,
    },
    {
      id: 20,
      title: t(basePath + "data.item20.title"),
      date: "01.10.2024",
      videoUrl: "https://www.youtube.com/embed/7AIs1XFOtlI",
      content: <Trans i18nKey={basePath + "data.item20.desc"} />,
    },
    {
      id: 21,
      title: t(basePath + "data.item21.title"),
      date: "10.10.2024",
      videoUrl: "https://www.youtube.com/embed/Y3M_R3DlnNw",
      content: <Trans i18nKey={basePath + "data.item21.desc"} />,
    },
    {
      id: 22,
      title: t(basePath + "data.item22.title"),
      date: "06.12.2024",
      videoUrl: "https://www.youtube.com/embed/yX13ezfq4-E",
      content: <Trans i18nKey={basePath + "data.item22.desc"} />,
    },
    {
      id: 23,
      title: t(basePath + "data.item23.title"),
      date: "06.12.2024",
      videoUrl: "https://www.youtube.com/embed/l3igH5PVtrg",
      content: <Trans i18nKey={basePath + "data.item23.desc"} />,
    },
    {
      id: 24,
      title: t(basePath + "data.item24.title"),
      date: "06.12.2024",
      videoUrl: "https://www.youtube.com/embed/Il-33RTlS8g",
      content: <Trans i18nKey={basePath + "data.item24.desc"} />,
    },
    {
      id: 25,
      title: t(basePath + "data.item25.title"),
      date: "06.12.2024",
      videoUrl: "https://www.youtube.com/embed/F7roG3jMKaQ",
      content: <Trans i18nKey={basePath + "data.item25.desc"} />,
    },
  ];

  return (
    <section className="k-stat">
      <Container>
        <Row className="align-items-center">
          <Col lg={12} className="title">
            <h3>
              <img
                src={require("../../assets/img/logo-icon.png").default}
                alt=""
              />
              {t(basePath + "title")}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={4}>
            <div className="stat-item">
              <div className="icon">
                <FontAwesomeIcon icon={faHandHoldingHeart} />
              </div>
              <p>{t(basePath + "items.one")}</p>
              <div className="player">
                <ReactPlayer
                  className="video"
                  playing={false}
                  controls={true}
                  volume={0}
                  url={"https://www.youtube.com/shorts/TooW6U8qJwc"}
                />
              </div>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="stat-item">
              <div className="icon">
                <FontAwesomeIcon icon={faRankingStar} />
              </div>
              <p>{t(basePath + "items.two")}</p>
              <div className="player">
                <ReactPlayer
                  className="video"
                  playing={false}
                  controls={true}
                  volume={0}
                  url={"https://www.youtube.com/watch?v=I_3hYLp_lsU"}
                />
              </div>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="stat-item">
              <div className="icon">
                <FontAwesomeIcon icon={faChild} />
              </div>
              <p>{t(basePath + "items.three")}</p>
              <div className="player">
                <ReactPlayer
                  className="video"
                  playing={false}
                  controls={true}
                  volume={0}
                  url={"https://www.youtube.com/watch?v=xNMlk4qLJXo"}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-50">
          {data.map((item) => (
            <Col lg={4} key={item.id}>
              <div className="info">
                <h4>{item.title}</h4>
                <span className="date">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  {item.date}
                </span>
                <div className="video">
                  <iframe
                    width="100%"
                    height="200px"
                    src={item.videoUrl}
                    title="Video"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
                <div className="content-wrapper">
                  <p
                    ref={(el) => el && contentRefs.current.set(item.id, el)}
                    className={`content ${
                      expandedIds.includes(item.id) ? "expanded" : ""
                    }`}
                  >
                    {item.content}
                  </p>
                  <Button
                    type="primary"
                    onClick={() => toggleExpand(item.id)}
                    className="btn-st mt-10"
                  >
                    {expandedIds.includes(item.id) ? (
                      <>
                        <FontAwesomeIcon icon={faAngleDown} />
                        {t(basePath + "btnHide")}
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faAngleUp} />
                        {t(basePath + "btnMore")}
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Statistic;
