import React from "react";
import PageBanner from "../components/PageBanner";
import Statistic from "../components/Report/Statistic";
import Donat from "../components/Report/Donat";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function Report() {
  return (
    <div className="kind">
      <PageBanner />
      <Statistic />
      <Donat />
      <Contact />
      <Footer />
    </div>
  );
}

export default Report;
