import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { languages } from "../i18n";
import { NavLink } from "react-router-dom";
import { useLanguage } from "../contexts/LanguageContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagramSquare,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const langIcons = {
  "ua-UA": require("../assets/img/flags/ua.png"),
  "en-US": require("../assets/img/flags/en.png"),
  "es-ES": require("../assets/img/flags/es.png"),
  "fr-FR": require("../assets/img/flags/fr.png"),
  "de-DE": require("../assets/img/flags/de.png"),
  "it-IT": require("../assets/img/flags/it.png"),
  "pl-PL": require("../assets/img/flags/pl.png"),
  "pt-PT": require("../assets/img/flags/pt.png"),
};

function NavBar() {
  // Translation
  const { t } = useTranslation();
  const basePath = "navBar.";
  const { changeLanguage } = useLanguage();

  const handleChangeLang = (e) => {
    if (e.target.className === "dropdown-item") {
      changeLanguage(e.target.dataset.value);
    }
  };

  return (
    <>
      <div className="top-bar">
        <Container>
          <div className="info">
            <div className="mail">
              <ul>
                <li>
                  <a href="tel:+380981879999">
                    <FontAwesomeIcon icon={faPhone} />
                    +38 (098) 187-9999
                  </a>
                </li>
                <li>
                  <a href="mailto:turbota.dp@gmail.com">
                    <FontAwesomeIcon icon={faEnvelope} />
                    turbota.dp@gmail.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="social">
              <ul>
                <li>
                  <a href="https://www.instagram.com/hto_yaksho_ne_my/">
                    <FontAwesomeIcon icon={faInstagramSquare} />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/share/18AVAV29yP/?mibextid=wwXIfr">
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <Navbar expand="lg" className="navbar-custom">
        <Container>
          <Navbar.Brand href="#home">
            <img src={require("../assets/img/logo-light.png").default} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavLink
                to="/"
                className="link"
                activeclass="active"
                exact={true}
              >
                {t(basePath + "link.home")}
              </NavLink>
              <NavLink to="/report" className="link" activeclass="active">
                {t(basePath + "link.center")}
              </NavLink>
              <NavLink to="/about" className="link" activeclass="active">
                {t(basePath + "link.about")}
              </NavLink>
              <NavLink to="/news" className="link" activeclass="active">
                {t(basePath + "link.news")}
              </NavLink>
              <NavLink to="/nft" className="link" activeclass="active">
                {t(basePath + "link.nft")}
              </NavLink>
              <NavLink to="/contact" className="link" activeclass="active">
                {t(basePath + "link.contact")}
              </NavLink>
            </Nav>
          </Navbar.Collapse>
          <Nav>
            <NavDropdown
              title={t(basePath + "lang.title")}
              id="collasible-nav-dropdown"
              onClick={handleChangeLang}
            >
              {languages.map((item) => (
                <NavDropdown.Item key={item} data-value={item}>
                  <img src={langIcons[item].default} alt="" />
                  {t(`${basePath}lang.${item}`)}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
